import { useRouteNavigation } from "../../../lib/common/routes";
import { TopMenu } from "./top-menu";
import { RouteEnumType } from "../../../lib/types/routes";
import { useUserService } from "../../../services/users";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    evaluators,
    useWindowSizeMatch,
} from "../../../lib/window-size-matcher";

const ButtonSignup = ({
    title,
    navigateTo,
}: {
    title: string;
    navigateTo: RouteEnumType;
}) => {
    const { navigateRouteClickHandler } = useRouteNavigation();

    return (
        <button
            className="btn btn-home-signUp btn-outline-light px-5 text-white"
            onClick={(e) => navigateRouteClickHandler(navigateTo, e)}
            title={title}
        >
            {title}
        </button>
    );
};

const SplashScreen = ({
    animationEndedHandler,
}: {
    animationEndedHandler: () => void;
}) => {
    const videoEndedHandler = () => {
        (
            document.getElementById("splashScreen") as HTMLDivElement
        ).classList.add("fade-in-image");
        window.document.body.style.overflow = "auto";
    };
    const { conditionFulfilled: isSmallDevice } = useWindowSizeMatch({
        condition: evaluators.lessThan(991),
    });

    const { conditionFulfilled: isPortrait } = useWindowSizeMatch({
        condition: evaluators.isPortrait(),
    });

    useEffect(() => {
        window.document.body.style.overflow = "hidden";
    }, []);

    return (
        <>
            <div
                id="splashScreen"
                onAnimationEnd={animationEndedHandler}
                className="container-video-splash"
            >
                <video
                    className="align-middle"
                    playsInline={true}
                    onEnded={videoEndedHandler}
                    style={{
                        width: "100%",
                    }}
                    autoPlay={true}
                    muted={true}
                >
                    <source
                        src={
                            !isPortrait.fulfilled
                                ? "/splash_wide.mov"
                                : isSmallDevice.fulfilled
                                ? "/splash_vertical.mov"
                                : "/splash_wide.mov"
                        }
                    />
                </video>
            </div>
        </>
    );
};

export const Intro = () => {
    const search = useLocation().search;
    const showSplash = new URLSearchParams(search).get("showSplash") === "1";

    const { isLoggedIn } = useUserService();
    const [showVideo, setShowVideo] = useState<boolean>(true);
    const animationEndedHandler = () => {
        setShowVideo((before) => {
            window.document.body.style.overflow = "auto";
            return false;
        });
        document.getElementById("splashContainer")?.remove();
    };

    return (
        <>
            <div id="splashContainer">
                {showSplash && showVideo && (
                    <SplashScreen
                        animationEndedHandler={animationEndedHandler}
                    />
                )}
            </div>
            <TopMenu />
            <div className="container-fluid bg-dark">
                <div className="container pt-4 pb-5 pt-md-1">
                    <div className="row">
                        <div className=" col-lg-7 text-start">
                            <h1 className="text-white px-3 pb-2 home-title">
                                How <br />
                                <span className="highlighted-title">
                                    Dental Techs
                                </span>{" "}
                                <br /> Find Paradise
                            </h1>
                            <h2 className="text-white py-3 ps-3 pe-4 lh-base">
                                Matching Skilled Tech's with Great Labs for your
                                Ideal Lifestyle
                            </h2>
                            <div className="mt-3 mb-5">
                                <div className="d-flex flex-column justify-content-center flex-sm-row gap-3 mt-lg-3 gap-lg-5 ">
                                    {!isLoggedIn && (
                                        <>
                                            <ButtonSignup
                                                title="Tech Sign Up"
                                                navigateTo="signupTech"
                                            />
                                            <ButtonSignup
                                                title="Lab Sign Up"
                                                navigateTo="signupLab"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mb-5">
                            <div className="image-section-intro rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
