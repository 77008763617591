import { useEffect, useRef, useState } from "react";
import {
    FileUploaderComponent,
    useFileManagement,
} from "../../../services/file-management";
import { useAuthContext } from "../../../contexts/auth-context";
import {
    apiRequestGet,
    apiRequestSecuredDelete,
} from "../../../lib/common/api";
import { Spinner } from "../../common/spinner";
import { GALLERY_LIMIT } from "../../../lib/common/constants";

const useGalleryImages = (userId: string) => {
    const [images, setImages] = useState<{ [userId: string]: string[] }>({});
    const [canAddImg, setCanAddImg] = useState<boolean>(true);
    const { getUserTokenId } = useAuthContext();

    const getGallery = async () => {
        try {
            const response = await apiRequestGet<{
                data: {
                    rows: { user_id: string; image_id: string }[];
                    countImages: number | undefined;
                };
            }>(`files/gallery/${userId}`);

            const imagesData = response.data?.data.rows || [];
            const imagesCount = response.data?.data.countImages ?? 0;

            return { images: imagesData, imageCount: imagesCount };
        } catch (error) {
            console.error("Error fetching gallery images:", error);
            return { images: {}, imageCount: 0 };
        }
    };

    const deleteImageGallery = async (image_id: string): Promise<any> => {
        return apiRequestSecuredDelete("files/delete", await getUserTokenId(), {
            image_id: image_id,
        });
    };
    const refreshGallery = async () => {
        const updatedGallery = await getGallery();
        const updatedImagesObject: { [userId: string]: string[] } = {};

        if (Array.isArray(updatedGallery.images)) {
            updatedGallery.images.forEach((item) => {
                if (!updatedImagesObject[item.user_id]) {
                    updatedImagesObject[item.user_id] = [];
                }
                updatedImagesObject[item.user_id].push(item.image_id);
            });
        }
        setImages(updatedImagesObject);
        setCanAddImg(updatedGallery.imageCount < GALLERY_LIMIT);
    };
    useEffect(() => {
        getGallery().then((data) => {
            const imagesObject: { [userId: string]: string[] } = {};

            if (Array.isArray(data.images)) {
                data.images.forEach((item) => {
                    if (!imagesObject[item.user_id]) {
                        imagesObject[item.user_id] = [];
                    }
                    imagesObject[item.user_id].push(item.image_id);
                });
            }
            setImages(imagesObject);
            setCanAddImg(data?.imageCount < GALLERY_LIMIT);
        });
        //eslint-disable-next-line
    }, [userId]);

    return {
        images,
        deleteImageGallery,
        refreshGallery,
        canAddImg,
    };
};

type TGallerySectionProps = {
    userId: string;
    editable: boolean;
    isSomePartBeingEdited: boolean;
    setIsSomePartBeingEdited: (value: boolean) => void;
    setInappropriateContent: (value: boolean) => void;
    setUnsupportedImageType: (value: boolean) => void;
};
export const GallerySection = ({
    userId,
    setInappropriateContent,
    setUnsupportedImageType,
    setIsSomePartBeingEdited,
    editable,
    isSomePartBeingEdited,
}: TGallerySectionProps) => {
    const [isEditingGallery, setIsEditingGallery] = useState(false);
    const refGallery1 = useRef<HTMLDivElement>(null);
    const { images, refreshGallery, deleteImageGallery, canAddImg } =
        useGalleryImages(userId);
    const [numberOfImages, setNumberOfImages] = useState(3);
    const [arrow, setArrow] = useState<boolean>(true);
    const { getImagePath, makeDroppableCapable, state } = useFileManagement();

    const editToggleHandler = () => {
        setIsEditingGallery((prevState) => {
            setIsSomePartBeingEdited(!prevState);
            return !prevState;
        });
    };

    const deleteImage = async (image_id: string) => {
        await deleteImageGallery(image_id);
        await refreshGallery();
    };

    const updateGallery1 = async (
        newFile?: string,
        inappropriateContent?: boolean,
        unsupportedImageType?: boolean
    ) => {
        if (inappropriateContent) {
            window.scrollTo({ top: 0 });
            setInappropriateContent(true);
        } else if (unsupportedImageType) {
            window.scrollTo({ top: 0 });
            setUnsupportedImageType(true);
        } else {
            if (!newFile) {
            } else {
                await refreshGallery();
                setInappropriateContent(false);
                setUnsupportedImageType(false);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setNumberOfImages(1);
            } else if (window.innerWidth <= 991) {
                setNumberOfImages(2);
            } else {
                setNumberOfImages(3);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        makeDroppableCapable(
            refGallery1,
            "gallery1",
            "image",
            "gallery",
            async (newfile, inappropriateContent, unsupportedImageType) => {
                updateGallery1(
                    newfile,
                    inappropriateContent,
                    unsupportedImageType
                );
            }
        );
        //eslint-disable-next-line
    }, [isEditingGallery, images]);

    useEffect(() => {
        const totalImages = Object.values(images).flat().length;
        if (window.innerWidth >= 991 && totalImages <= 3) {
            setArrow(false);
        } else if (totalImages === 0) {
            setArrow(false);
        } else {
            setArrow(true);
        }
    }, [images]);
    return (
        <>
            <div className="row p-2 p-md-4">
                <div className="d-flex justify-content-between py-2 ps-2 pe-0">
                    <h5 className="fw-bold">GALLERY</h5>
                    {editable && !isEditingGallery && (
                        <div
                            className="fileTooltip rightEdit ms-auto ms-lg-2"
                            data-tooltip=" Edit"
                            role="button"
                            data-toggle="collapse"
                            data-target="#galleryUpload"
                            aria-expanded="true"
                            aria-controls="galleryUpload"
                            onClick={editToggleHandler}
                        >
                            <i
                                data-testid="edit-icon"
                                className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                            ></i>
                        </div>
                    )}
                    {editable && isEditingGallery && (
                        <button
                            className="btn btn-dark shadow"
                            data-toggle="collapse"
                            data-target="#galleryUpload"
                            aria-expanded="true"
                            aria-controls="galleryUpload"
                            onClick={editToggleHandler}
                        >
                            Finish Editing
                        </button>
                    )}
                </div>
            </div>
            <div className="row justify-content-center align-items-center">
                {/* when there is no image in gallery */}
                {!editable &&
                    (Object.keys(images).length === 0 ||
                        Object.values(images).every(
                            (imageIds) => imageIds.length === 0
                        )) && (
                        <div>
                            <div className="border rounded mx-md-3 p-4 d-flex justify-content-center flex-column align-items-center">
                                <i className="fa-solid fa-images mb-2 icon-no-photos"></i>
                            </div>
                        </div>
                    )}
                {editable &&
                    !isEditingGallery &&
                    (Object.keys(images).length === 0 ||
                        Object.values(images).every(
                            (imageIds) => imageIds.length === 0
                        )) && (
                        <div>
                            <div className="border rounded mx-md-3 p-4 d-flex justify-content-center flex-column align-items-center">
                                <i className="fa-solid fa-images mb-2 icon-no-photos"></i>
                                <p
                                    className="m-0 text-center"
                                    style={{ color: "#a5a5a5" }}
                                >
                                    <strong>
                                        {" "}
                                        Enhance your profile by creating a photo
                                        gallery!{" "}
                                    </strong>
                                    <br />
                                    Click on Edit to upload photos of your lab,
                                    the local environment, community, or nearby
                                    attractions.
                                    <br />
                                    It’s a great way to make your profile more
                                    engaging.
                                </p>
                            </div>
                        </div>
                    )}
                {/* when is editing */}
                {editable && isEditingGallery && (
                    <>
                        <div className="collapse mb-3" id="galleryUpload">
                            <p className="px-md-4 pb-md-2">
                                <small>
                                    Share photos of your workspace, team, and
                                    surroundings to give new applicants a true
                                    feel for what makes your lab unique.
                                    <br />
                                    Just make sure your images are either public
                                    domain or that you have permission to use
                                    them!
                                </small>
                            </p>
                            {/* grid */}
                            <div className="mx-md-2 d-flex flex-wrap">
                                {Object.entries(images).map(
                                    ([userId, imageIds]) =>
                                        imageIds.map((imageId) => (
                                            <div
                                                key={imageId}
                                                className="col-6 col-md-4 px-2 mb-3 gallery-image gallery-image_grid position-relative"
                                            >
                                                <img
                                                    src={getImagePath(
                                                        imageId,
                                                        "gallery",
                                                        userId
                                                    )}
                                                    className="w-100 h-100 rounded"
                                                    alt=""
                                                />
                                                <button
                                                    style={{
                                                        paddingInline: "0.5rem",
                                                        paddingBlock: "0.1rem",
                                                        opacity: "70%",
                                                    }}
                                                    className="btn btn-light position-absolute top-0 end-0 my-2 mx-4 border-0"
                                                    onClick={() => {
                                                        deleteImage(imageId);
                                                    }}
                                                >
                                                    <i className="fa-solid fa-xmark fs-small"></i>
                                                </button>
                                            </div>
                                        ))
                                )}
                                {state === "working" && (
                                    <>
                                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                                            <Spinner />
                                        </div>
                                    </>
                                )}
                                {canAddImg && (
                                    <div
                                        ref={refGallery1}
                                        className="col-md-4 d-flex justify-content-center flex-column gallery-image gallery-image_uploadBox rounded dashed-border border-primary p-4 text-center"
                                    >
                                        <i className="fa-solid fa-arrow-up-from-bracket mb-2 icon-photos"></i>
                                        <p className="d-none d-md-block">
                                            Drag and drop new images
                                        </p>
                                        <div className="d-flex justify-content-center">
                                            <FileUploaderComponent
                                                relatedComponent={refGallery1}
                                                tooltipPosition="right"
                                                fileToolTipText=""
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!canAddImg && (
                                <p className="text-danger text-center">
                                    You can only add up to {GALLERY_LIMIT}{" "}
                                    images.
                                </p>
                            )}
                        </div>
                    </>
                )}
                {/* carrousel not editing*/}
                {!isEditingGallery && (
                    <div className="col">
                        <div
                            id="galleryCarousel"
                            className="carousel slide carousel-fade"
                            // data-ride="carousel"
                        >
                            <div className="carousel-inner h-auto">
                                {Object.entries(images).map(
                                    ([userId, imageIds], userIndex) => {
                                        const imageChunks = [];
                                        for (
                                            let i = 0;
                                            i < imageIds.length;
                                            i += numberOfImages
                                        ) {
                                            imageChunks.push(
                                                imageIds.slice(
                                                    i,
                                                    i + numberOfImages
                                                )
                                            );
                                        }

                                        return imageChunks.map(
                                            (chunk, chunkIndex) => (
                                                <div
                                                    key={`${userId}-${chunkIndex}`}
                                                    className={`carousel-item ${
                                                        userIndex === 0 &&
                                                        chunkIndex === 0
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    <div className="row">
                                                        {chunk.map(
                                                            (imageId) => (
                                                                <div
                                                                    key={
                                                                        imageId
                                                                    }
                                                                    className="col-md-6 col-lg-4 gallery-image position-relative"
                                                                >
                                                                    <img
                                                                        src={getImagePath(
                                                                            imageId,
                                                                            "gallery",
                                                                            userId
                                                                        )}
                                                                        className="d-block w-100 rounded h-100"
                                                                        alt={`${imageId}`}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        );
                                    }
                                )}
                            </div>
                            {!!arrow && (
                                <>
                                    <button
                                        className="carousel-control-prev carousel-control-prev_carrouselImgGallery"
                                        type="button"
                                        data-target="#galleryCarousel"
                                        data-slide="prev"
                                    >
                                        <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                            Previous
                                        </span>
                                    </button>
                                    <button
                                        className="carousel-control-next carousel-control-next_carrouselImgGallery"
                                        type="button"
                                        data-target="#galleryCarousel"
                                        data-slide="next"
                                    >
                                        <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                            Next
                                        </span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
