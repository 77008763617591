import { CSSProperties, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Header } from "../../common/header";
import { getIndustryRoles } from "../../../services/generalInfo";
import { TGenericIdName, TProcessStates } from "../../../lib/types/general";
import { InviteButton } from "../../common/invite-button";
import {
    NotificationProvider,
    useNotificationContext,
} from "../../../contexts/notification-context";
import {
    LabSignUpFormData,
    TApiLab,
    TEvents,
    TLandmarks,
    TRestaurant,
} from "../../../lib/types/labs";
import { useLabs } from "../../../services/laboratories";
import { useAuthContext } from "../../../contexts/auth-context";
import { useUserService } from "../../../services/users";
import {
    FileUploaderComponent,
    useFileManagement,
} from "../../../services/file-management";
import { getCacheKillQueryString } from "../../../lib/common/api";
import { PositionsCheckListNoFormik } from "../../common/positions-check-list";
import { BackButton } from "../../common/backbutton";
import { ConnectionRequestInfoAndButtons } from "../../common/notifications";
import { getRegions } from "../../../services/regions";
import { Footer } from "../main-page/footer";
import { ASSETS_ROUTES, ERROR_IS_FRAUD } from "../../../lib/common/constants";
import { ReportButton } from "../../common/report-button";
import { UserNotExist } from "../../common/user-not-exist";
import { Spinner } from "../../common/spinner";
import { Spinner as SpinnerSmall } from "../../sections/backoffice/common/spinner";
import {
    ErrorMessageFraud,
    ErrorMessageSticky,
} from "../../common/alert-message";
import { BlacklistButton } from "../../common/blacklist-button";
import {
    numberRegex,
    phoneFormat,
    phoneRegex,
    websiteRegex,
} from "../../../lib/common/validation";
import { GallerySection } from "./gallery-section";
import { MessageBtn } from "../../common/message-button";
declare global {
    interface Window {
        wwgLabInfo_dataWasModified: boolean;
    }
}

type TDentalLabInfoProps = {
    editable?: boolean;
    labExist: boolean;
    labInfo?: TApiLab;
};

dayjs.extend(utc);

export const LabInfo = ({
    editable = false,
    labExist,
    labInfo,
}: TDentalLabInfoProps) => {
    const [logoImg, setLogo] = useState<string | undefined>(undefined);
    const [backgroundImg, setBackgroundImg] = useState<string | undefined>(
        undefined
    );
    const [externalPhoto, setExternalPhoto] = useState<string | undefined>(
        undefined
    );
    const [internalPhoto, setInternalPhoto] = useState<string | undefined>(
        undefined
    );

    const [inappropriateContent, setInappropriateContent] = useState(false);
    const [unsupportedImageType, setUnsupportedImageType] = useState(false);
    const [isSomePartBeingEdited, setIsSomePartBeingEdited] = useState(false);

    const croppedImage: CSSProperties = {
        maxWidth: "100%",
        height: "300px",
        objectFit: "cover",
    };

    const updateLabAndLocalStorage = async (
        data: LabSignUpFormData,
        name?: string
    ) => {
        await updateLab(data, name);
        updateUserInfoInStorage(
            LabSignUpFormData2UserInfo(data, userInfo, name)
        );
        window.wwgLabInfo_dataWasModified = true;
    };

    const updateBackground = (
        newfile?: string,
        inappropriateContent?: boolean,
        unsupportedImageType?: boolean
    ) => {
        if (inappropriateContent) {
            window.scrollTo({ top: 0 });
            setInappropriateContent(true);
            setBackgroundImg(undefined);
        } else if (unsupportedImageType) {
            window.scrollTo({ top: 0 });
            setUnsupportedImageType(true);
            setBackgroundImg(undefined);
        } else {
            if (!newfile) {
                setBackgroundImg(undefined);
            } else {
                const cacheKill = editable
                    ? `?${getCacheKillQueryString()}`
                    : "";
                setInappropriateContent(false);
                setUnsupportedImageType(false);
                setBackgroundImg(
                    getImagePath(
                        newfile,
                        "fixed",
                        !editable ? userId : undefined
                    ) + cacheKill
                );
            }
        }
    };

    const updateLogo = (
        newfile?: string,
        inappropriateContent?: boolean,
        unsupportedImageType?: boolean
    ) => {
        if (inappropriateContent) {
            window.scrollTo({ top: 0 });
            setInappropriateContent(true);
            setLogo(ASSETS_ROUTES.IMGS.labAvatarUrl);
        } else if (unsupportedImageType) {
            window.scrollTo({ top: 0 });
            setUnsupportedImageType(true);
            setLogo(ASSETS_ROUTES.IMGS.labAvatarUrl);
        } else {
            if (!newfile) {
                setLogo(ASSETS_ROUTES.IMGS.labAvatarUrl);
            } else {
                const cacheKill = editable
                    ? `?${getCacheKillQueryString()}`
                    : "";
                setInappropriateContent(false);
                setUnsupportedImageType(false);
                setLogo(
                    getImagePath(
                        newfile,
                        "fixed",
                        !editable ? userId : undefined
                    ) + cacheKill
                );
            }
        }
    };
    const updateExternalPhoto = (
        newfile?: string,
        inappropriateContent?: boolean,
        unsupportedImageType?: boolean
    ) => {
        if (inappropriateContent) {
            window.scrollTo({ top: 0 });
            setInappropriateContent(true);
            setExternalPhoto(undefined);
        } else if (unsupportedImageType) {
            window.scrollTo({ top: 0 });
            setUnsupportedImageType(true);
            setExternalPhoto(undefined);
        } else {
            if (!newfile) {
                setExternalPhoto(undefined);
            } else {
                const cacheKill = editable
                    ? `?${getCacheKillQueryString()}`
                    : "";
                setInappropriateContent(false);
                setUnsupportedImageType(false);
                setExternalPhoto(
                    getImagePath(
                        newfile,
                        "fixed",
                        !editable ? userId : undefined
                    ) + cacheKill
                );
            }
        }
    };

    const updateInternalPhoto = (
        newfile?: string,
        inappropriateContent?: boolean,
        unsupportedImageType?: boolean
    ) => {
        if (inappropriateContent) {
            window.scrollTo({ top: 0 });
            setInappropriateContent(true);
            setInternalPhoto(undefined);
        } else if (unsupportedImageType) {
            window.scrollTo({ top: 0 });
            setUnsupportedImageType(true);
            setInternalPhoto(undefined);
        } else {
            if (!newfile) {
                setInternalPhoto(undefined);
            } else {
                const cacheKill = editable
                    ? `?${getCacheKillQueryString()}`
                    : "";
                setInappropriateContent(false);
                setUnsupportedImageType(false);
                setInternalPhoto(
                    getImagePath(
                        newfile,
                        "fixed",
                        !editable ? userId : undefined
                    ) + cacheKill
                );
            }
        }
    };

    const { userId } = useParams();
    const {
        userInfo: currentUserInfo,
        updateUserInfoFromLocalStorage,
        updateUserInfoInStorage,
    } = useAuthContext();
    const { getImagePath, makeDroppableCapable } = useFileManagement();
    const { updateLab, LabSignUpFormData2UserInfo } = useUserService();
    const { getLab, getRestaurants, getEvents, getLandmarks } = useLabs();
    const [workingState, setWorkingState] = useState<TProcessStates>("working");
    const [desiredRegion, setdesiredRegion] = useState<TGenericIdName[]>([]);
    const [positions, setPositions] = useState<TGenericIdName[]>([]);
    const [userInfo, setUserInfo] = useState<TApiLab>({
        id: "",
        name: "",
        inmycontactlist: false,
        invitationsenttome: false,
        is_in_blacklist: "",
        other: {
            desiredRegion: "",
            desiredSalaryPerYear: 0,
            email: "",
            firstName: "",
            hobbies: [],
            imgUrl: "",
            interests: [],
            lastName: "",
            positions: [],
            yearsOfExperience: 0,
            philosophy: "",
            location: "",
            phone: "",
            website: "",
            size: "",
            specialty: "",
            history: "",
            description: "",
            zip: "",
            aboutArea: "",
            video: "",
            aboutRestaurants: [
                {
                    isSelected: false,
                    id: "",
                    name: "",
                    image_url: "",
                    url: "",
                    rating: 0,
                    categories: [],
                },
            ],
            aboutEvents: [
                {
                    isSelected: false,
                    id: "",
                    name: "",
                    url: "",
                    dates: {
                        start: {
                            localDate: "",
                        },
                    },
                    images: [],
                    classifications: [
                        {
                            segment: {
                                name: "",
                            },
                        },
                    ],
                },
            ],
            aboutLandmarks: [
                {
                    isSelected: false,
                    id: "",
                    name: "",
                    image_url: "",
                    url: "",
                    rating: 0,
                    location: {
                        address1: "",
                    },
                },
            ],
        },
        images: {
            background: "",
            external: "",
            internal: "",
            logo: "",
            resume: "",
        },
    });
    const [userExist, setUserExist] = useState<boolean>(true);
    const refLogoPhoto = useRef<HTMLDivElement>(null);
    const refProfileBackround = useRef<HTMLDivElement>(null);
    const refProfileInternalPhoto = useRef<HTMLDivElement>(null);
    const refProfileExternalPhoto = useRef<HTMLDivElement>(null);

    const bringUserInfo = async () => {
        if (!labExist) {
            setUserExist(false);
            return;
        }

        const info =
            labInfo !== undefined
                ? [labInfo]
                : await getLab(userId || (currentUserInfo?.id as string));
        if (info.length === 0) {
            setUserExist(false);
            return;
        }
        setUserInfo(info[0]);

        const extraValues = await Promise.all([
            getRegions(),
            getIndustryRoles(),
        ]);
        setdesiredRegion(extraValues[0]);
        setPositions(extraValues[1]);

        updateLogo({ ...info[0]?.images }?.logo);
        updateBackground({ ...info[0]?.images }?.background);
        updateExternalPhoto({ ...info[0]?.images }?.external);
        updateInternalPhoto({ ...info[0]?.images }?.internal);

        setWorkingState("done");
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        bringUserInfo();

        if (editable) {
            makeDroppableCapable(
                refLogoPhoto,
                "logo",
                "image",
                "fixed",
                (newfile, inappropriateContent, unsupportedImageType) => {
                    updateLogo(
                        newfile,
                        inappropriateContent,
                        unsupportedImageType
                    );
                }
            );
            makeDroppableCapable(
                refProfileBackround,
                "background",
                "image",
                "fixed",
                (newfile, inappropriateContent, unsupportedImageType) => {
                    updateBackground(
                        newfile,
                        inappropriateContent,
                        unsupportedImageType
                    );
                }
            );
            makeDroppableCapable(
                refProfileExternalPhoto,
                "external",
                "image",
                "fixed",
                (newfile, inappropriateContent, unsupportedImageType) => {
                    updateExternalPhoto(
                        newfile,
                        inappropriateContent,
                        unsupportedImageType
                    );
                }
            );
            makeDroppableCapable(
                refProfileInternalPhoto,
                "internal",
                "image",
                "fixed",
                (newfile, inappropriateContent, unsupportedImageType) => {
                    updateInternalPhoto(
                        newfile,
                        inappropriateContent,
                        unsupportedImageType
                    );
                }
            );
        }

        return () => {
            if (window.wwgLabInfo_dataWasModified) {
                window.wwgLabInfo_dataWasModified = false;
                updateUserInfoFromLocalStorage();
            }
        };

        // eslint-disable-next-line
    }, [userId]);

    const positionsList = (): TGenericIdName[] => {
        if (!userInfo || !userInfo?.other?.positions) return [];

        return positions.filter((pos) => {
            return userInfo.other?.positions.includes(pos.id);
        });
    };

    const editableStyle: React.CSSProperties = {
        cursor: "pointer",
    };

    const [isEditingPositions, setIsEditingPositions] = useState(false);
    const refPositions = useRef<any>({});
    const PositionsSection = () => {
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingPositions(true);
            setIsSomePartBeingEdited(true);
        };

        const savePositions = async () => {
            const newPositions = Object.keys(refPositions.current).filter(
                (val) => refPositions.current[val]
            );

            setCurrWorkingState("working");
            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...userInfo.other,
                    positions: newPositions,
                },
                undefined
            );
            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    positions: newPositions,
                },
            });
            setIsEditingPositions(false);
            setIsSomePartBeingEdited(false);
            setCurrWorkingState("done");
        };

        let defaultValue: {
            [key: string]: boolean;
        } = {};

        const cancelHandler = async () => {
            setIsEditingPositions(false);
            setIsSomePartBeingEdited(false);
        };

        useEffect(() => {
            const selectedPositions = userInfo.other?.positions.reduce(
                (prev, curr) => {
                    return {
                        ...prev,
                        [curr]: true,
                    };
                },
                defaultValue
            );

            refPositions.current = selectedPositions;
            //eslint-disable-next-line
        }, []);

        return (
            <>
                {!isEditingPositions && (
                    <>
                        {userId && (
                            <h6
                                className={
                                    "fw-bold text-center " +
                                    (workingState !== "done" ||
                                    currWorkingState !== "done"
                                        ? " cssLoading"
                                        : "")
                                }
                            >
                                POSITIONS WE ARE CURRENTLY LOOKING TO FILL:
                            </h6>
                        )}
                        {editable && (
                            <>
                                <div>
                                    {!isSomePartBeingEdited && (
                                        <div
                                            className="fileTooltip rightEdit ms-auto mb-2"
                                            role="button"
                                            data-tooltip=" Edit "
                                            onClick={editableOnClickHandler}
                                        >
                                            <i
                                                className="fa-solid fa-pen-to-square fa-xs p-2 shadow editIcon"
                                                data-testid="edit-icon"
                                            ></i>
                                        </div>
                                    )}
                                </div>
                                <h6
                                    className={
                                        "fw-bold text-center " +
                                        (workingState !== "done" ||
                                        currWorkingState !== "done"
                                            ? " cssLoading"
                                            : "")
                                    }
                                    onClick={(e) => {
                                        if (isSomePartBeingEdited) return;
                                        editableOnClickHandler(e);
                                    }}
                                    role="button"
                                >
                                    POSITIONS WE ARE CURRENTLY LOOKING TO FILL:
                                </h6>
                            </>
                        )}
                        <div className="mt-3 text-center">
                            {positionsList().map((pos) => (
                                <span className="badge rounded-pill text-primary bg-white border-primary border-2 border me-2 mb-2 fs-6">
                                    {" "}
                                    {pos.name}
                                </span>
                            ))}
                        </div>
                    </>
                )}
                {isEditingPositions && (
                    <>
                        <h6
                            className={
                                "fw-bold text-center mt-4 " +
                                (workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? " cssLoading"
                                    : "")
                            }
                        >
                            POSITIONS WE ARE CURRENTLY LOOKING TO FILL:
                        </h6>
                        <div
                            className={
                                "" +
                                (workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? " cssLoading"
                                    : "")
                            }
                        >
                            <div className="row p-2">
                                <PositionsCheckListNoFormik
                                    positions={positions}
                                    checkedList={refPositions.current}
                                    refs={refPositions}
                                />
                            </div>
                            <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                                <button
                                    className="btn btn-primary px-5"
                                    onClick={savePositions}
                                >
                                    Save
                                </button>
                                <button
                                    className="btn btn-danger ms-md-2 px-5"
                                    onClick={cancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const [isEditingName, setIsEditingName] = useState(false);
    const nameRef = useRef<any>();
    const NameSection = () => {
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingName(true);
            setIsSomePartBeingEdited(true);
        };

        const saveName = async () => {
            setCurrWorkingState("working");
            setIsEditingName(false);
            setIsSomePartBeingEdited(false);
            const newName = nameRef.current.value;
            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...userInfo.other,
                },
                newName
            );
            setUserInfo({
                ...userInfo,
                name: newName,
            });
            setCurrWorkingState("done");
        };

        const cancelHandler = async () => {
            nameRef.current.value = userInfo?.name;
            setIsEditingName(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <>
                {!isEditingName && (
                    <>
                        {userId && (
                            <div className="text-center text-break">
                                <h4
                                    className={
                                        workingState !== "done" ||
                                        currWorkingState !== "done"
                                            ? "cssLoading"
                                            : "fw-bold"
                                    }
                                >
                                    {workingState === "done" ? (
                                        nameRef.current?.value || userInfo?.name
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </h4>
                            </div>
                        )}
                        {editable && (
                            <div className="d-flex align-items-center justify-content-between w-100 text-break">
                                <div
                                    role="button"
                                    onClick={(e) => {
                                        if (isSomePartBeingEdited) return;
                                        editableOnClickHandler(e);
                                    }}
                                    className={
                                        workingState !== "done" ||
                                        currWorkingState !== "done"
                                            ? " cssLoading"
                                            : "px-2 fs-3 fw-bold"
                                    }
                                >
                                    {workingState === "done" ? (
                                        nameRef.current?.value || userInfo?.name
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </div>
                                {!isSomePartBeingEdited && (
                                    <div
                                        className="fileTooltip rightEdit "
                                        role="button"
                                        data-tooltip=" Edit "
                                        onClick={editableOnClickHandler}
                                    >
                                        <i
                                            className="fa-solid fa-pen-to-square fa-xs p-2 shadow editIcon"
                                            data-testid="edit-icon"
                                        ></i>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
                {isEditingName && (
                    <div className="text-center my-3">
                        <input
                            className="text-center mb-2 form-control form-control-lg"
                            ref={nameRef}
                            type="text"
                            defaultValue={userInfo?.name}
                            maxLength={50}
                        />
                        <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                            <button
                                className="btn btn-primary px-4"
                                onClick={saveName}
                            >
                                {currWorkingState === "working" ? (
                                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                                ) : (
                                    <>Save</>
                                )}
                            </button>
                            <button
                                className="btn btn-danger ms-md-2 px-3 "
                                onClick={cancelHandler}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const [isEditingPhilosophy, setIsEditingPhilosophy] = useState(false);
    const philosophyRef = useRef<any>();
    const PhilosophySection = () => {
        const [showError, setShowError] = useState(false);
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingPhilosophy(true);
            setIsSomePartBeingEdited(true);
        };

        const savePhilosophy = async () => {
            const newData = {
                ...userInfo.other,
                philosophy: philosophyRef.current.value as string,
            };
            setCurrWorkingState("working");
            try {
                setShowError(false);
                await updateLabAndLocalStorage(
                    {
                        password: "",
                        ...newData,
                    },
                    ""
                );
                setUserInfo({
                    ...userInfo,
                    other: newData,
                });
                setIsEditingPhilosophy(false);
            } catch (error: any) {
                if (ERROR_IS_FRAUD(error)) {
                    setShowError(true);
                }

                setIsEditingPhilosophy(true);
            }
            setIsSomePartBeingEdited(false);
            setCurrWorkingState("done");
        };

        const cancelHandler = async () => {
            if (philosophyRef.current) {
                philosophyRef.current.value = userInfo?.other?.philosophy || "";
            }
            setShowError(false);
            setIsEditingPhilosophy(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <>
                {!isEditingPhilosophy && (
                    <>
                        {editable && !isSomePartBeingEdited && (
                            <div>
                                <div
                                    className="fileTooltip rightEdit ms-auto"
                                    role="button"
                                    data-tooltip=" Edit "
                                    onClick={editableOnClickHandler}
                                >
                                    <i
                                        className="fa-solid fa-pen-to-square fa-xs p-2 shadow editIcon"
                                        data-testid="edit-icon"
                                    ></i>
                                </div>
                            </div>
                        )}
                        <h4
                            className={
                                "text-center " +
                                (workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : "")
                            }
                            style={
                                editable && !isSomePartBeingEdited
                                    ? editableStyle
                                    : {}
                            }
                            onClick={(e) => {
                                if (isSomePartBeingEdited) return;
                                editableOnClickHandler(e);
                            }}
                        >
                            COMPANY PHILOSOPHY
                        </h4>
                        <p
                            className={
                                "lh-base px-2 text-break " +
                                (workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : "")
                            }
                        >
                            {philosophyRef.current?.value ||
                                userInfo.other.philosophy ||
                                "   "}
                        </p>
                    </>
                )}
                {isEditingPhilosophy && (
                    <>
                        {showError && (
                            <ErrorMessageFraud
                                closeClickHandler={() => {
                                    setShowError(false);
                                }}
                            />
                        )}

                        <h3 className="text-center">COMPANY PHILOSOPHY</h3>
                        <div className="my-3">
                            <textarea
                                disabled={currWorkingState === "working"}
                                ref={philosophyRef}
                                className="form-control lh-lg text-justify mb-3 py-3"
                                rows={8}
                                maxLength={1000}
                                placeholder="Tell us about your company's philosophy!"
                                defaultValue={userInfo.other?.philosophy}
                            />
                            <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                                <button
                                    className="btn btn-primary px-5"
                                    disabled={currWorkingState === "working"}
                                    type="button"
                                    onClick={savePhilosophy}
                                >
                                    Save{" "}
                                    {currWorkingState === "working" && (
                                        <span className="ms-2">
                                            <SpinnerSmall />
                                        </span>
                                    )}
                                </button>
                                <button
                                    className="btn btn-danger ms-md-2 px-5"
                                    disabled={currWorkingState === "working"}
                                    type="button"
                                    onClick={cancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const [isEditingAbout, setIsEditingAbout] = useState(false);
    const AboutTheLabSection = () => {
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingAbout(true);
            setIsSomePartBeingEdited(true);
        };
        const refLocation = useRef<any>();
        const refRegion = useRef<any>({});
        const refPhone = useRef<any>();
        const refSize = useRef<any>();
        const refSpecialty = useRef<any>();
        const refHistory = useRef<any>();
        const refZip = useRef<any>();
        const refWebSite = useRef<any>();

        const [errorPhone, setErrorPhone] = useState("");
        const [errorZip, setErrorZip] = useState("");
        const [errorWebsite, setErrorWebsite] = useState("");

        const phoneFormatHandler = (phoneNumberString: string) => {
            const match = phoneNumberString.match(phoneFormat);
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return "Phone number not verified";
        };

        const saveData = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setCurrWorkingState("working");

            const phoneValid = refPhone.current.value;
            const zipValid = refZip.current.value;
            const websiteValid = refWebSite.current.value;

            let hasError = false;

            if (!numberRegex.test(zipValid)) {
                setErrorZip("Please use numbers only for your ZIP.");
                hasError = true;
            } else {
                setErrorZip("");
            }
            if (!phoneRegex.test(phoneValid) && phoneValid.length > 0) {
                setErrorPhone("Please enter a valid 10-digit phone number.");
                hasError = true;
            } else {
                setErrorPhone("");
            }
            if (websiteValid && !websiteRegex.test(websiteValid)) {
                setErrorWebsite("Please check that your URL is correct.");
                hasError = true;
            } else {
                setErrorWebsite("");
            }
            if (hasError) {
                setCurrWorkingState("done");
                return;
            }

            const newData = {
                ...userInfo.other,
                location: refLocation.current.value,
                phone: refPhone.current.value,
                website: refWebSite.current.value,
                size: refSize.current.value,
                specialty: refSpecialty.current.value,
                history: refHistory.current.value,
                zip: refZip.current.value,
                desiredRegion: refRegion.current.value,
            };

            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...newData,
                },
                ""
            );

            if (
                userInfo.other.zip !== newData.zip &&
                userInfo.other.aboutRestaurants
            ) {
                newData.aboutRestaurants = [];
            }
            if (
                userInfo.other.zip !== newData.zip &&
                userInfo.other.aboutEvents
            ) {
                newData.aboutEvents = [];
            }
            if (
                userInfo.other.zip !== newData.zip &&
                userInfo.other.aboutLandmarks
            ) {
                newData.aboutLandmarks = [];
            }

            setUserInfo({
                ...userInfo,
                other: newData,
            });

            setIsEditingAbout(false);
            setIsSomePartBeingEdited(false);
            setCurrWorkingState("done");
        };

        const regionList = (): TGenericIdName | undefined => {
            if (!userInfo || !userInfo.other?.desiredRegion) return undefined;

            const selectedRegion = desiredRegion.find((region) => {
                return userInfo.other?.desiredRegion.includes(region.id);
            });

            return selectedRegion;
        };

        const cancelHandler = async (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setIsEditingAbout(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <div
                className=""
                style={editable && !isSomePartBeingEdited ? editableStyle : {}}
                onClick={(e) => {
                    if (isSomePartBeingEdited) return;
                    editableOnClickHandler(e);
                }}
            >
                <div className="d-flex justify-content-end">
                    {/* <h4 >
                        About the Lab
                    </h4> */}
                    {editable && !isSomePartBeingEdited && (
                        <div
                            className="fileTooltip rightEdit"
                            data-tooltip=" Edit "
                            role="button"
                        >
                            <i
                                data-testid="edit-icon"
                                className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                            ></i>
                        </div>
                    )}
                </div>
                {!isEditingAbout && (
                    <div className="text-break">
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">Region: </strong>
                            {regionList()?.name}
                        </p>
                        <p
                            className={
                                " " +
                                (workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : "")
                            }
                        >
                            <strong className="text-primary">Address: </strong>{" "}
                            {userInfo.other.location}
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">ZIP code: </strong>{" "}
                            {userInfo.other.zip}
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">
                                Phone Number:{" "}
                            </strong>
                            {userInfo.other.phone
                                ? phoneFormatHandler(userInfo.other.phone)
                                : ""}
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">Website: </strong>
                            <a
                                href={`${
                                    userInfo.other.website?.startsWith("http")
                                        ? userInfo.other.website
                                        : `http://${userInfo.other.website}`
                                }`}
                                target="_blank"
                                className="btn-link"
                                rel="noopener noreferrer"
                            >
                                {userInfo.other.website}
                            </a>
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">Size: </strong>{" "}
                            {userInfo.other.size}
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">
                                Specialty:{" "}
                            </strong>{" "}
                            {userInfo.other.specialty}
                        </p>
                        <p
                            className={
                                workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : ""
                            }
                        >
                            <strong className="text-primary">History: </strong>{" "}
                            {userInfo.other.history}
                        </p>
                    </div>
                )}
                {isEditingAbout && (
                    <div className="text-break ">
                        <p>
                            <strong className="text-primary">Region: </strong>{" "}
                            <small className="text-primary fst-italic">
                                (required)
                            </small>
                            <div className="">
                                <select
                                    className="form-select"
                                    ref={refRegion}
                                    defaultValue={
                                        refRegion.current?.value ||
                                        userInfo?.other?.desiredRegion
                                    }
                                >
                                    {desiredRegion.map((region) => (
                                        <option
                                            key={region.id}
                                            value={region.id}
                                        >
                                            {region.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </p>
                        <p>
                            <strong className="text-primary">Address: </strong>{" "}
                            <input
                                ref={refLocation}
                                defaultValue={userInfo.other.location}
                                type="text"
                                placeholder="Street name, city, state"
                                className="form-control"
                                maxLength={100}
                            />
                        </p>
                        <p>
                            {" "}
                            <strong className="text-primary">
                                ZIP code:{" "}
                            </strong>{" "}
                            <small className="text-primary fst-italic">
                                (required)
                            </small>
                            <input
                                ref={refZip}
                                defaultValue={userInfo.other.zip}
                                type="text"
                                className="form-control"
                                maxLength={10}
                                placeholder="Zip Code is required"
                            />
                            {errorZip && (
                                <p className="text-danger">
                                    <small>{errorZip}</small>
                                </p>
                            )}
                        </p>
                        <p>
                            <strong className="text-primary">
                                Phone Number:{" "}
                            </strong>{" "}
                            <input
                                ref={refPhone}
                                defaultValue={userInfo.other.phone}
                                type="text"
                                maxLength={10}
                                placeholder="10 digit phone number"
                                className="form-control"
                            />
                            {errorPhone && (
                                <p className="text-danger">
                                    <small>{errorPhone}</small>
                                </p>
                            )}
                        </p>
                        <p>
                            <strong className="text-primary">Website: </strong>{" "}
                            <input
                                ref={refWebSite}
                                defaultValue={userInfo.other.website}
                                type="text"
                                className="form-control"
                                maxLength={50}
                            />
                            {errorWebsite && (
                                <p className="text-danger">
                                    <small>{errorWebsite}</small>
                                </p>
                            )}
                        </p>
                        <p>
                            <strong className="text-primary">Size: </strong>{" "}
                            <input
                                ref={refSize}
                                defaultValue={userInfo.other.size}
                                type="text"
                                className="form-control"
                                maxLength={50}
                            />
                        </p>
                        <p>
                            <strong className="text-primary">
                                Specialty:{" "}
                            </strong>{" "}
                            <textarea
                                ref={refSpecialty}
                                defaultValue={userInfo.other.specialty}
                                className="form-control"
                                maxLength={250}
                                rows={5}
                            />
                        </p>
                        <p>
                            <strong className="text-primary">History: </strong>{" "}
                            <textarea
                                ref={refHistory}
                                defaultValue={userInfo.other.history}
                                className="form-control"
                                maxLength={500}
                                rows={8}
                            />
                        </p>

                        <div className="d-grid gap-2 mt-2 w-100 d-md-block">
                            <button
                                className="btn btn-primary px-4"
                                onClick={saveData}
                            >
                                {currWorkingState === "working" ? (
                                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                                ) : (
                                    <>Save</>
                                )}
                            </button>
                            <button
                                className="btn btn-danger ms-lg-2 px-3 mt-md-2 mt-lg-0"
                                onClick={(e) => {
                                    cancelHandler(e);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    const InvitationButtons = () => {
        const { invitationsList } = useNotificationContext();
        return (
            <div className="text-center">
                {userInfo.invitationsenttome &&
                    invitationsList.findIndex((p) => p.from === userInfo.id) >
                        -1 && (
                        <ConnectionRequestInfoAndButtons
                            userId={userInfo.id}
                            wideStyle={true}
                            userName={userInfo.name}
                        />
                    )}
            </div>
        );
    };

    const [isEditingArea, setIsEditingArea] = useState(false);
    const refAboutArea = useRef<any>();
    const AboutOurArea = () => {
        const [showError, setShowError] = useState(false);
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingArea(true);
            setIsSomePartBeingEdited(true);
        };

        const saveArea = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setShowError(false);
            setCurrWorkingState("working");
            try {
                const newData = {
                    ...userInfo.other,
                    aboutArea: refAboutArea.current.value,
                };
                await updateLabAndLocalStorage(
                    {
                        password: "",
                        ...newData,
                    },
                    ""
                );
                setIsEditingArea(false);

                setUserInfo({
                    ...userInfo,
                    other: newData,
                });
            } catch (error) {
                if (ERROR_IS_FRAUD(error)) {
                    setShowError(true);
                }
                setIsEditingArea(true);
            }
            setIsSomePartBeingEdited(false);
            setCurrWorkingState("done");
        };

        const cancelHandler = async () => {
            refAboutArea.current.value = userInfo?.other.aboutArea;
            setShowError(false);
            setIsEditingArea(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <>
                <div className="d-flex justify-content-start mb-2">
                    <h4 className="fw-bold">ABOUT OUR AREA</h4>
                    {!isEditingArea && editable && !isSomePartBeingEdited && (
                        <div
                            className="fileTooltip leftEdit ms-auto ms-lg-3"
                            data-tooltip=" Edit "
                            role="button"
                            onClick={editableOnClickHandler}
                        >
                            <i
                                data-testid="edit-icon"
                                className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                            ></i>
                        </div>
                    )}
                </div>
                {editable && !userInfo.other.aboutArea && (
                    <>
                        <div className="border rounded p-4 d-flex justify-content-center flex-column align-items-center">
                            <i className="fa-solid fa-align-left mb-2 icon-no-photos"></i>
                            <p
                                className="m-0 text-center"
                                style={{ color: "#a5a5a5" }}
                            >
                                <strong>
                                    {" "}
                                    Add a personal touch to your profile!{" "}
                                </strong>
                                <br />
                                Click on Edit to write a description about the
                                local area, highlighting what makes your
                                community special.
                                <br />
                                It’s a great way to make your profile more
                                inviting.
                            </p>
                        </div>
                    </>
                )}
                {!isEditingArea && (
                    <>
                        {userInfo.other?.aboutArea && (
                            <div className="mb-2 mb-lg-5 text-break">
                                <p
                                    className={
                                        workingState !== "done" ||
                                        currWorkingState !== "done"
                                            ? "cssLoading"
                                            : "text-start m-0"
                                    }
                                >
                                    {userInfo.other.aboutArea}
                                </p>
                                {editable && !userInfo.other.aboutArea && (
                                    <>
                                        <div className="container bg-white rounded p-4 pt-md-3 pe-md-3 mb-3">
                                            <p>Click here to edit.</p>
                                            <p>
                                                Share a compelling and
                                                descriptive text about the area.{" "}
                                                <br></br> Highlight what makes
                                                the location special.
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
                {isEditingArea && (
                    <>
                        {showError && (
                            <ErrorMessageFraud
                                closeClickHandler={() => setShowError(false)}
                            />
                        )}
                        <>
                            <textarea
                                disabled={currWorkingState === "working"}
                                ref={refAboutArea}
                                className="form-control text-justify mb-2"
                                rows={7}
                                defaultValue={userInfo.other?.aboutArea}
                                maxLength={1000}
                            />
                            <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                                <button
                                    className="btn btn-primary px-5"
                                    disabled={currWorkingState === "working"}
                                    onClick={saveArea}
                                >
                                    Save{" "}
                                    {currWorkingState === "working" && (
                                        <span className="ms-2">
                                            <SpinnerSmall />
                                        </span>
                                    )}
                                </button>
                                <button
                                    className="btn btn-danger ms-md-2 px-5"
                                    disabled={currWorkingState === "working"}
                                    onClick={cancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </>
                    </>
                )}
            </>
        );
    };

    const [isEditingVideo, setIsEditingVideo] = useState(false);
    const refVideo = useRef<any>();
    const Video = () => {
        const [currWorkingState, setCurrWorkingState] =
            useState<TProcessStates>("done");
        const editableOnClickHandler = (e: any) => {
            if (!editable) return;
            if (isSomePartBeingEdited) return;
            e.preventDefault();
            e.stopPropagation();
            setIsEditingVideo(true);
            setIsSomePartBeingEdited(true);
        };

        const saveVideo = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setIsEditingVideo(true);
            setCurrWorkingState("working");
            const newData = {
                ...userInfo.other,
                video: refVideo.current.value,
            };

            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...newData,
                },
                ""
            );

            setUserInfo({
                ...userInfo,
                other: newData,
            });
            setIsEditingVideo(false);
            setCurrWorkingState("done");
            setIsSomePartBeingEdited(false);
        };

        const getVideoUrl = (videoLink: string) => {
            const videoId = getVideoID(videoLink);

            if (
                videoLink.includes("youtube.com") ||
                videoLink.includes("youtu.be")
            ) {
                return `https://www.youtube.com/embed/${videoId}`;
            } else if (videoLink.includes("vimeo.com")) {
                return `https://player.vimeo.com/video/${videoId}`;
            } else {
                return "";
            }
        };
        const getVideoID = (inputLink: string) => {
            if (
                inputLink.includes("youtube.com") ||
                inputLink.includes("youtu.be")
            ) {
                // eslint-disable-next-line no-useless-escape
                const regex =
                    // eslint-disable-next-line no-useless-escape
                    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                const id = inputLink.match(regex);
                return id ? id[1] : null;
            } else if (inputLink.includes("vimeo.com")) {
                const regex =
                    /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
                const vimeoId = inputLink.match(regex);
                return vimeoId ? vimeoId[1] : null;
            } else {
                return null;
            }
        };

        const cancelHandler = async () => {
            refVideo.current.value = userInfo?.other.video;
            setIsEditingVideo(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <>
                {!isEditingVideo && (
                    <>
                        {editable && !isSomePartBeingEdited && (
                            <div
                                className="fileTooltip right ms-auto mb-3"
                                data-tooltip="Add a Video! Your video should be a YouTube or Vimeo url link. Find the video that best represents your area. On YouTube, click share below the video and then copy and paste the link into the editor. "
                                role="button"
                                onClick={editableOnClickHandler}
                            >
                                <i
                                    data-testid="edit-icon"
                                    className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                                ></i>
                            </div>
                        )}
                        {currWorkingState === "done" &&
                            !userInfo.other?.video &&
                            editable && (
                                <div
                                    className={
                                        "border rounded p-4 d-flex justify-content-center flex-column align-items-center " +
                                        (workingState === "done"
                                            ? ""
                                            : "cssLoading")
                                    }
                                >
                                    <i className="fa-solid fa-video mb-2 icon-no-photos"></i>
                                    <p
                                        className="m-0 text-center"
                                        style={{ color: "#a5a5a5" }}
                                    >
                                        Make your profile stand out!
                                        <br />{" "}
                                        <strong>
                                            {" "}
                                            Click on Edit to upload a video
                                        </strong>{" "}
                                        showcasing the local environment,
                                        community, or nearby attractions.
                                        <br /> It’s a great way to make your
                                        profile more appealing.
                                    </p>
                                </div>
                            )}
                        {currWorkingState === "done" &&
                            userInfo.other?.video && (
                                <div className="ratio ratio-16x9">
                                    <iframe
                                        width="1100"
                                        height="725"
                                        className="rounded"
                                        src={getVideoUrl(userInfo.other?.video)}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                    </>
                )}
                {isEditingVideo && (
                    <>
                        <div
                            className={
                                "w-100 mb-3 rounded dashed-border border-primary p-4 d-flex justify-content-center flex-column align-items-center " +
                                +(workingState !== "done" ||
                                currWorkingState !== "done"
                                    ? "cssLoading"
                                    : "")
                            }
                        >
                            <i className="fa-brands fa-youtube icon-photos mb-2">
                                <i className="fa-brands fa-square-vimeo icon-photos ms-3"></i>
                            </i>
                            <p className="text-center">
                                Showcase the area around your lab!{" "}
                            </p>
                            <p className="text-center mb-2">
                                Share a YouTube or Vimeo link featuring the
                                local environment, community, or attractions.
                            </p>
                            <input
                                ref={refVideo}
                                className="text-center mb-2 form-control"
                                type="text"
                                defaultValue={userInfo?.other.video}
                                placeholder="Paste your video link here. (e.g. https://www.youtube.com/watch?v=tCezBBF726c)"
                            />
                            <div className="d-grid gap-3 mt-2 w-100">
                                <button
                                    className="btn btn-primary px-5"
                                    type="button"
                                    onClick={saveVideo}
                                >
                                    {currWorkingState === "working" ? (
                                        <i className="fa-solid fa-spinner fa-spin text-white"></i>
                                    ) : (
                                        <>Save</>
                                    )}
                                </button>
                                <button
                                    className="btn btn-danger px-5"
                                    type="button"
                                    onClick={cancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const [isExpandedRestaurant, setIsExpandedRestaurant] = useState(false);
    const [selectedRestaurants, setSelectedRestaurants] = useState<
        TRestaurant[]
    >([]);
    const [currWorkingStateRestaurants, setCurrWorkingStateRestaurants] =
        useState<TProcessStates>("done");

    const fetchRestaurantsData = async () => {
        const zip = userInfo.other?.zip || "";
        if (!zip || zip === "") {
            return;
        }
        setCurrWorkingStateRestaurants("working");
        try {
            const response = await getRestaurants(zip);
            const restaurants = response.data?.businesses || [];
            const userInfoSelectedRestaurantsMap = userInfo.other
                ?.aboutRestaurants
                ? userInfo.other.aboutRestaurants.reduce((prev, curr) => {
                      return {
                          ...prev,
                          [curr.id]: curr,
                      };
                  }, {} as Record<string, any>)
                : {};
            //we set the selected restaurants
            restaurants.forEach((r) => {
                r.isSelected =
                    userInfoSelectedRestaurantsMap[r.id] !== undefined
                        ? true
                        : false;
            });
            setSelectedRestaurants(restaurants);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setCurrWorkingStateRestaurants("idle");
        }
    };

    const Restaurants = () => {
        const [restaurantsPerPage, setRestaurantsPerPage] = useState(5);
        const totalRestaurants = userInfo.other.aboutRestaurants || [];
        const totalItems = Math.ceil(
            totalRestaurants.length / restaurantsPerPage
        );
        const [visibleRestaurants, setVisibleRestaurants] = useState(5);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth <= 991) {
                    setRestaurantsPerPage(3);
                } else {
                    setRestaurantsPerPage(5);
                }
            };
            handleResize();
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        const renderCarouselItems = () => {
            const carouselItems = [];

            for (let i = 0; i < totalItems; i++) {
                const startIdx = i * restaurantsPerPage;
                const endIdx = startIdx + restaurantsPerPage;
                const currentRestaurants = totalRestaurants.slice(
                    startIdx,
                    endIdx
                );

                const cardGroup = currentRestaurants.map((restaurant) => (
                    <div className="col">
                        <div
                            className="card bg-dark text-white rounded border-0 min-height-cards-restaurants w-auto"
                            key={restaurant.id}
                        >
                            <img
                                src={restaurant.image_url}
                                className="card-img-top rounded-top border border-2 border-dark restaurant-img"
                                alt="restaurant"
                            />
                            <div className="card-body">
                                <a
                                    href={restaurant.url}
                                    target="_blank"
                                    className="btn-link text-decoration-none"
                                    rel="noreferrer"
                                >
                                    <p className="card-title text-white">
                                        <strong>{restaurant.name}</strong>
                                    </p>
                                </a>
                                <p>
                                    <i className="fa-solid fa-star fa-xs"></i>{" "}
                                    {restaurant.rating}
                                </p>
                                <p className="card-text">
                                    {restaurant.categories &&
                                        restaurant?.categories?.map(
                                            (category) => (
                                                <span key={category.alias}>
                                                    {category.title}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                </p>
                            </div>
                        </div>
                    </div>
                ));

                //hack to make all the card equally width when there is less than what the page allows
                if (cardGroup.length < restaurantsPerPage) {
                    const DummyEmptyCard = (
                        <div className="card" style={{ border: "none" }}></div>
                    );
                    const remainingCards =
                        restaurantsPerPage - cardGroup.length;
                    for (let j = 0; j < remainingCards; j++)
                        cardGroup.push(DummyEmptyCard);
                }
                carouselItems.push(
                    <div
                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                        key={i}
                    >
                        <div
                            className={
                                "row g-3 row-cols-1 row-cols-md-" +
                                restaurantsPerPage
                            }
                        >
                            {cardGroup}
                        </div>
                    </div>
                );
            }

            return carouselItems;
        };

        const openRestaurantOptions = () => {
            setIsExpandedRestaurant(!isExpandedRestaurant);
            fetchRestaurantsData();
            setIsSomePartBeingEdited(true);
        };
        const handleCheckboxChange = (e: any, restaurant: TRestaurant) => {
            e.stopPropagation();
            setSelectedRestaurants((prev) => {
                const idx = prev.findIndex((r) => r.id === restaurant.id);
                prev[idx].isSelected = !prev[idx].isSelected;
                return prev;
            });
        };

        const saveRestaurants = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            setCurrWorkingStateRestaurants("working");
            const selectedInfo = selectedRestaurants.filter(
                (r) => r.isSelected
            );
            const newRestaurants = {
                ...userInfo.other,
                aboutRestaurants: selectedInfo,
            };

            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...newRestaurants,
                },
                ""
            );

            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    aboutRestaurants: selectedInfo,
                },
            });

            setIsExpandedRestaurant(false);
            setIsSomePartBeingEdited(false);
            setCurrWorkingStateRestaurants("done");
        };

        const cancelHandler = async () => {
            setIsExpandedRestaurant(false);
            setIsSomePartBeingEdited(false);
        };

        return (
            <>
                {!editable && userInfo.other.aboutRestaurants && (
                    <div className="container bg-white shadow-sm rounded p-4 mb-3">
                        <div className="row">
                            <h5 className="fw-bold pb-3">
                                FAVORITE RESTAURANTS
                            </h5>
                            <div className="d-none d-md-block">
                                <div className="min-height-cards-restaurants w-100 px-lg-5 px-md-3 px-2">
                                    {workingState === "working" && (
                                        <p className="p-3">
                                            Looking for restaurants in this
                                            area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutRestaurants &&
                                        userInfo.other.aboutRestaurants.length >
                                            0 && (
                                            <>
                                                <div
                                                    id="restCarousel"
                                                    className="carousel slide"
                                                    data-ride="carousel"
                                                    data-interval="false"
                                                >
                                                    <div className="carousel-inner">
                                                        {renderCarouselItems()}
                                                    </div>
                                                    {userInfo.other
                                                        .aboutRestaurants
                                                        .length > 5 && (
                                                        <div className="">
                                                            <a
                                                                className="carousel-control-prev"
                                                                href="#restCarousel"
                                                                role="button"
                                                                data-slide="prev"
                                                            >
                                                                <span
                                                                    className="carousel-control-prev-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Previous
                                                                </span>
                                                            </a>
                                                            <a
                                                                className="carousel-control-next"
                                                                href="#restCarousel"
                                                                role="button"
                                                                data-slide="next"
                                                            >
                                                                <span
                                                                    className="carousel-control-next-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Next
                                                                </span>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutRestaurants
                                            ?.length === 0 && (
                                            <p>No restaurants found.</p>
                                        )}
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                {workingState === "working" && (
                                    <p>
                                        Looking for restaurants in this area...
                                    </p>
                                )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutRestaurants &&
                                    userInfo.other.aboutRestaurants.length >
                                        0 && (
                                        <>
                                            <div>
                                                {userInfo.other.aboutRestaurants
                                                    .slice(
                                                        0,
                                                        visibleRestaurants
                                                    )
                                                    .map((restaurant) => (
                                                        <div
                                                            key={restaurant.id}
                                                            className="row mb-1"
                                                        >
                                                            <div className="card mb-3 w-100 ">
                                                                <div className="row no-gutters">
                                                                    <div className="col-4 p-0">
                                                                        <img
                                                                            className="card-img restaurant-img rounded-start "
                                                                            src={
                                                                                restaurant.image_url
                                                                            }
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <div className="card-body ">
                                                                            <a
                                                                                href={
                                                                                    restaurant.url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="btn-link text-decoration-none"
                                                                            >
                                                                                <small className="fw-bold">
                                                                                    {
                                                                                        restaurant.name
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                <small>
                                                                                    <i className="fa-solid fa-star fa-xs"></i>{" "}
                                                                                    {
                                                                                        restaurant.rating
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                {restaurant.categories?.map(
                                                                                    (
                                                                                        category,
                                                                                        index
                                                                                    ) => (
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "small",
                                                                                            }}
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                category.title
                                                                                            }
                                                                                            {index <
                                                                                                (
                                                                                                    restaurant.categories ||
                                                                                                    []
                                                                                                )
                                                                                                    .length -
                                                                                                    1 &&
                                                                                                ", "}
                                                                                        </span>
                                                                                    )
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            {visibleRestaurants <
                                                userInfo.other.aboutRestaurants
                                                    .length && (
                                                <div className="text-center">
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() =>
                                                            setVisibleRestaurants(
                                                                (prev) =>
                                                                    prev + 5
                                                            )
                                                        }
                                                    >
                                                        View More
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutRestaurants?.length ===
                                        0 && <p>No restaurants found.</p>}
                            </div>
                        </div>
                    </div>
                )}
                {editable && (
                    <div className="container bg-white shadow-sm rounded p-4 mb-3">
                        <div className="row">
                            <div className="d-flex justify-content-between p-2">
                                <h5 className="fw-bold">
                                    Favorite Restaurants
                                </h5>
                                {!isSomePartBeingEdited && (
                                    <div
                                        className="fileTooltip rightEdit"
                                        data-tooltip=" Edit "
                                        role="button"
                                        onClick={openRestaurantOptions}
                                        aria-expanded="false"
                                        aria-controls="collapseRestaurant"
                                    >
                                        <i
                                            data-testid="edit-icon"
                                            className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                                        ></i>
                                    </div>
                                )}
                            </div>
                            {isExpandedRestaurant && editable && (
                                <div>
                                    <div className="card card-body mb-3">
                                        <p>
                                            {" "}
                                            <strong>
                                                Select the restaurants you would
                                                like to display.
                                            </strong>
                                        </p>
                                        <div>
                                            {currWorkingStateRestaurants ===
                                                "working" && (
                                                <>
                                                    <Spinner />
                                                </>
                                            )}
                                            {currWorkingStateRestaurants ===
                                                "idle" &&
                                                selectedRestaurants.length >
                                                    0 && (
                                                    <div className="row">
                                                        {selectedRestaurants.map(
                                                            (restaurant) => (
                                                                <div
                                                                    className="col-md-6 col-lg-4 mb-3"
                                                                    key={
                                                                        restaurant.id
                                                                    }
                                                                >
                                                                    <div className="input-group h-100">
                                                                        <label
                                                                            htmlFor={`rChk_${restaurant.id}`}
                                                                            className="w-100"
                                                                        >
                                                                            <div className="row h-100 w-100 align-items-center input-group-text p-2">
                                                                                <div className="col-2 p-0">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        defaultChecked={
                                                                                            restaurant.isSelected
                                                                                        }
                                                                                        id={`rChk_${restaurant.id}`}
                                                                                        className="form-check-input"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleCheckboxChange(
                                                                                                e,
                                                                                                restaurant
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="col-4 p-0">
                                                                                    <img
                                                                                        src={
                                                                                            restaurant.image_url
                                                                                        }
                                                                                        className="rounded label-img"
                                                                                        alt="restaurant"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 text-wrap">
                                                                                    <p className="mb-1">
                                                                                        {" "}
                                                                                        <small>
                                                                                            <strong>
                                                                                                {
                                                                                                    restaurant.name
                                                                                                }
                                                                                            </strong>
                                                                                        </small>
                                                                                    </p>
                                                                                    <p
                                                                                        className="mb-0"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "x-small",
                                                                                        }}
                                                                                    >
                                                                                        {restaurant.categories?.map(
                                                                                            (
                                                                                                category,
                                                                                                index
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        category.title
                                                                                                    }
                                                                                                    {index <
                                                                                                        (
                                                                                                            restaurant.categories ||
                                                                                                            []
                                                                                                        )
                                                                                                            .length -
                                                                                                            1 &&
                                                                                                        ", "}
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {currWorkingStateRestaurants ===
                                                "idle" &&
                                                selectedRestaurants.length ===
                                                    0 && (
                                                    <p>
                                                        No restaurants found in
                                                        this area.
                                                    </p>
                                                )}
                                        </div>
                                        <div className="d-grid gap-3 mt-2 w-100 d-md-block">
                                            <button
                                                className="btn btn-primary px-4"
                                                type="button"
                                                onClick={saveRestaurants}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-danger ms-md-3 px-3"
                                                type="button"
                                                onClick={cancelHandler}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="d-none d-md-block">
                                <div className="min-height-cards-restaurants w-100 px-lg-5 px-md-3 px-2">
                                    {workingState === "working" && (
                                        <p className="p-3">
                                            Looking for restaurants in this
                                            area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutRestaurants &&
                                        userInfo.other.aboutRestaurants.length >
                                            0 && (
                                            <>
                                                <div
                                                    id="restCarousel"
                                                    className="carousel slide"
                                                    data-ride="carousel"
                                                    data-interval="false"
                                                >
                                                    <div className="carousel-inner">
                                                        {renderCarouselItems()}
                                                    </div>
                                                    {userInfo.other
                                                        .aboutRestaurants
                                                        .length > 5 && (
                                                        <div className="">
                                                            <a
                                                                className="carousel-control-prev"
                                                                href="#restCarousel"
                                                                role="button"
                                                                data-slide="prev"
                                                            >
                                                                <span
                                                                    className="carousel-control-prev-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Previous
                                                                </span>
                                                            </a>
                                                            <a
                                                                className="carousel-control-next"
                                                                href="#restCarousel"
                                                                role="button"
                                                                data-slide="next"
                                                            >
                                                                <span
                                                                    className="carousel-control-next-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Next
                                                                </span>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutRestaurants
                                            ?.length === 0 && (
                                            <p>No restaurants found.</p>
                                        )}
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                {workingState === "working" && (
                                    <p>
                                        Looking for restaurants in this area...
                                    </p>
                                )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutRestaurants &&
                                    userInfo.other.aboutRestaurants.length >
                                        0 && (
                                        <>
                                            <div>
                                                {userInfo.other.aboutRestaurants
                                                    .slice(
                                                        0,
                                                        visibleRestaurants
                                                    )
                                                    .map((restaurant) => (
                                                        <div
                                                            key={restaurant.id}
                                                            className="row mb-1"
                                                        >
                                                            <div className="card mb-3 w-100 ">
                                                                <div className="row no-gutters">
                                                                    <div className="col-4 p-0">
                                                                        <img
                                                                            className="card-img restaurant-img rounded-start "
                                                                            src={
                                                                                restaurant.image_url
                                                                            }
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <div className="card-body ">
                                                                            <a
                                                                                href={
                                                                                    restaurant.url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="btn-link text-decoration-none"
                                                                            >
                                                                                <small className="fw-bold">
                                                                                    {
                                                                                        restaurant.name
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                <small>
                                                                                    <i className="fa-solid fa-star fa-xs"></i>{" "}
                                                                                    {
                                                                                        restaurant.rating
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                {restaurant.categories?.map(
                                                                                    (
                                                                                        category,
                                                                                        index
                                                                                    ) => (
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "small",
                                                                                            }}
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                category.title
                                                                                            }
                                                                                            {index <
                                                                                                (
                                                                                                    restaurant.categories ||
                                                                                                    []
                                                                                                )
                                                                                                    .length -
                                                                                                    1 &&
                                                                                                ", "}
                                                                                        </span>
                                                                                    )
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            {visibleRestaurants <
                                                userInfo.other.aboutRestaurants
                                                    .length && (
                                                <div className="text-center">
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() =>
                                                            setVisibleRestaurants(
                                                                (prev) =>
                                                                    prev + 5
                                                            )
                                                        }
                                                    >
                                                        View More
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutRestaurants?.length ===
                                        0 && <p>No restaurants found.</p>}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const [isExpandedEvents, setIsExpandedEvents] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState<TEvents[]>([]);
    const [currWorkingStateEvents, setCurrWorkingStateEvents] =
        useState<TProcessStates>("done");
    const fetchEventsData = async () => {
        const zip = userInfo.other?.zip || "";
        if (!zip || zip === "") {
            return;
        }

        setCurrWorkingStateEvents("working");
        try {
            const response = await getEvents(zip);
            const events = response.data?._embedded?.events || [];
            const userInfoSelectedEventsMap = userInfo.other?.aboutEvents
                ? userInfo.other.aboutEvents.reduce((prev, curr) => {
                      return {
                          ...prev,
                          [curr.id]: curr,
                      };
                  }, {} as Record<string, any>)
                : {};

            events.forEach((r) => {
                r.isSelected =
                    userInfoSelectedEventsMap[r.id] !== undefined
                        ? true
                        : false;
            });

            setSelectedEvents(events);
        } catch (error) {
            console.error("fetchEventsData - Error fetching data:", error);
        } finally {
            setCurrWorkingStateEvents("idle");
        }
    };

    const Events = () => {
        const [eventsPerPage, setEventsPerPage] = useState(5);
        const totalEvents = userInfo.other.aboutEvents || [];
        const totalItems = Math.ceil(totalEvents.length / eventsPerPage);
        const [visibleEvents, setVisibleEvents] = useState(5);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth <= 991) {
                    setEventsPerPage(3);
                } else {
                    setEventsPerPage(5);
                }
            };
            handleResize();
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        const renderCarouselItems = () => {
            const carouselItems = [];

            for (let i = 0; i < totalItems; i++) {
                const startIdx = i * eventsPerPage;
                const endIdx = startIdx + eventsPerPage;
                const currentEvents = totalEvents.slice(startIdx, endIdx);

                const cardGroup = currentEvents.map((event) => (
                    <div className="col">
                        <div
                            className="card bg-dark text-white rounded border-0 w-auto min-height-cards-restaurants"
                            key={event.id}
                        >
                            <img
                                src={
                                    event.images && event.images.length > 0
                                        ? event.images[9].url
                                        : ""
                                }
                                className="card-img-top rounded-top border border-2 border-dark restaurant-img"
                                alt="event-img"
                            />
                            <div className="card-body">
                                <a
                                    href={event.url}
                                    target="_blank"
                                    className="btn-link text-decoration-none"
                                    rel="noreferrer"
                                >
                                    <p className="card-title text-white">
                                        <strong>{event.name}</strong>
                                    </p>
                                </a>
                                <p>
                                    <small>
                                        {dayjs(event.dates?.start?.localDate)
                                            .utcOffset(0)
                                            .format("MMMM D, YYYY")}
                                    </small>
                                </p>
                                <p>
                                    <small>
                                        {
                                            event.classifications?.[0]?.segment
                                                ?.name
                                        }
                                    </small>
                                </p>
                                <p>
                                    <small className="card-text">
                                        {
                                            event.classifications?.[0]?.genre
                                                ?.name
                                        }
                                    </small>{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                ));

                //hack to make all the card equally width when there is less than what the page allows
                if (cardGroup.length < eventsPerPage) {
                    const DummyEmptyCard = (
                        <div className="card" style={{ border: "none" }}></div>
                    );
                    const remainingCards = eventsPerPage - cardGroup.length;
                    for (let j = 0; j < remainingCards; j++)
                        cardGroup.push(DummyEmptyCard);
                }
                carouselItems.push(
                    <div
                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                        key={i}
                    >
                        <div
                            className={
                                "row g-3 row-cols-1 row-cols-md-" +
                                eventsPerPage
                            }
                        >
                            {cardGroup}
                        </div>
                    </div>
                );
            }

            return carouselItems;
        };

        const openEventsOptions = () => {
            setIsExpandedEvents(!isExpandedEvents);
            fetchEventsData();
            setIsSomePartBeingEdited(true);
        };
        const handleCheckboxChange = (e: any, events: TEvents) => {
            e.stopPropagation();
            setSelectedEvents((prev) => {
                const idx = prev.findIndex((event) => event.id === events.id);
                prev[idx].isSelected = !prev[idx].isSelected;
                return prev;
            });
        };

        const saveEvents = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            setCurrWorkingStateEvents("working");
            const selectedInfo = selectedEvents.filter(
                (event) => event.isSelected
            );
            const newEvents = {
                ...userInfo.other,
                aboutEvents: selectedInfo,
            };

            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...newEvents,
                },
                ""
            );

            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    aboutEvents: selectedInfo,
                },
            });

            setIsExpandedEvents(false);
            setIsSomePartBeingEdited(false);
            setCurrWorkingStateEvents("done");
        };

        const cancelHandler = async () => {
            setIsExpandedEvents(false);
            setIsSomePartBeingEdited(false);
        };
        return (
            <>
                {!editable && userInfo.other.aboutEvents && (
                    <>
                        <div className="container bg-white shadow-sm rounded p-4 mb-3">
                            <div className="row">
                                <h5 className="fw-bold pb-3">
                                    UPCOMING EVENTS IN THE AREA
                                </h5>
                                <div className="d-none d-md-block">
                                    <div className="min-height-cards-restaurants w-100 px-lg-5 px-md-3 px-2 ">
                                        {workingState === "working" && (
                                            <p className="p-3">
                                                Looking for events in this
                                                area...
                                            </p>
                                        )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutEvents &&
                                            userInfo.other.aboutEvents.length >
                                                0 && (
                                                <>
                                                    <div
                                                        id="eventCarousel"
                                                        className="carousel slide"
                                                        data-ride="carousel"
                                                        data-interval="false"
                                                    >
                                                        <div className="carousel-inner">
                                                            {renderCarouselItems()}
                                                        </div>
                                                        {userInfo.other
                                                            .aboutEvents
                                                            .length > 5 && (
                                                            <div className="">
                                                                <a
                                                                    className="carousel-control-prev"
                                                                    href="#eventCarousel"
                                                                    role="button"
                                                                    data-slide="prev"
                                                                >
                                                                    <span
                                                                        className="carousel-control-prev-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Previous
                                                                    </span>
                                                                </a>
                                                                <a
                                                                    className="carousel-control-next"
                                                                    href="#eventCarousel"
                                                                    role="button"
                                                                    data-slide="next"
                                                                >
                                                                    <span
                                                                        className="carousel-control-next-icon text-dark"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Next
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutEvents
                                                ?.length === 0 && (
                                                <p>No events found.</p>
                                            )}
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    {workingState === "working" && (
                                        <p>
                                            Looking for events in this area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutEvents &&
                                        userInfo.other.aboutEvents.length >
                                            0 && (
                                            <>
                                                <div>
                                                    {userInfo.other.aboutEvents
                                                        .slice(0, visibleEvents)
                                                        .map((event) => (
                                                            <div
                                                                key={event.id}
                                                                className="row mb-1"
                                                            >
                                                                <div className="card mb-3 w-100">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-4 p-0">
                                                                            <img
                                                                                className="card-img restaurant-img rounded-start"
                                                                                src={
                                                                                    event.images &&
                                                                                    event
                                                                                        .images
                                                                                        .length >
                                                                                        0
                                                                                        ? event
                                                                                              .images[9]
                                                                                              .url
                                                                                        : ""
                                                                                }
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-8 p-0">
                                                                            <div className="card-body ">
                                                                                <a
                                                                                    href={
                                                                                        event.url
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    className="btn-link text-decoration-none"
                                                                                >
                                                                                    <small className="fw-bold">
                                                                                        {
                                                                                            event.name
                                                                                        }
                                                                                    </small>
                                                                                    <br />
                                                                                    <small>
                                                                                        {dayjs(
                                                                                            event
                                                                                                .dates
                                                                                                ?.start
                                                                                                ?.localDate
                                                                                        )
                                                                                            .utcOffset(
                                                                                                0
                                                                                            )
                                                                                            .format(
                                                                                                "MMMM D, YYYY"
                                                                                            )}
                                                                                    </small>
                                                                                    <br />
                                                                                    <small>
                                                                                        {
                                                                                            event
                                                                                                .classifications?.[0]
                                                                                                ?.segment
                                                                                                ?.name
                                                                                        }
                                                                                    </small>
                                                                                    <br />
                                                                                    <small>
                                                                                        {
                                                                                            event
                                                                                                .classifications?.[0]
                                                                                                ?.genre
                                                                                                ?.name
                                                                                        }
                                                                                    </small>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {visibleEvents <
                                                    userInfo.other.aboutEvents
                                                        .length && (
                                                    <div className="text-center">
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                                setVisibleEvents(
                                                                    (prev) =>
                                                                        prev + 5
                                                                )
                                                            }
                                                        >
                                                            View More
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutEvents?.length ===
                                            0 && <p>No events found.</p>}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {editable && (
                    <div className="container bg-white shadow-sm rounded p-4 mb-3">
                        <div className="row">
                            <div className="d-flex justify-content-between p-2">
                                <h5 className="fw-bold">
                                    Upcoming Events in the Area
                                </h5>
                                {!isSomePartBeingEdited && (
                                    <div
                                        className="fileTooltip rightEdit"
                                        data-tooltip=" Edit "
                                        role="button"
                                        onClick={openEventsOptions}
                                        aria-expanded="false"
                                        aria-controls="collapseRestaurant"
                                    >
                                        <i
                                            data-testid="edit-icon"
                                            className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                                        ></i>
                                    </div>
                                )}
                            </div>
                            {isExpandedEvents && editable && (
                                <div>
                                    <div className="card card-body mb-3">
                                        <p>
                                            <strong>
                                                Select the events you would like
                                                to display.
                                            </strong>
                                        </p>
                                        <div>
                                            {currWorkingStateEvents ===
                                                "working" && (
                                                <>
                                                    <Spinner />
                                                </>
                                            )}
                                            {currWorkingStateEvents ===
                                                "idle" &&
                                                selectedEvents.length > 0 && (
                                                    <div className="row">
                                                        {selectedEvents.map(
                                                            (event) => (
                                                                <div
                                                                    className="col-md-6 col-lg-4 mb-3"
                                                                    key={
                                                                        event.id
                                                                    }
                                                                >
                                                                    <div className="input-group h-100">
                                                                        <label
                                                                            className="w-100"
                                                                            htmlFor={`rChk_${event.id}`}
                                                                        >
                                                                            <div className="row w-100 d-flex align-items-center input-group-text h-100 p-2">
                                                                                <div className="col-2 p-0">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        defaultChecked={
                                                                                            event.isSelected
                                                                                        }
                                                                                        id={`rChk_${event.id}`}
                                                                                        className="form-check-input"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleCheckboxChange(
                                                                                                e,
                                                                                                event
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="col-4 p-0">
                                                                                    <img
                                                                                        src={
                                                                                            event.images &&
                                                                                            event
                                                                                                .images
                                                                                                .length >
                                                                                                0
                                                                                                ? event
                                                                                                      .images[0]
                                                                                                      .url
                                                                                                : ""
                                                                                        }
                                                                                        className="rounded label-img"
                                                                                        alt="events"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 text-wrap">
                                                                                    <p className="mb-1">
                                                                                        {" "}
                                                                                        <small>
                                                                                            <strong>
                                                                                                {
                                                                                                    event.name
                                                                                                }
                                                                                            </strong>
                                                                                        </small>
                                                                                    </p>
                                                                                    <p className="mb-0">
                                                                                        <small>
                                                                                            {dayjs(
                                                                                                event
                                                                                                    .dates
                                                                                                    ?.start
                                                                                                    ?.localDate
                                                                                            )
                                                                                                .utcOffset(
                                                                                                    0
                                                                                                )
                                                                                                .format(
                                                                                                    "MM/DD/YYYY"
                                                                                                )}
                                                                                        </small>
                                                                                    </p>
                                                                                    <p>
                                                                                        <small>
                                                                                            {
                                                                                                event
                                                                                                    .classifications?.[0]
                                                                                                    ?.segment
                                                                                                    ?.name
                                                                                            }
                                                                                        </small>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {currWorkingStateEvents ===
                                                "idle" &&
                                                selectedEvents.length === 0 && (
                                                    <p>
                                                        No events found in this
                                                        area.
                                                    </p>
                                                )}
                                        </div>
                                        <div className="d-grid gap-3 d-md-block">
                                            <button
                                                className="btn btn-primary"
                                                onClick={saveEvents}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-danger ms-md-3"
                                                onClick={cancelHandler}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="d-none d-md-block">
                                <div className="min-height-cards-restaurants w-100 px-lg-5 px-md-3 px-2 ">
                                    {workingState === "working" && (
                                        <p className="p-3">
                                            Looking for events in this area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutEvents &&
                                        userInfo.other.aboutEvents.length >
                                            0 && (
                                            <>
                                                <div
                                                    id="eventCarousel"
                                                    className="carousel slide"
                                                    data-ride="carousel"
                                                    data-interval="false"
                                                >
                                                    <div className="carousel-inner">
                                                        {renderCarouselItems()}
                                                    </div>
                                                    {userInfo.other.aboutEvents
                                                        .length > 5 && (
                                                        <div className="">
                                                            <a
                                                                className="carousel-control-prev"
                                                                href="#eventCarousel"
                                                                role="button"
                                                                data-slide="prev"
                                                            >
                                                                <span
                                                                    className="carousel-control-prev-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Previous
                                                                </span>
                                                            </a>
                                                            <a
                                                                className="carousel-control-next"
                                                                href="#eventCarousel"
                                                                role="button"
                                                                data-slide="next"
                                                            >
                                                                <span
                                                                    className="carousel-control-next-icon"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="visually-hidden">
                                                                    Next
                                                                </span>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutEvents?.length ===
                                            0 && <p>No events found.</p>}
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                {workingState === "working" && (
                                    <p>Looking for events in this area...</p>
                                )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutEvents &&
                                    userInfo.other.aboutEvents.length > 0 && (
                                        <>
                                            <div>
                                                {userInfo.other.aboutEvents
                                                    .slice(0, visibleEvents)
                                                    .map((event) => (
                                                        <div
                                                            key={event.id}
                                                            className="row mb-1"
                                                        >
                                                            <div className="card mb-3 w-100">
                                                                <div className="row no-gutters">
                                                                    <div className="col-4 p-0">
                                                                        <img
                                                                            className="card-img restaurant-img rounded-start"
                                                                            src={
                                                                                event.images &&
                                                                                event
                                                                                    .images
                                                                                    .length >
                                                                                    0
                                                                                    ? event
                                                                                          .images[9]
                                                                                          .url
                                                                                    : ""
                                                                            }
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <div className="card-body ">
                                                                            <a
                                                                                href={
                                                                                    event.url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="btn-link text-decoration-none"
                                                                            >
                                                                                <small className="fw-bold">
                                                                                    {
                                                                                        event.name
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                <small>
                                                                                    {dayjs(
                                                                                        event
                                                                                            .dates
                                                                                            ?.start
                                                                                            ?.localDate
                                                                                    )
                                                                                        .utcOffset(
                                                                                            0
                                                                                        )
                                                                                        .format(
                                                                                            "MMMM D, YYYY"
                                                                                        )}
                                                                                </small>
                                                                                <br />
                                                                                <small>
                                                                                    {
                                                                                        event
                                                                                            .classifications?.[0]
                                                                                            ?.segment
                                                                                            ?.name
                                                                                    }
                                                                                </small>
                                                                                <br />
                                                                                <small>
                                                                                    {
                                                                                        event
                                                                                            .classifications?.[0]
                                                                                            ?.genre
                                                                                            ?.name
                                                                                    }
                                                                                </small>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            {visibleEvents <
                                                userInfo.other.aboutEvents
                                                    .length && (
                                                <div className="text-center">
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() =>
                                                            setVisibleEvents(
                                                                (prev) =>
                                                                    prev + 5
                                                            )
                                                        }
                                                    >
                                                        View More
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                {["idle", "done"].includes(workingState) &&
                                    userInfo.other.aboutEvents?.length ===
                                        0 && <p>No events found.</p>}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const [isExpandedLandmarks, setIsExpandedLandmarks] = useState(false);
    const [currWorkingStateLandmarks, setCurrWorkingStateLandmarks] =
        useState<TProcessStates>("done");
    const [selectedLandmarks, setSelectedLandmarks] = useState<TLandmarks[]>(
        []
    );

    const fetchLandmarksData = async () => {
        const zip = userInfo.other?.zip || "";
        if (!zip || zip === "") {
            return;
        }
        setCurrWorkingStateLandmarks("working");
        try {
            const response = await getLandmarks(zip);
            const landmarks = response.data?.businesses || [];
            const userInfoSelectedLandmarksMap = userInfo.other?.aboutLandmarks
                ? userInfo.other.aboutLandmarks.reduce((prev, curr) => {
                      return {
                          ...prev,
                          [curr.id]: curr,
                      };
                  }, {} as Record<string, any>)
                : {};
            //we set the selected landmarks
            landmarks.forEach((r) => {
                r.isSelected =
                    userInfoSelectedLandmarksMap[r.id] !== undefined
                        ? true
                        : false;
            });
            setSelectedLandmarks(landmarks);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setCurrWorkingStateLandmarks("idle");
        }
    };

    const Landmarks = () => {
        const [landmarksPerPage, setlandmarksPerPage] = useState(5);
        const totalLandmarks = userInfo.other.aboutLandmarks || [];
        const totalItems = Math.ceil(totalLandmarks.length / landmarksPerPage);
        const [visibleLandmarks, setVisibleLandmarks] = useState(5);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth <= 991) {
                    setlandmarksPerPage(3);
                } else {
                    setlandmarksPerPage(5);
                }
            };
            handleResize();
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        const renderCarouselItems = () => {
            const carouselItems = [];

            for (let i = 0; i < totalItems; i++) {
                const startIdx = i * landmarksPerPage;
                const endIdx = startIdx + landmarksPerPage;
                const currentLandmarks = totalLandmarks.slice(startIdx, endIdx);

                const cardGroup = currentLandmarks.map((landmark) => (
                    <div className="col">
                        <div
                            className="card bg-dark text-white rounded border-0 min-height-cards-restaurants w-auto"
                            key={landmark.id}
                        >
                            <img
                                src={landmark.image_url}
                                className="card-img-top rounded-top border border-2 border-dark restaurant-img"
                                alt="landmark"
                            />
                            <div className="card-body">
                                <a
                                    href={landmark.url}
                                    target="_blank"
                                    className="btn-link text-decoration-none"
                                    rel="noreferrer"
                                >
                                    <p className="card-title text-white">
                                        <strong>{landmark.name}</strong>
                                    </p>
                                </a>
                                <p>
                                    <i className="fa-solid fa-star fa-xs"></i>{" "}
                                    {landmark.rating}
                                </p>
                                <small className="card-text">
                                    {landmark.location?.address1}
                                </small>
                            </div>
                        </div>
                    </div>
                ));

                //hack to make all the card equally width when there is less than what the page allows
                if (cardGroup.length < landmarksPerPage) {
                    const DummyEmptyCard = (
                        <div className="card" style={{ border: "none" }}></div>
                    );
                    const remainingCards = landmarksPerPage - cardGroup.length;
                    for (let j = 0; j < remainingCards; j++)
                        cardGroup.push(DummyEmptyCard);
                }
                carouselItems.push(
                    <div
                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                        key={i}
                    >
                        <div
                            className={
                                "row g-3 row-cols-1 row-cols-md-" +
                                landmarksPerPage
                            }
                        >
                            {cardGroup}
                        </div>
                    </div>
                );
            }

            return carouselItems;
        };

        const openLandmarkOptions = () => {
            setIsExpandedLandmarks(!isExpandedLandmarks);
            fetchLandmarksData();
            setIsSomePartBeingEdited(true);
        };
        const handleCheckboxChange = (e: any, landmark: TLandmarks) => {
            e.stopPropagation();
            setSelectedLandmarks((prev) => {
                const idx = prev.findIndex((r) => r.id === landmark.id);
                prev[idx].isSelected = !prev[idx].isSelected;
                return prev;
            });
        };

        const saveLandmarks = async (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            setCurrWorkingStateLandmarks("working");
            const selectedInfo = selectedLandmarks.filter((r) => r.isSelected);
            const newLandmarks = {
                ...userInfo.other,
                aboutLandmarks: selectedInfo,
            };

            await updateLabAndLocalStorage(
                {
                    password: "",
                    ...newLandmarks,
                },
                ""
            );

            setUserInfo({
                ...userInfo,
                other: {
                    ...userInfo.other,
                    aboutLandmarks: selectedInfo,
                },
            });

            setIsExpandedLandmarks(false);
            setIsSomePartBeingEdited(false);
            setCurrWorkingStateLandmarks("done");
        };

        const cancelHandler = async () => {
            setIsExpandedLandmarks(false);
            setIsSomePartBeingEdited(false);
        };
        return (
            <>
                {!editable && userInfo.other.aboutLandmarks && (
                    <>
                        <div className="container bg-white rounded shadow-sm p-4  mb-3">
                            <div className="row">
                                <h5 className="fw-bold pb-3">
                                    FAVORITE LANDMARKS
                                </h5>
                                <div className="d-none d-md-block">
                                    <div className="w-100 px-lg-5 px-md-3 px-2 min-height-cards-restaurants">
                                        {workingState === "working" && (
                                            <p className="p-3">
                                                {" "}
                                                Looking for landmarks in this
                                                area...
                                            </p>
                                        )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutLandmarks &&
                                            userInfo.other.aboutLandmarks
                                                .length > 0 && (
                                                <>
                                                    <div
                                                        id="landCarousel"
                                                        className="carousel slide"
                                                        data-ride="carousel"
                                                        data-interval="false"
                                                    >
                                                        <div className="carousel-inner">
                                                            {renderCarouselItems()}
                                                        </div>
                                                        {userInfo.other
                                                            .aboutLandmarks
                                                            .length > 5 && (
                                                            <div className="">
                                                                <a
                                                                    className="carousel-control-prev"
                                                                    href="#landCarousel"
                                                                    role="button"
                                                                    data-slide="prev"
                                                                >
                                                                    <span
                                                                        className="carousel-control-prev-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Previous
                                                                    </span>
                                                                </a>
                                                                <a
                                                                    className="carousel-control-next"
                                                                    href="#landCarousel"
                                                                    role="button"
                                                                    data-slide="next"
                                                                >
                                                                    <span
                                                                        className="carousel-control-next-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Next
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutLandmarks
                                                ?.length === 0 && (
                                                <p> No landmarks found.</p>
                                            )}
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    {workingState === "working" && (
                                        <p>
                                            {" "}
                                            Looking for landmarks in this
                                            area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutLandmarks &&
                                        userInfo.other.aboutLandmarks.length >
                                            0 && (
                                            <>
                                                <div>
                                                    {userInfo.other.aboutLandmarks
                                                        .slice(
                                                            0,
                                                            visibleLandmarks
                                                        )
                                                        .map((landmark) => (
                                                            <div
                                                                key={
                                                                    landmark.id
                                                                }
                                                                className="row mb-1"
                                                            >
                                                                <div className="card mb-3 w-100">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-4 p-0">
                                                                            <img
                                                                                className="card-img restaurant-img"
                                                                                src={
                                                                                    landmark.image_url
                                                                                }
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-8 p-0">
                                                                            <div className="card-body ">
                                                                                <a
                                                                                    href={
                                                                                        landmark.url
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <strong>
                                                                                        {
                                                                                            landmark.name
                                                                                        }
                                                                                    </strong>
                                                                                    <br />
                                                                                    <small>
                                                                                        <i className="fa-solid fa-star fa-xs"></i>{" "}
                                                                                        {
                                                                                            landmark.rating
                                                                                        }
                                                                                    </small>
                                                                                    <br />
                                                                                    <small>
                                                                                        {
                                                                                            landmark
                                                                                                .location
                                                                                                ?.address1
                                                                                        }
                                                                                    </small>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {visibleLandmarks <
                                                    userInfo.other
                                                        .aboutLandmarks
                                                        .length && (
                                                    <div className="text-center">
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                                setVisibleLandmarks(
                                                                    (prev) =>
                                                                        prev + 5
                                                                )
                                                            }
                                                        >
                                                            View More
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutLandmarks
                                            ?.length === 0 && (
                                            <p> No landmarks found.</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {editable && (
                    <>
                        <div className="container bg-white rounded shadow-sm p-4 mb-3">
                            <div className="row">
                                <div className="d-flex justify-content-between p-2">
                                    <h5 className="fw-bold">
                                        Favorite Landmarks
                                    </h5>
                                    {!isSomePartBeingEdited && (
                                        <div
                                            className="fileTooltip rightEdit"
                                            data-tooltip=" Edit "
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseRestaurant"
                                            onClick={openLandmarkOptions}
                                        >
                                            <i
                                                data-testid="edit-icon"
                                                className="fa-solid fa-pen-to-square fa-xs p-2 text-center shadow editIcon"
                                            ></i>
                                        </div>
                                    )}
                                </div>
                                {isExpandedLandmarks && editable && (
                                    <div>
                                        <div className="card card-body mb-3">
                                            <p>
                                                Select the landmarks you would
                                                like to display.
                                            </p>
                                            <div>
                                                {currWorkingStateLandmarks ===
                                                    "working" && (
                                                    <>
                                                        <Spinner />
                                                    </>
                                                )}
                                                {currWorkingStateLandmarks ===
                                                    "idle" &&
                                                    selectedLandmarks.length >
                                                        0 && (
                                                        <div className="row">
                                                            {selectedLandmarks.map(
                                                                (landmark) => (
                                                                    <div
                                                                        className="col-md-6 col-lg-4 mb-3"
                                                                        key={
                                                                            landmark.id
                                                                        }
                                                                    >
                                                                        <div className="input-group h-100">
                                                                            <label
                                                                                className="w-100"
                                                                                htmlFor={`rChk_${landmark.id}`}
                                                                            >
                                                                                <div className="row align-items-center input-group-text w-100 h-100 p-2">
                                                                                    <div className="col-2 p-0">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            defaultChecked={
                                                                                                landmark.isSelected
                                                                                            }
                                                                                            id={`rChk_${landmark.id}`}
                                                                                            className="form-check-input"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleCheckboxChange(
                                                                                                    e,
                                                                                                    landmark
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-4 p-0">
                                                                                        <img
                                                                                            src={
                                                                                                landmark.image_url
                                                                                            }
                                                                                            className="rounded label-img"
                                                                                            alt="landmark"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-6 text-wrap">
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "smaller",
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                {
                                                                                                    landmark.name
                                                                                                }
                                                                                            </strong>
                                                                                            <br />
                                                                                            <small>
                                                                                                <i className="fa-solid fa-star fa-xs"></i>{" "}
                                                                                                {
                                                                                                    landmark.rating
                                                                                                }
                                                                                            </small>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                {currWorkingStateLandmarks ===
                                                    "idle" &&
                                                    selectedLandmarks.length ===
                                                        0 && (
                                                        <p>
                                                            No landmarks found
                                                            in this area.
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="d-grid gap-3 d-md-block">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={saveLandmarks}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="btn btn-danger ms-md-3"
                                                    onClick={cancelHandler}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="d-none d-md-block">
                                    <div className="w-100 px-lg-5 px-md-3 px-2 min-height-cards-restaurants">
                                        {workingState === "working" && (
                                            <p className="p-3">
                                                {" "}
                                                Looking for landmarks in this
                                                area...
                                            </p>
                                        )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutLandmarks &&
                                            userInfo.other.aboutLandmarks
                                                .length > 0 && (
                                                <>
                                                    <div
                                                        id="landCarousel"
                                                        className="carousel slide"
                                                        data-ride="carousel"
                                                        data-interval="false"
                                                    >
                                                        <div className="carousel-inner">
                                                            {renderCarouselItems()}
                                                        </div>
                                                        {userInfo.other
                                                            .aboutLandmarks
                                                            .length > 5 && (
                                                            <div className="">
                                                                <a
                                                                    className="carousel-control-prev"
                                                                    href="#landCarousel"
                                                                    role="button"
                                                                    data-slide="prev"
                                                                >
                                                                    <span
                                                                        className="carousel-control-prev-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Previous
                                                                    </span>
                                                                </a>
                                                                <a
                                                                    className="carousel-control-next"
                                                                    href="#landCarousel"
                                                                    role="button"
                                                                    data-slide="next"
                                                                >
                                                                    <span
                                                                        className="carousel-control-next-icon"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Next
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        {["idle", "done"].includes(
                                            workingState
                                        ) &&
                                            userInfo.other.aboutLandmarks
                                                ?.length === 0 && (
                                                <p> No landmarks found.</p>
                                            )}
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    {workingState === "working" && (
                                        <p>
                                            {" "}
                                            Looking for landmarks in this
                                            area...
                                        </p>
                                    )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutLandmarks &&
                                        userInfo.other.aboutLandmarks.length >
                                            0 && (
                                            <>
                                                <div>
                                                    {userInfo.other.aboutLandmarks
                                                        .slice(
                                                            0,
                                                            visibleLandmarks
                                                        )
                                                        .map((landmark) => (
                                                            <div
                                                                key={
                                                                    landmark.id
                                                                }
                                                                className="row mb-1"
                                                            >
                                                                <div className="card mb-3 w-100">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-4 p-0">
                                                                            <img
                                                                                className="card-img restaurant-img"
                                                                                src={
                                                                                    landmark.image_url
                                                                                }
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <div className="col-8 p-0">
                                                                            <div className="card-body ">
                                                                                <a
                                                                                    href={
                                                                                        landmark.url
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <strong>
                                                                                        {
                                                                                            landmark.name
                                                                                        }
                                                                                    </strong>
                                                                                    <br />
                                                                                    <small>
                                                                                        <i className="fa-solid fa-star fa-xs"></i>{" "}
                                                                                        {
                                                                                            landmark.rating
                                                                                        }
                                                                                    </small>
                                                                                    <br />
                                                                                    <small>
                                                                                        {
                                                                                            landmark
                                                                                                .location
                                                                                                ?.address1
                                                                                        }
                                                                                    </small>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {visibleLandmarks <
                                                    userInfo.other
                                                        .aboutLandmarks
                                                        .length && (
                                                    <div className="text-center">
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                                setVisibleLandmarks(
                                                                    (prev) =>
                                                                        prev + 5
                                                                )
                                                            }
                                                        >
                                                            View More
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    {["idle", "done"].includes(workingState) &&
                                        userInfo.other.aboutLandmarks
                                            ?.length === 0 && (
                                            <p> No landmarks found.</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {!userExist && <UserNotExist />}
            {userExist && (
                <>
                    {/* Background Profile*/}
                    <div
                        className={
                            "labprofilecontentblock container-fluid " +
                            (workingState === "done" ? "" : "cssLoading")
                        }
                        ref={refProfileBackround}
                        style={{
                            [backgroundImg
                                ? "backgroundImage"
                                : ""]: `url('${backgroundImg}')`,
                        }}
                    >
                        <div className="container">
                            {/*Row for Edit Bg button, backbutton and invitation notification */}
                            <div className="row pt-3">
                                {editable && !isSomePartBeingEdited && (
                                    <FileUploaderComponent
                                        relatedComponent={refProfileBackround}
                                        tooltipPosition="right"
                                        fileToolTipText="Click here to edit your background photo, or drag and drop it onto this area. Images should be in .JPG, .JPEG or .PNG formats. Your background image should be 1220x320 pixels."
                                    />
                                )}
                                {!editable && (
                                    <BackButton
                                        btnText={
                                            currentUserInfo?.type === "admin"
                                                ? " Return to list"
                                                : " Return to labs list"
                                        }
                                    />
                                )}
                                {!document.querySelector(".cssLoading") &&
                                    (inappropriateContent ||
                                        unsupportedImageType) && (
                                        <ErrorMessageSticky
                                            message={
                                                inappropriateContent
                                                    ? "Image not accepted. It may contain inappropriate content. Please try again with a suitable image."
                                                    : unsupportedImageType
                                                    ? "Image format not accepted. Please use JPEG, JPG, or PNG formats."
                                                    : ""
                                            }
                                        />
                                    )}
                                <InvitationButtons />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row gap-3 justify-content-center lab-row">
                                <div className="col-lg-5 bg-light rounded p-4">
                                    <div className="row">
                                        <div className="col-md-4 my-2 p-0 d-flex justify-content-center">
                                            <div
                                                ref={refLogoPhoto}
                                                className={
                                                    "rounded-circle border border-2 border-light profile-logo " +
                                                    (workingState === "done"
                                                        ? ""
                                                        : "cssLoading")
                                                }
                                                style={{
                                                    [logoImg
                                                        ? "backgroundImage"
                                                        : ""]: `url('${logoImg}')`,
                                                }}
                                            >
                                                {editable &&
                                                    !isSomePartBeingEdited && (
                                                        <FileUploaderComponent
                                                            relatedComponent={
                                                                refLogoPhoto
                                                            }
                                                            tooltipPosition="left"
                                                            fileToolTipText="Click here to edit your logo, or drag and drop it onto this area. Images should be in .JPG, .JPEG or .PNG formats. Your profile image should be minimum 500x500 pixels. "
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                        <div className="col-md-8 my-2 d-block text-center">
                                            <NameSection />
                                            {/* Message Button*/}
                                            {!editable && (
                                                <>
                                                    {userInfo.inmycontactlist && (
                                                        <MessageBtn
                                                            userInfo={userInfo}
                                                            className="btn-outline-secondary w-100 rounded-3 my-2"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <AboutTheLabSection />
                                    </div>
                                    <hr className="mt-4" />
                                    <div className="pt-3 pb-5">
                                        <PositionsSection />
                                    </div>
                                    {/* Invite Button*/}
                                    {!editable && (
                                        <div className="w-100 py-4">
                                            <>
                                                {currentUserInfo?.type !==
                                                    "admin" &&
                                                    !userInfo.inmycontactlist &&
                                                    !userInfo.invitationsenttome &&
                                                    userInfo.invitationsentstate !==
                                                        "accepted" && (
                                                        <InviteButton
                                                            userToInviteId={
                                                                userInfo.id
                                                            }
                                                            userToInviteInvitationState={
                                                                userInfo.invitationsentstate
                                                            }
                                                            userType="lab"
                                                        />
                                                    )}
                                            </>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 bg-light  rounded">
                                    <div className="row p-3 mt-3 rounded border border-1 border-light">
                                        <PhilosophySection />
                                    </div>
                                    <div className="row mb-5 px-3 ">
                                        <div
                                            className={
                                                "col-md-6" +
                                                (workingState === "done"
                                                    ? ""
                                                    : "cssLoading")
                                            }
                                        >
                                            <div className="d-flex justify-content-between p-3">
                                                <h5>LAB EXTERIOR</h5>
                                                {editable &&
                                                    !isSomePartBeingEdited && (
                                                        <FileUploaderComponent
                                                            relatedComponent={
                                                                refProfileExternalPhoto
                                                            }
                                                            tooltipPosition="downRight"
                                                            fileToolTipText="Click here to edit lab exterior photo, or drag and drop it onto this area.  Images should be in .JPG, .JPEG or .PNG formats. Your image should be minimum 500x500 pixels. "
                                                        />
                                                    )}
                                            </div>
                                            {editable && (
                                                <div
                                                    className={
                                                        !!externalPhoto
                                                            ? "rounded"
                                                            : "rounded dashed-border border-primary lab-external-photo p-4 d-flex justify-content-center flex-column align-items-center "
                                                    }
                                                    style={{
                                                        ...croppedImage,
                                                        [externalPhoto
                                                            ? "backgroundImage"
                                                            : ""]: `url(${externalPhoto})`,
                                                        [externalPhoto
                                                            ? "backgroundSize"
                                                            : ""]: "cover",
                                                    }}
                                                    ref={
                                                        refProfileExternalPhoto
                                                    }
                                                >
                                                    {!!!externalPhoto && (
                                                        <>
                                                            <i className="fa-solid fa-image mb-2 icon-photos"></i>
                                                            <p className="d-none d-lg-block d-xl-block text-center">
                                                                Drag and drop a
                                                                photo of your
                                                                lab's exterior
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {!editable && (
                                                <>
                                                    {!!!externalPhoto && (
                                                        <div className="border rounded lab-external-photo p-4 d-flex justify-content-center flex-column align-items-center">
                                                            <i className="fa-solid fa-image mb-2 icon-no-photos"></i>
                                                            <p
                                                                className="d-none d-lg-block d-xl-block text-center"
                                                                style={{
                                                                    color: "#a5a5a5",
                                                                }}
                                                            >
                                                                No photo to show
                                                            </p>
                                                        </div>
                                                    )}
                                                    {!!externalPhoto && (
                                                        <div
                                                            className="rounded"
                                                            style={{
                                                                ...croppedImage,
                                                                backgroundImage: `url(${externalPhoto})`,
                                                                backgroundSize:
                                                                    "cover",
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "col-md-6" +
                                                (workingState === "done"
                                                    ? ""
                                                    : "cssLoading")
                                            }
                                        >
                                            <div className="d-flex justify-content-between p-3">
                                                <h5>LAB INTERIOR</h5>
                                                {editable &&
                                                    !isSomePartBeingEdited && (
                                                        <FileUploaderComponent
                                                            relatedComponent={
                                                                refProfileInternalPhoto
                                                            }
                                                            tooltipPosition="downRight"
                                                            fileToolTipText="Click here to edit lab interior photo, or drag and drop it onto this area. Images should be in .JPG, .JPEG or .PNG formats. Your image should  be minimum 500x500 pixels.  "
                                                        />
                                                    )}
                                            </div>
                                            {editable && (
                                                <div
                                                    className={
                                                        internalPhoto
                                                            ? "rounded"
                                                            : "rounded dashed-border border-primary lab-external-photo p-4 d-flex justify-content-center flex-column align-items-center"
                                                    }
                                                    style={{
                                                        ...croppedImage,
                                                        [internalPhoto
                                                            ? "backgroundImage"
                                                            : ""]: `url(${internalPhoto})`,
                                                        [internalPhoto
                                                            ? "backgroundSize"
                                                            : ""]: "cover",
                                                    }}
                                                    ref={
                                                        refProfileInternalPhoto
                                                    }
                                                >
                                                    {!!!internalPhoto && (
                                                        <>
                                                            <i className="fa-solid fa-image mb-2 icon-photos"></i>
                                                            <p className="d-none d-lg-block d-xl-block text-center">
                                                                Drag and drop a
                                                                photo of your
                                                                lab's interior
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                            {!editable && (
                                                <>
                                                    {!!!internalPhoto && (
                                                        <div className="border rounded lab-external-photo p-4 d-flex justify-content-center flex-column align-items-center">
                                                            <i className="fa-solid fa-image mb-2 icon-no-photos"></i>
                                                            <p
                                                                className="d-none d-lg-block d-xl-block text-center"
                                                                style={{
                                                                    color: "#a5a5a5",
                                                                }}
                                                            >
                                                                No photo to show
                                                            </p>
                                                        </div>
                                                    )}
                                                    {!!internalPhoto && (
                                                        <div
                                                            className="rounded"
                                                            style={{
                                                                ...croppedImage,
                                                                backgroundImage: `url(${internalPhoto})`,
                                                                backgroundSize:
                                                                    "cover",
                                                            }}
                                                        ></div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* About the Area */}
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row justify-content-center lab-row mt-5">
                                <div className="col-lg-11 bg-light rounded p-4">
                                    <div className="row">
                                        <div className="col-lg-7 p-2 p-md-4">
                                            <AboutOurArea />
                                        </div>
                                        <div className="col-lg-5 p-2 p-md-4 mb-3 mb-md-0">
                                            <Video />
                                        </div>
                                    </div>
                                    <GallerySection
                                        setIsSomePartBeingEdited={
                                            setIsSomePartBeingEdited
                                        }
                                        userId={
                                            userId ||
                                            (currentUserInfo?.id as string)
                                        }
                                        setInappropriateContent={
                                            setInappropriateContent
                                        }
                                        setUnsupportedImageType={
                                            setUnsupportedImageType
                                        }
                                        editable={editable}
                                        isSomePartBeingEdited={
                                            isSomePartBeingEdited
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Lifestyle */}
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row justify-content-center lab-row mt-5">
                                <div className="col-lg-11 bg-light rounded py-4 px-2 px-md-3">
                                    <h4 className="py-4 px-3 text-start fw-bold">
                                        LIFESTYLE
                                    </h4>
                                    <div className="row gap-3">
                                        <div className="">
                                            <Restaurants />
                                            <Events />
                                            <Landmarks />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!editable && (
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-end lab-row mt-5">
                                    <div className="w-auto py-3 px-md-5 text-center text-md-end">
                                        <BlacklistButton
                                            userId={userId || ""}
                                            isInBlacklist={
                                                userInfo.is_in_blacklist
                                            }
                                        />
                                        <ReportButton userId={userId || ""} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export const DentalLabInfo = ({
    editable = false,
}: Pick<TDentalLabInfoProps, "editable">) => {
    const { userId } = useParams();
    const { getLab, state } = useLabs();
    const [labInfo, setLabInfo] = useState<TApiLab[]>([]);

    useEffect(() => {
        if (userId) {
            getLab(userId).then((info) => {
                setLabInfo(info);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <>
            <NotificationProvider>
                <Header title="" />
                {state === "working" && <Spinner />}
                {state !== "working" && (
                    <>
                        <LabInfo
                            editable={editable}
                            labExist={
                                userId === undefined ? true : labInfo.length > 0
                            }
                            labInfo={labInfo[0]}
                        />
                    </>
                )}
                <Footer />
            </NotificationProvider>
        </>
    );
};
