import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
import { MESSAGES_MAP } from "./i18n";
import { LabSignUpFormData, TApiLab } from "../lib/types/labs";
import { useAuthContext } from "../contexts/auth-context";
import {
    apiRequestSecureGet,
    apiRequestSecuredDelete,
    apiRequestSecuredPatch,
    apiRequestSecuredPost,
} from "../lib/common/api";
import { TUserInfo } from "../lib/types/users";
import { RouteEnumType } from "../lib/types/routes";
import { useRouteNavigation } from "../lib/common/routes";
import { MouseEvent } from "react";
import {
    DentalTechSignUpFormData,
    TApiTechnician,
} from "../lib/types/dentalTechs";
import { useServiceContext } from "../contexts/service";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyDTJBqOrXYSRxxGBuj2aWPBLqYS3GDM0Oc",
    authDomain: "winwingo-firebase.firebaseapp.com",
    projectId: "winwingo-firebase",
    storageBucket: "winwingo-firebase.appspot.com",
    messagingSenderId: "606419034082",
    appId: "1:606419034082:web:814ec5acd5bf36a23723bb",
};

const app = initializeApp(firebaseConfig);

export const getFirebaseAppAuth = () => {
    return getAuth(initializeApp(firebaseConfig));
};

export type LabUserResponse = {
    uid: string;
    error?: string;
};

export const useUserService = () => {
    const {
        setIsLoggedIn,
        getUserTokenId,
        setUserTokenId,
        isLoggedIn,
        userInfo,
        logInUser,
    } = useAuthContext();
    const {
        general: { logOut },
    } = useServiceContext();

    /*onAuthStateChanged(appAuth, (user) => {
    if (!user) {
      logOutUser();
      return;
    }
    user
      .getIdToken()
      .then((tt) => {
        logInUser(tt, undefined as any);
        console.log("el id token!!!! ", tt);
      })
      .catch((eee) => {
      });
  });*/

    const { navigateToRoute, navigateRouteClickHandler, getRoute } =
        useRouteNavigation();

    let currentUserTokenId = "";

    const signUpUser = async (
        email: string,
        pwd: string
    ): Promise<LabUserResponse> => {
        // Initialize Firebase

        // Initialize Firebase Authentication and get a reference to the service
        const auth = getAuth(app);
        let response: LabUserResponse = {
            uid: "",
        };
        try {
            const resp = await createUserWithEmailAndPassword(auth, email, pwd);
            setIsLoggedIn(true);
            currentUserTokenId = await resp.user.getIdToken();
            setUserTokenId(currentUserTokenId);
            response.uid = resp.user.uid;
        } catch (error: any) {
            response.error = MESSAGES_MAP[error.code];
        }
        return response;
    };

    const configureNewLab = async (data: LabSignUpFormData) => {
        console.log(
            "calling configure new lab: ",
            currentUserTokenId || (await getUserTokenId())
        );
        await apiRequestSecuredPost(
            "users/add",
            currentUserTokenId || (await getUserTokenId()),
            {
                user: {
                    name: data.labName,
                    uid: data.uid,
                    type: "lab",
                    other: data,
                },
            }
        );
        const userData = await getUserInfo();
        logInUser(currentUserTokenId || (await getUserTokenId()), userData);
    };

    const configureNewTech = async (data: DentalTechSignUpFormData) => {
        await apiRequestSecuredPost(
            "users/add",
            currentUserTokenId || (await getUserTokenId()),
            {
                user: {
                    name: data.firstName,
                    uid: data.uid,
                    type: "tech",
                    other: data,
                },
            }
        );
        const userData = await getUserInfo();
        logInUser(currentUserTokenId || (await getUserTokenId()), userData);
    };

    const updateLab = async (data: LabSignUpFormData, name?: string) => {
        await apiRequestSecuredPatch(
            "users/add",
            currentUserTokenId || (await getUserTokenId()),
            {
                name,
                data,
            }
        );
    };

    const updateTech = async (
        data: DentalTechSignUpFormData,
        name?: string
    ) => {
        await apiRequestSecuredPatch(
            "users/add",
            currentUserTokenId || (await getUserTokenId()),
            {
                name,
                data,
            }
        );
    };

    const updateTechAPI = async (data: TApiTechnician, name?: string) => {
        await apiRequestSecuredPatch(
            "users/add",
            currentUserTokenId || (await getUserTokenId()),
            {
                name,
                data: data.other,
                education: data.education || { education: [] },
                experience: data.experience || { experience: [] },
            }
        );
    };

    const UserTypeRedirects: {
        [key: string]: RouteEnumType;
    } = {
        lab: "dashboardLab",
        tech: "dashboardTech",
        admin: "adminDashboard",
    };

    const singInUser = async (
        email: string,
        pwd: string
    ): Promise<TUserInfo> => {
        const auth = getAuth(app);
        const fbUserInfo = await signInWithEmailAndPassword(auth, email, pwd);

        currentUserTokenId = await fbUserInfo.user.getIdToken();
        await apiRequestSecureGet<any>("users/login", currentUserTokenId);
        const userInfo = await getUserInfo();

        logInUser(currentUserTokenId, userInfo);

        navigateToRoute(UserTypeRedirects[userInfo.type]);

        return userInfo;
    };

    const resetPassword = async (email: string): Promise<boolean | void> => {
        try {
            const auth = getAuth(app);
            const message = await sendPasswordResetEmail(auth, email)
                .then((data) => {
                    if (data === undefined) {
                        return true;
                    }
                })
                .catch((error) => {
                    if (error.code === "auth/user-not-found") {
                        return false;
                    }
                });
            return message;
        } catch (error: any) {
            return error.code;
        }
    };

    const getUserInfo = async (): Promise<TUserInfo> => {
        const respUserInfo = await apiRequestSecureGet<TUserInfo>(
            "users/info",
            currentUserTokenId || (await getUserTokenId())
        );

        return respUserInfo.data;
    };

    const removeFromMyContactList = async (
        contactUserId: string
    ): Promise<any> => {
        return apiRequestSecuredDelete(
            "users/removecontact",
            currentUserTokenId || (await getUserTokenId()),
            { contactUserId: contactUserId }
        );
    };

    const getAnyUserInfo = async (userId: string): Promise<TApiTechnician> => {
        const respUserInfo = await apiRequestSecureGet<TApiTechnician>(
            "users/infobyid",
            currentUserTokenId || (await getUserTokenId()),
            {
                userid: userId,
            }
        );

        return respUserInfo.data;
    };

    const navigateToDashboardClickHandler = (e: MouseEvent) => {
        e.preventDefault();

        if (userInfo && isLoggedIn) {
            const section: RouteEnumType =
                userInfo.type === "lab" ? "dashboardLab" : "dashboardTech";

            navigateRouteClickHandler(section, e);
        }
    };
    const navigateToMyAccountClickHandler = (e: MouseEvent) => {
        e.preventDefault();

        if (userInfo && isLoggedIn) {
            const section: RouteEnumType =
                userInfo.type === "lab"
                    ? "dentalLabAccount"
                    : "dentalTechAccount";

            navigateRouteClickHandler(section, e);
        }
    };

    const navigateToMyHomeClickHandler = (e: MouseEvent) => {
        e.preventDefault();
        const section: RouteEnumType =
            !userInfo || !isLoggedIn ? "home" : "homeRegistered";

        navigateRouteClickHandler(section, e);
    };

    const getDashboardRoute = (): string => {
        if (!userInfo || !isLoggedIn) return "home";

        return getRoute(
            userInfo.type === "lab" ? "dashboardLab" : "dashboardTech"
        );
    };

    const getHomeRoute = (): string => {
        if (!userInfo || !isLoggedIn) return "home";
        return getRoute("homeRegistered");
    };

    const getMyAccountRoute = (): string => {
        if (!userInfo || !isLoggedIn) return "home";

        return getRoute(
            userInfo.type === "lab" ? "dentalLabAccount" : "dentalTechAccount"
        );
    };

    /**
     * generic method to convert a technician to a common UserInfo type used
     * to show common info between tech and labs
     * @param data
     * @param name
     * @returns TUserInfo
     */
    const techAPI2UserINfo = (
        data: TApiTechnician,
        name?: string
    ): TUserInfo => {
        return {
            id: data.id,
            images: data.images,
            name: name || data.name,
            type: "tech",
            enabled: data.enabled,
            suprSend: {
                subscriberId: data?.suprSend?.subscriberId,
            },
            other: {
                desiredRegion: data.other.desiredRegion,
                desiredSalary: data.other.desiredSalaryPerYear,
                hobbies: data.other.hobbies,
                interests: data.other.interests,
                positions: data.other.positions,
                yearsOfExperience: data.other.yearsOfExperience,
                imgUrl: data.other.imgUrl,
            },
        };
    };

    const LabSignUpFormData2UserInfo = (
        data: LabSignUpFormData,
        userInfo: TApiLab,
        name?: string
    ): TUserInfo => {
        return {
            id: userInfo.id,
            name: name || data.labName || userInfo.name,
            other: {
                desiredRegion: data.desiredRegion as string,
                desiredSalary: data.desiredSalaryPerYear as number,
                hobbies: data.hobbies as string[],
                interests: data.interests as string[],
                positions: data.positions as string[],
                yearsOfExperience: data.yearsInBusiness as number,
                imgUrl: "",
            },
            type: "lab",
            images: { ...userInfo.images },
            enabled: true,
            suprSend: {
                subscriberId: "",
            },
        };
    };

    const reportUser = async (
        userId: string,
        reason: string
    ): Promise<string> => {
        try {
            await apiRequestSecuredPost(
                "users/report",
                await getUserTokenId(),
                {
                    reported_user_id: userId,
                    reason,
                }
            );
        } catch (error: any) {
            if (error?.response?.data) {
                const errorMessage = error.response.data.error as string;
                switch (errorMessage) {
                    case "Already reported by this user":
                        return "You already reported this user";
                    case "Disabled user":
                        return "This user is already disabled, you can't report it.";
                    default:
                        throw error;
                }
            } else throw error;
        }

        return "ok";
    };

    const addLabToBlacklist = async (lab_id: string): Promise<boolean> => {
        try {
            await apiRequestSecuredPost(
                "users/blacklist",
                currentUserTokenId || (await getUserTokenId()),
                { lab_id }
            );
        } catch (err) {
            console.error(err);
        }
        return true;
    };

    const deleteLabBlacklist = async (lab_id: string): Promise<boolean> => {
        try {
            await apiRequestSecuredDelete(
                "users/deleteblacklist",
                currentUserTokenId || (await getUserTokenId()),
                { lab_id: lab_id }
            );
        } catch (error) {
            console.error(error);
        }
        return true;
    };

    return {
        reportUser,
        singInUser,
        configureNewLab,
        configureNewTech,
        logOutUser: () => {
            getAuth(app).signOut();
            logOut();
            navigateToRoute("home");
        },
        logOutUserDueToInactivity: () => {
            getAuth(app).signOut();
            logOut();
            navigateToRoute("logoutduetoinactivity");
        },
        isLoggedIn,
        signUpUser,
        userInfo,
        navigateToMyHomeClickHandler,
        navigateToDashboardClickHandler,
        navigateToMyAccountClickHandler,
        updateLab,
        updateTech,
        updateTechAPI,
        getHomeRoute,
        getDashboardRoute,
        getMyAccountRoute,
        getAnyUserInfo,
        removeFromMyContactList,
        techAPI2UserINfo,
        LabSignUpFormData2UserInfo,
        resetPassword,
        addLabToBlacklist,
        deleteLabBlacklist,
    };
};
