import { useAuthContext } from "../../../contexts/auth-context";
import { useRouteNavigation } from "../../../lib/common/routes";

export const HowItWorks = () => {
    const { navigateRouteClickHandler } = useRouteNavigation();
    const { isLoggedIn, userInfo } = useAuthContext();

    return (
        <>
            <div className="container-fluid image-section-howitworks image-section-howitworks__lab ">
                {/* Dental Labs Section */}
                <div className="container">
                    <div className="row p-3 p-md-5">
                        <div className="col-9 col-lg-5 text-container-black text-container-black__labs px-4 px-md-5 text-white">
                            <div className="row pb-3 ps-1 fs-4">
                                <strong>DENTAL LABS</strong>
                            </div>
                            <div className="row ps-3 fs-5 mb-0 fw-bold">
                                SCRAMBLING AROUND FOR A GOOD DENTAL LAB TECH?
                            </div>
                            <div className="row pt-4 ps-3">
                                Maybe you're looking in all the wrong places. At
                                a time when dental lab work is in high demand,
                                dental labs around the country are finding it
                                harder and harder to hire qualified dental
                                technicians.
                            </div>
                            <div className="row pt-3 ps-3">
                                And yet today's impersonal supermarket job sites
                                with their dry generic job listings fail to
                                serve our dental labs and the techs that are
                                seeking their ideal job.
                            </div>
                            <div className="mt-4">
                                <button
                                    className="btn btn-readMore px-4"
                                    onClick={(e) =>
                                        navigateRouteClickHandler("story", e)
                                    }
                                >
                                    READ MORE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid image-section-howitworks image-section-howitworks__tech">
                {/* Dental Tech Section */}
                <div className="container">
                    <div className="row p-3 p-md-5">
                        <div className="col-9 col-lg-5 text-container-white p-4 p-md-5 text-black">
                            <div className="row pb-3 ps-1 fs-4">
                                <strong>DENTAL TECH</strong>
                            </div>
                            <div className="row ps-3 fs-5 mb-0 fw-bold">
                                CURIOUS WHAT OPPORTUNITIES ARE OUT THERE?
                            </div>
                            <div className="row pt-4 ps-3">
                                In today's dental lab market techs are extremely
                                valuable and that's good. But what if you're not
                                happy where you are? That's where we come in.
                            </div>
                            <div className="row pt-3 ps-3">
                                Signing up makes you visible to labs around the
                                country. The labs can invite you to take a look
                                at their lab profile. You decide if you want to
                                explore the invite or decline.
                                <br />
                                <br />
                                <p className="p-0">
                                    <strong>Pretty cool right?</strong>
                                </p>
                                <p className="px-0 p1-2">
                                    <strong>Feels good to be in demand.</strong>
                                </p>
                            </div>
                            <div className="mt-4">
                                <button
                                    className="btn btn-readMore px-4"
                                    onClick={(e) =>
                                        navigateRouteClickHandler(
                                            !!isLoggedIn &&
                                                userInfo?.type === "tech"
                                                ? "dentalLabSearch"
                                                : "signupTech",
                                            e
                                        )
                                    }
                                    disabled={
                                        !!isLoggedIn &&
                                        userInfo?.type !== "tech"
                                    }
                                >
                                    {!!isLoggedIn
                                        ? userInfo?.type === "tech"
                                            ? "Find Your Perfect Lab"
                                            : `You are logged in as ${userInfo?.type}`
                                        : "Sign Up"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
