import { SUPPORT_EMAIL } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { Header } from "../../common/header";
import { Footer } from "../main-page/footer";
export const PrivacyPolicy = () => {
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();

    return (
        <>
            <Header title="Privacy Policy" />
            <div className="container-fluid">
                <div className="container text-justify px-5">
                    <p>
                        <small>Effective March 20, 2024</small>
                    </p>

                    <p>
                        WinWinGo (“WinWinGo,", “we”, ”us," or "our") is the
                        company that runs the winwingo.com website and has
                        created this Privacy Policy ("Policy") to inform you of
                        our practices related to our collection, use, storage,
                        and transmission of information. For the purposes of
                        this Policy, our website includes all web pages,
                        content, and functionality that are available from the
                        “winwingo.com" domain, which may be accessed via your
                        web browser at www.winwingo.com (collectively, our
                        "Website"). The Website and any other services offered
                        by us from time to time, including, without limitation,
                        advertising, resumes database, email service, online
                        messaging service, and any mobile application that we
                        may elect to offer are referred to herein as the
                        "Service" or "Services."
                    </p>
                    <p>
                        <strong>
                            BY CONTINUING TO ACCESS THE SERVICES, YOU CONSENT TO
                            OUR POLICIES AND PRACTICES DESCRIBED IN THIS POLICY
                            AS WELL AS TO OUR{" "}
                            <a
                                style={{ color: "orange" }}
                                href={getRoute("termsOfUse")}
                                onClick={(e) =>
                                    navigateRouteClickHandler("termsOfUse", e)
                                }
                            >
                                <strong>Terms of Use</strong>
                            </a>
                            . FURTHERMORE, BY CONTINUING TO VIEW AND/OR ACCESS
                            THE SERVICES, YOU EXPRESSLY CONSENT TO OUR
                            COLLECTION, PROCESSING, USE, AND DISCLOSURE OF ANY
                            ALL INFORMATION YOU PROVIDE VIA THE SERVICES OR THAT
                            WE OTHERWISE COLLECT FROM YOU, AS PROVIDED FOR
                            HEREIN. IF YOU DO NOT CONSENT OR AGREE WITH ANY
                            PROVISION OF THIS POLICY, YOU MUST IMMEDIATELY CEASE
                            ACCESSING THE SERVICES.
                        </strong>
                    </p>
                    <p>
                        <strong>Scope of Policy</strong>
                    </p>
                    <p>
                        The information provided in this Policy covers your
                        interactions via the Services, including, without
                        limitation, the features, applications, emails, content,
                        downloads, products, and all other information and
                        services offered via the Services. Certain portions of
                        the Services may only be accessible to registered users
                        and such access may be subject to additional terms and
                        conditions as provided via those particular Services or
                        on the Website.
                        <br />
                        <br />
                        It is not our intention to market to children.
                        Therefore, we prohibit use of the Services by persons
                        under the age of eighteen (18). Any person under the age
                        of eighteen (18) should immediately discontinue use of
                        all of the Services and may not register on or for the
                        Services, any account with WinWinGo, provide any
                        information via the Services, and/or participate in any
                        contests or raffles sponsored by WinWinGo. We will not
                        knowingly collect, maintain, or transmit information
                        about a person under the age of eighteen (18) without
                        the consent of or upon the request of the person's
                        parent or legal guardian. We reserve the right to
                        immediately delete any and all personal information
                        provided by persons under the age of eighteen (18), upon
                        discovery and without notice.
                        <br />
                        <br />
                        This Policy does not apply to any third party websites,
                        links, content, activities, or services that are
                        accessible via the Services (collectively, "Third Party
                        Services"). Information received by you and any activity
                        carried out via Third Party Services is subject to such
                        third party's ownership, control, privacy policies,
                        other policies, and terms. You are responsible to read
                        and understand the{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("termsOfUse")}
                            onClick={(e) =>
                                navigateRouteClickHandler("termsOfUse", e)
                            }
                        >
                            <strong>Terms of Use</strong>
                        </a>{" "}
                        and privacy policies applicable to any Third Party
                        Services. We do not review Third Party Services for
                        availability, accuracy, completeness, content, activity,
                        or security and make no endorsements, representations,
                        or warranties regarding the same.
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU
                        ASSUME ALL RISK AND WWG SHALL NOT BE HELD RESPONSIBLE
                        FOR THE CONTENT OR ACTIVITY OF ANY THIRD PARTY SERVICES.
                        <br />
                        <br />
                        Please see our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("termsOfUse")}
                            onClick={(e) =>
                                navigateRouteClickHandler("termsOfUse", e)
                            }
                        >
                            <strong>Terms of Use</strong>
                        </a>{" "}
                        for more information regarding your rights and
                        responsibilities related to any International use of the
                        Services.
                    </p>
                    <br />
                    <p>
                        <strong>Types of Information Collected for Use</strong>
                    </p>
                    <p>
                        As used herein, your "Personal Information" consists of
                        your Contact Information, Employment Information, and
                        Financial Information, as described below.
                    </p>
                    <br />
                    <p>
                        <strong>Contact Information</strong>
                    </p>
                    <p>
                        You may generally visit the Website without revealing
                        any of your Contact Information. As used herein,
                        "Contact Information" means information that would allow
                        a party to contact you with nominal effort, including,
                        for example, your full name, address, telephone number,
                        and/or e-mail address. Your Contact Information will be
                        shared with others via the Website at your direction, as
                        provided on the Website.
                        <br />
                        <br />
                        We will use your Contact Information to send you
                        e-mails, newsletters, and information that you have
                        requested; and administer the Services and services. We
                        may send you notices and marketing materials related to
                        services that you have requested or that we determine
                        may be of interest to you. However, you may opt-out of
                        marketing emails via the unsubscribe method included
                        therein.{" "}
                    </p>
                    <br />
                    <p>
                        <strong>Employment Information</strong>
                    </p>
                    <p>
                        You will have the option to request career-related
                        services from us, which may require you to provide
                        additional information such as employment-related
                        information, including, without limitation, your
                        employment history, educational history, certifications,
                        references, and demographic information (collectively,
                        "Employment Information"). Such information is not
                        strictly required for you to maintain an account with
                        us, but may be helpful or necessary to create an
                        attractive or useful profile, to be viewed by others via
                        the Services. Your Employment Information will be shared
                        with others via the Website at your direction, as
                        provided on the Website.
                    </p>
                    <br />
                    <p>
                        <strong>Financial Information</strong>
                    </p>
                    <p>
                        Notwithstanding any other provision of this Policy, any
                        financial information that you provide to us, including
                        any credit card number, CCV code, or financial account
                        information (collectively, "Financial Information") will
                        only be used and shared by us for the purpose of
                        processing any payments that we require for your use of
                        our services.
                    </p>
                    <br />
                    <p>
                        <strong>Browsing Information</strong>
                    </p>
                    <p>
                        When you use the Website, our servers (which may be
                        hosted by a third party service provider) may from time
                        to time collect information related to your web browsing
                        activities, including, without limitation, your internet
                        protocol ("IP") address, which web pages you view, the
                        times you view them, your computer's operating system,
                        and your web browser software (collectively, "Browsing
                        Information").
                        <br />
                        <br />
                        We do not intentionally link Browsing Information to
                        Personal Information. Rather, we use your Browsing
                        Information to personalize aspects of your search
                        experience, for example, enabling you to save your
                        search location on your computer system or web browser.
                        We also use Browsing Information for internal purposes,
                        for example, to administer, improve, monitor, and secure
                        our computer systems.
                        <br />
                        <br />
                        You are solely responsible for abiding by any and all
                        legal restrictions to your employment whether based on
                        age or otherwise, and assume all risks associated
                        therewith.
                    </p>
                    <br />
                    <p>
                        <strong>Use of Cookies</strong>
                    </p>
                    <p>
                        Our website uses cookies. A cookie is a file containing
                        an identifier (a string of letters and numbers) that is
                        sent by a web server to a web browser and is stored by
                        the browser. The identifier is then sent back to the
                        server each time the browser requests a page from the
                        server.
                        <br />
                        <br />
                        We use Google Analytics to analyze the use of our
                        website. Our analytics service provider generates
                        statistical and other information about website use by
                        means of cookies.
                        <br />
                        <br />
                        You can delete cookies already stored on your computer.
                        Please visit the 'Help' option in your browser menu to
                        learn how to do this. Deleting cookies will have a
                        negative impact on the usability of this website.
                        <br />
                        <br />
                        Our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("cookiePolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("cookiePolicy", e)
                            }
                        >
                            <strong>Cookie Policy</strong>
                        </a>{" "}
                        describes what cookies are and how and they're being
                        used by the winwingo.com website ("Website" or
                        "Service") and all of its related products and services
                        (collectively, "Services"). This Policy is a legally
                        binding agreement between you ("User", "you" or "your")
                        and WinWinGo Ltd, including any and all of our
                        affiliated products. You should read this Policy so you
                        can understand the types of cookies we use, the
                        information we collect using cookies and how that
                        information is used. It also describes the choices
                        available to you regarding accepting or declining the
                        use of cookies. For further information on how we use,
                        store and keep your personal data secure, please visit
                        our full{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("cookiePolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("cookiePolicy", e)
                            }
                        >
                            <strong>Cookie Policy</strong>
                        </a>
                        .
                    </p>
                    <br />
                    <p>
                        <strong>Responsive Information</strong>
                    </p>
                    <p>
                        From time to time, we may invite you to submit questions
                        or comments about the Website or our services, request
                        information, or subscribe to a newsletter. Due to the
                        nature of some of these activities, we may ask that you
                        complete and submit information online. We will use and
                        share such information for the purposes for which it was
                        requested and for our own internal purposes in our
                        discretion.
                    </p>
                    <br />
                    <p>
                        <strong>Public Forums Guidelines</strong>
                    </p>
                    <p>
                        We may from time to time offer services such as
                        voluntary chat rooms, public forums, online reviews as
                        well as participation in surveys, questionnaires,
                        contests, etc. (each a "Public Forum") via the Services,
                        which are not necessary for our provision of other
                        services to you. Please note that any information you
                        disclose in a Public Forum becomes public, unless we
                        provide otherwise on the Website. We also reserve the
                        right to share any such information publicly, for
                        example, to announce contest winners or for marketing
                        purposes, as provided on the Website.
                        <br />
                        <br />
                        You are solely responsible for the security and privacy
                        of any disclosures of content that you make by these
                        means and further represent and warrant that you own all
                        such content and that its disclosure does not violate
                        any law, rule, or regulation, or any right of any third
                        party. Additionally, you expressly consent to our
                        ownership of such information and our use of it for
                        marketing purposes, upon your disclosure. You agree not
                        to post information, without regard to format, that is
                        illegal, profane, inflammatory, or unrelated to our
                        business. We reserve the rights to refuse to display and
                        to edit or remove content that, in our opinion, may
                        frustrate our marketing efforts.
                    </p>
                    <br />
                    <p>
                        <strong>
                            Additional Information Regarding Our Use of
                            Information
                        </strong>
                    </p>
                    <p>
                        To the fullest extent allowed by applicable law, we
                        reserve the right to use and share with third parties
                        any information that you provide to us in order to:
                    </p>
                    <ul>
                        <li>
                            Provide any services, products, or information you
                            have requested;
                        </li>
                        <li>
                            Carry out any agreements you enter into with us;
                        </li>
                        <li>
                            Enforce or apply our{" "}
                            <a
                                style={{ color: "orange" }}
                                href={getRoute("termsOfUse")}
                                onClick={(e) =>
                                    navigateRouteClickHandler("termsOfUse", e)
                                }
                            >
                                <strong>Terms of Use</strong>
                            </a>{" "}
                            and/or this Policy;
                        </li>
                        <li>
                            Assist us in our efforts to collect any amounts that
                            you owe to us;
                        </li>
                        <li>
                            Administer, improve, monitor, and secure the
                            Services and our computer systems;
                        </li>
                        <li>
                            Comply with any and all applicable laws, rules, and
                            regulations;
                        </li>
                        <li>
                            Respond to what we reasonably determine to be a
                            valid request for information by any
                            governmental/regulatory authority or officer of the
                            court;
                        </li>
                        <li>
                            Engage in legal action by or against WinWinGo, its
                            owners, officers, or managers;
                        </li>
                        <li>
                            Protect our rights, reputation, and property, or
                            that of our users, affiliates, or any other person;
                            and
                        </li>
                        <li>
                            Take actions as otherwise provided in this Policy
                            and our{" "}
                            <a
                                style={{ color: "orange" }}
                                href={getRoute("termsOfUse")}
                                onClick={(e) =>
                                    navigateRouteClickHandler("termsOfUse", e)
                                }
                            >
                                <strong>Terms of Use</strong>
                            </a>
                            .
                        </li>
                    </ul>
                    <br />
                    <p>
                        <strong>
                            Information Generally Shared With Third Parties
                        </strong>
                    </p>
                    <p>
                        We share Browsing Information with third party
                        advertising companies to present advertisements to you
                        when you use the Services. These companies may combine
                        your Browsing Information with other information about
                        you that they gather from third party websites in an
                        effort to provide advertisements that will be of
                        interest to you. These companies may also place
                        persistent cookies on your computer system via the
                        Website or a third party's website to determine your web
                        browsing habits. You may choose to block cookies via
                        your web browser according to the instructions provided
                        by the creator of your web browsing software; however,
                        this may prevent us from being able to provide certain
                        services to you and limit the functionality of the
                        Services.
                        <br />
                        <br />
                        We share Personal Information with third party service
                        providers as necessary for our provision of services and
                        products to you. Such third party service providers may
                        include payment processors, communication platform
                        providers, hosting service providers, and others.
                    </p>
                    <br />
                    <p>
                        <strong>Sharing of Aggregated Information</strong>
                    </p>
                    <p>
                        We may from time to time collect information to assist
                        us in developing statistics related to the usage of the
                        Services from multiple visitors, including, without
                        limitation, the number of visitors to the Website, the
                        number of visitors to each page of the Website,
                        visitor's IP addresses, third party websites linked to,
                        search terms, and the domain names of Website visitors'
                        Internet Service Providers, and others (collectively,
                        "Statistical Browsing Information"). Such information
                        will be maintained, used, and disclosed in aggregate
                        form only and will not contain or be linked to your
                        Personal Information. We use Statistical Browsing
                        Information and publicly available aggregate labor
                        statistics to aid us in our administrative and marketing
                        efforts. We may use such aggregate information to
                        analyse trends, administer the Website, track website
                        visitors' movement, and gather broad demographic
                        information. We may share such aggregate information
                        with third parties in our discretion, for example, to
                        assist them in targeting advertisements to appropriate
                        audiences. We may also use and share this information
                        collected through our own additional products, platforms
                        and services, including but not limited to, winwingo.com{" "}
                    </p>
                    <br />
                    <p>
                        <strong>
                            Information Ownership Upon Merger or Acquisition
                        </strong>
                    </p>
                    <p>
                        If this company becomes part of another organization,
                        that organization will possess all information that we
                        collect and store. That organization will also assume
                        the rights and obligations described in this Policy.
                    </p>
                    <br />
                    <p>
                        <strong>Security</strong>
                    </p>
                    <p>
                        We strive to maintain excellent security for your
                        Personal Information via both physical and technological
                        controls. We use encryption technology for the
                        transmission of credit card and certain other private
                        information from your web browser to our servers, and do
                        not store complete credit card information on our
                        computer systems. However, we recognize that our control
                        over payment card processors is limited and that perfect
                        security is a myth. Therefore, we will use reasonable
                        measures to notify you, as required by law, in the
                        unlikely event of an unauthorized release of your
                        information.
                        <br />
                        <br />
                        We require you to register for a username and password
                        to access certain protected areas of the Services. It is
                        your responsibility to maintain the confidentiality of
                        your password(s) and other user credentials. More
                        information regarding your rights and obligations with
                        respect to your username and password and other security
                        measures are provided in our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("termsOfUse")}
                            onClick={(e) =>
                                navigateRouteClickHandler("termsOfUse", e)
                            }
                        >
                            <strong>Terms of Use</strong>
                        </a>
                        .
                        <br />
                        <br />
                        You agree to abide by all WinWinGo computer, network,
                        account, and information security policies as
                        communicated to you from time to time, whether such
                        policies are provided via the Services, email, mail,
                        phone, or in person.
                    </p>
                    <br />
                    <p>
                        <strong>Accurate and Complete Information</strong>
                    </p>
                    <p>
                        By providing information to us, you represent and
                        warrant that such information is owned or properly
                        licensed by you, accurate, complete, that its use via
                        the Services does not violate any law, rule, or
                        regulation, and does not infringe or violate any right
                        of any third party. You agree to inform us of any
                        changes to any information that you have previously
                        provided to us. We will not be held responsible for any
                        inaccurate information provided by you, or for any
                        effects that any such inaccuracy may cause. You also
                        agree to be solely responsible for maintaining the
                        secrecy of any username and password you use to access
                        any part of the Services.
                    </p>
                    <br />
                    <p>
                        <strong>Modification of Information</strong>
                    </p>
                    <p>
                        If you wish to review, correct, modify, or delete any
                        information that you have provided to us, you may access
                        your account and review, correct, and delete your
                        Personal Information. We reserve the rights to take
                        action to verify your identity prior to any access or
                        change and to deny a request in the event that we unable
                        to verify your identity to our satisfaction. We reserve
                        the right to charge a reasonable fee for such services
                        in the case of multiple or frequent requests that
                        require us to act beyond any automated means that we
                        provide to fulfil any such requests. Additionally, we
                        reserve the right to retain copies in archived form of
                        all information that you provide to us.
                    </p>
                    <br />
                    <p>
                        <strong>Amendments</strong>
                    </p>
                    <p>
                        We reserve to amend this policy from time to time in our
                        sole discretion. We may do so at any time by posting an
                        updated privacy policy on the Website, to be effective
                        upon posting. We may also elect, in our sole discretion,
                        to notify you of any amendments to the policy via any
                        e-mail or postal address that you have provided to us or
                        by placing a prominent notice via the Services.
                        <br />
                        <br />
                        We encourage you to review this Policy regularly for any
                        changes. You are responsible for staying up-to-date
                        regarding our current privacy policy to the fullest
                        extent allowed by applicable law.
                        <br />
                    </p>
                    <p>
                        <strong>
                            BY CONTINUING TO ACCESS OR USE THE SERVICES AFTER
                            ANY REVISIONS BECOME EFFECTIVE, YOU AGREE TO BE
                            BOUND BY THE REVISED POLICY. IF YOU DO NOT AGREE TO
                            THE REVISED POLICY, YOU ARE NO LONGER AUTHORIZED TO
                            USE THE SERVICES AND MUST IMMEDIATELY DISCONTINUE
                            ACCESS AND/OR USE.
                        </strong>
                    </p>
                    <br />
                    <p>
                        <strong>Contact Us</strong>
                    </p>
                    <p>
                        {" "}
                        If you would like to contact us to understand more about
                        this Policy or wish to contact us concerning any matter
                        relating to your Privacy, you may do so by sending an
                        email to {SUPPORT_EMAIL}.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};
