import { useUserService } from "../../services/users";
import { TopMenu } from "../sections/main-page/top-menu";

export const DashboardLink = () => {
    const { getDashboardRoute, navigateToDashboardClickHandler } =
        useUserService();
    return (
        <a
            className="nav-link nav-row rounded-3 ms-2"
            href={getDashboardRoute()}
            onClick={navigateToDashboardClickHandler}
        >
            My Dashboard
        </a>
    );
};

export const MyAccountLink = () => {
    const { getMyAccountRoute, navigateToMyAccountClickHandler } =
        useUserService();
    return (
        <a
            className="nav-link nav-row rounded-3 ms-2"
            href={getMyAccountRoute()}
            onClick={navigateToMyAccountClickHandler}
        >
            My Profile
        </a>
    );
};

export const MyHomeLink = () => {
    const { getHomeRoute, navigateToMyHomeClickHandler } = useUserService();
    return (
        <a
            className="nav-link nav-row rounded-3 ms-2"
            href={getHomeRoute()}
            onClick={navigateToMyHomeClickHandler}
        >
            Home
        </a>
    );
};

export const Header = ({ title }: { title: string }) => {
    return (
        <>
            <TopMenu />
            {title && (
                <div className="container-fluid">
                    <div className="container">
                        <div className="text-center py-4">
                            <h2>{title}</h2>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
