import { SUPPORT_EMAIL } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { Header } from "../../common/header";
import { Footer } from "../main-page/footer";
export const TermsOfUse = () => {
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();

    return (
        <>
            <Header title="Terms of Use" />
            <div className="container-fluid">
                <div className="container text-justify px-5">
                    <p>
                        <small>Effective March 20, 2024</small>
                    </p>
                    <p>
                        WinWinGo (“WinWinGo,” “WWG,” “we,” “us,” or “our”) is
                        the company that owns and operates winwingo.com and has
                        created these Terms of Use (“Terms”) to inform you of
                        your and our respective rights and obligations with
                        respect to the use of our online services. Please read
                        these Terms carefully before using any webpages,
                        content, service, and/or functionality that is available
                        from the “winwingo.com” domain, which may be accessed
                        via your web browser at www.winwingo.com (collectively,
                        our “Website”), or any other service provided by us from
                        time to time, including, without limitation any email
                        service, online messaging service, and/or any mobile
                        application that we may elect to offer (collectively
                        with the Website, the “Services”). As used herein, the
                        Services shall include all associated Accounts and
                        Content.
                    </p>
                    <p>
                        These Terms constitute a binding agreement between
                        WinWinGo and you, a visitor to or user of the Services,
                        along with any entity or other person that you represent
                        (collectively, “you” and “your”). If you are using or
                        accessing the Services on behalf of any entity or other
                        person, you represent and warrant that you are
                        authorized by such entity or other person to accept
                        these Terms on such entity’s or other person’s behalf.
                        Your access to and use of the Services is conditioned
                        upon your acceptance of and compliance with these Terms,
                        which apply to all visitors, users, and others who wish
                        to access or use any part or all of the Services.
                    </p>
                    <p>
                        {" "}
                        <strong>
                            BY ACCESSING OR USING THE SERVICES, YOU EXPLICITLY
                            AGREE THAT YOU UNDERSTAND AND WILL COMPLY WITH AND
                            BE BOUND BY THESE TERMS. IF YOU DISAGREE WITH ANY
                            PART OF THESE TERMS, THEN YOU DO NOT HAVE OUR
                            PERMISSION TO ACCESS THE SERVICES AND MUST
                            IMMEDIATELY DISCONTINUE ACCESS AND/OR USE.
                        </strong>
                    </p>
                    <br />
                    <h5>Incorporated Policies</h5>
                    <p>
                        <strong>Privacy Policy</strong>
                    </p>
                    <p>
                        For more information regarding our privacy practices and
                        your rights and responsibilities related to them, please
                        review our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("privacyPolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("privacyPolicy", e)
                            }
                        >
                            <strong>Privacy Policy</strong>
                        </a>{" "}
                        which is hereby incorporated in these Terms by reference
                        as if fully restated herein.
                    </p>
                    <p>
                        <strong>Payment Policy</strong>
                    </p>
                    <p>
                        All rates, charges, and payments related to your Account
                        and use of the Services shall be made according to our
                        financial information section in our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("privacyPolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("privacyPolicy", e)
                            }
                        >
                            <strong>Privacy Policy</strong>
                        </a>{" "}
                        which are incorporated by reference as if fully restated
                        herein.
                    </p>
                    <br />
                    <h5>Access to the Services</h5>
                    <p>
                        <strong>Registration and Password Protection</strong>
                    </p>
                    <p>
                        To access certain Services, you must you register for an
                        account (“Account”) with us by following the account
                        registration procedures posted on the Website. When you
                        create an Account, you represent, warrant, and guarantee
                        that you are above the age of 18 years, and that all of
                        the information you provide to us is accurate, complete,
                        and current at all times. By logging in to your Account
                        with your username and password (collectively,
                        “Credentials”), you agree to comply with any and all
                        procedures regarding your Account and Credentials, as
                        specified by WinWinGo by any means from time to time.
                    </p>
                    <p>
                        <strong>
                            YOU ACCEPT FULL RESPONSIBILITY FOR ANY ACTIONS OR
                            INACTIONS BY PERSONS TO WHOM YOU GRANT ACCESS TO
                            YOUR CREDENTIALS AND/OR ACCOUNT, WHETHER
                            INTENTIONALLY OR UNINTENTIONALLY. FURTHERMORE, YOU
                            AGREE TO ACCEPT RESPONSIBILITY FOR ANY AND ALL
                            ACTIVITIES OR ACTIONS THAT OCCUR VIA YOUR ACCOUNT
                            AND/OR CREDENTIALS.
                        </strong>
                    </p>
                    <p>
                        You must notify us immediately upon becoming aware of
                        any breach of security or unauthorized use of the
                        Services, your Account, or your Credentials. If you do
                        not notify us, we may be prevented from implementing
                        measures to protect the integrity, availability, and
                        confidentiality of the Services for you and other users.
                    </p>
                    <p>
                        <strong>Termination</strong>
                    </p>
                    <p>
                        WinWinGo may, in its sole discretion and for any reason
                        and at any time modify, interrupt, suspend, terminate,
                        or suspend access to the Services, in whole or in part,
                        and/or your Account or Credentials. WinWinGo will use
                        reasonable efforts to notify you in advance about any
                        material modification, suspension, or termination by
                        WinWinGo that is not caused by your breach of these
                        Terms. However, WinWinGo reserves the right to terminate
                        your use of the Services and/or your Account without
                        notice, upon your breach of any of these Terms.
                    </p>
                    <p>
                        You may terminate your access to and use of your Account
                        and the Services at any time and for any reason
                        whatsoever.
                    </p>
                    <br />
                    <p>
                        <strong>Effect of Termination</strong>
                    </p>
                    <p>
                        You are responsible for ensuring that you retain a copy
                        of any information that you upload, post, or otherwise
                        provide to or via the Services. We shall have no
                        obligation to provide copies of any information
                        associated with your Account, or use of the Services,
                        upon any modification, suspension, or termination of
                        your Account, or any or all of the Services for any
                        reason.
                    </p>
                    <br />
                    <p>
                        <strong>Content</strong>
                    </p>
                    <p>
                        WinWinGo, you, and other users of the Services may post,
                        link, store, share, and otherwise make available certain
                        information, text, graphics, photographs, videos, audio,
                        designs, or other material created or provided by
                        WinWinGo, you, other users of the Services, and third
                        parties in any combination (collectively, “Content”).
                        <br />
                        Please see our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("privacyPolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("privacyPolicy", e)
                            }
                        >
                            <strong>Privacy Policy</strong>
                        </a>{" "}
                        for more information regarding your rights and
                        responsibilities related to any third party websites or
                        services.
                    </p>
                    <p>
                        <strong>Service Content</strong>
                    </p>
                    <p>
                        Content that you do not provide to us that is accessible
                        via the Services (“Service Content”) is owned by us or
                        is used with permission. You have no right, title, or
                        interest in or to any Service Content except as
                        otherwise provided in these Terms and are prohibited
                        from using any Service Content for any purpose other
                        than for your proper use of the Services. You may not
                        distribute or sell Service Content, whether in whole or
                        in part, for commercial purposes or for personal gain,
                        or make any changes, additions, or deletions to Service
                        Content without advance, written permission from us and
                        the owner.
                    </p>
                    <br />
                    <h5>Your Content</h5>
                    <p>
                        By providing Content on or through the Services, you
                        represent and warrant that: (i) such Content is yours
                        (you own it) and/or you have the right to use it and the
                        right to grant us the rights and license as provided
                        below, (ii) that such Content is not misleading and is
                        true, accurate, and complete, (iii) that the provision
                        of such Content on or through the Services is lawful and
                        does not violate the privacy rights, publicity rights,
                        copyrights, contract rights or any other rights of any
                        person or entity, and (iv) such Content is free of
                        Malware (as defined herein).
                        <br />
                        <br />
                        You are also responsible for the reliability,
                        completeness, and appropriateness of any and all Content
                        that you provide via the Services. We take no
                        responsibility and assume no liability for Content you
                        or any third party provides via the Services. However,
                        by posting Content via the Services you grant us
                        worldwide, royalty-free, non-exclusive, perpetual,
                        unlimited, irrevocable, and fully sub-licensable license
                        and right to use, modify, perform, display, distribute,
                        publish, translate, reproduce, and sell such Content via
                        the Services.
                        <br />
                        <br />
                        We reserve the right but not the obligation to monitor,
                        edit, and remove any and all Content in our sole
                        discretion. We also have the right to use, copy, and
                        share any Content provided by you to promote and/or test
                        the integrity, availability, and confidentiality of the
                        Services, including the retention of such Content in any
                        backups of our computer systems.
                    </p>
                    <br />
                    <p>
                        <strong>Job Information</strong>
                    </p>
                    <p>
                        WinWinGo assumes no responsibility for the content of
                        any job listing or website that is either included in
                        the Website’s search results or linked to via the
                        Website. Such information is created by third parties
                        and collected and provided by us in an automated manner,
                        without any prior screening by us.
                    </p>
                    <br />
                    <p>
                        <strong>Objectionable Content</strong>
                    </p>
                    <p>
                        You hereby acknowledge and agree that you may be exposed
                        to content that may be offensive or objectionable to
                        you, which is provided by other users of the Services
                        and/or third parties. YOU HEREBY ACKNOWLEDGE AND AGREE
                        THAT ALL CONTENT PROVIDED BY ANY OTHER USER OF THE
                        SERVICES OR ANY THIRD PARTY EXPRESSES THE VIEWS OF THE
                        AUTHOR, AND WWG SHALL NOT BE HELD RESPONSIBLE FOR OR
                        LIABLE FOR SUCH CONTENT. YOUR ACCESS TO AND USE OF THE
                        SERVICES IS UNDERTAKEN AT YOUR OWN RISK, and WWG is not
                        responsible for any offensive or otherwise objectionable
                        Content available via the Services but created or
                        provided by any user of the Services or any third party.
                        However, you agree to report to WinWinGo any and all
                        violations of these Terms that you become aware of, via
                        the contact information provided near the end of these
                        Terms.
                    </p>
                    <br />
                    <p>
                        <strong>License for Use of Content</strong>
                    </p>
                    <p>
                        WinWinGo grants you an limited license to display,
                        download, print, and use (collectively, “Use” or “Used”)
                        Service Content via and onto your personal computer or
                        device only (“Limited License”). Under the terms of such
                        Limited License, you may Use Service Content provided
                        that: (i) the WinWinGo copyright notice (set forth
                        below) appears in the Content so Used, and (ii) such Use
                        is solely for your personal or internal business
                        purposes. You acknowledge and agree that this Limited
                        License grants you no right to modify, edit, alter,
                        enhance, remove, or destroy any Service Content in any
                        manner. This Limited License terminates automatically,
                        without notice to you, if you breach any of these Terms.
                        Upon any termination of this Limited License, you agree
                        to immediately destroy any and all copies of Service
                        Content, whether whole or in part, and cease using both
                        your Account and the Services. You are solely
                        responsible for the security and privacy of any Service
                        Content that you Use.
                    </p>
                    <br />
                    <h5>Use of the Services</h5>
                    <p>
                        <strong>Permitted and Prohibited User Conduct</strong>
                    </p>
                    <p>
                        You are only permitted to use your Account, the
                        Services, and Content solely for the purpose of
                        identifying and applying for employment opportunities
                        for yourself and/or to identify and engage prospective
                        employees for legitimate and lawful employment
                        opportunities that you may have, in accordance with
                        these Terms. You are expressly prohibited from and agree
                        not to use any Account, the Services, and any Content
                        for any other purpose (“Prohibited Conduct”). Without
                        limiting the foregoing, we provide a bulleted list of
                        examples of Prohibited Conduct below (organised by
                        topic) solely for the avoidance of doubt. Such list
                        shall not be construed as an exhaustive listing of all
                        Prohibited Conduct.
                    </p>
                    <br />
                    <p>
                        <strong>
                            Without limiting the foregoing, you are prohibited
                            from and agree not use the Services to, or attempt
                            to:
                            <br />
                            <br />
                            Circumvention of Security Measures
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Log in to an Account that is not yours or with
                            Credentials that we did not assign to you;
                        </li>
                        <li>
                            Access Service Content that is not made available to
                            all Services users or is otherwise not intended for
                            your access;
                        </li>
                        <li>
                            Identify, test, challenge, or tamper with the
                            security measures and protections or any
                            vulnerability of the Services;
                        </li>
                        <li>Impersonate anyone via the Services;</li>
                        <li>
                            Modify, reverse engineer, disassemble, decompile, or
                            otherwise attempt or allow others to attempt to
                            discover the underlying computer codes and/or
                            scripts used by the Services;
                        </li>
                        <li>
                            Disable, overwhelm, substantially slow, interfere
                            with or limit authorized access to, or cause a
                            denial of the Services;
                        </li>
                        <li>
                            Intentionally expose the Website or Services to any
                            Malware (as defined herein);
                        </li>
                    </ul>
                    <p>
                        <strong>Misuse of Content</strong>
                    </p>
                    <ul>
                        <li>
                            Use any robot, spider, or other program or device to
                            retrieve or index any portion of the Services;
                        </li>
                        <li>
                            Harvest or otherwise collect information about other
                            users for any purpose other than as expressly
                            permitted herein;
                        </li>
                        <li>
                            Provide Content that relates to a user of the
                            Services other than you, or otherwise publicly
                            disseminate private communications, without express,
                            prior, written consent from such other user, without
                            regard to whether you were a party to such private
                            communication;
                        </li>
                        <li>
                            Frame or link to the Services or any Content without
                            our advance, written permission;
                        </li>
                        <li>
                            Include any hyperlinks or any misleading,
                            irrelevant, or hidden keywords or code in any
                            Content provided by you;
                        </li>
                    </ul>
                    <p>
                        <strong>Undesirable Contact</strong>
                    </p>
                    <ul>
                        <li>
                            Harass, stalk, or otherwise subject any other user
                            of the Services, or any of our personnel, to
                            unwanted or inappropriate contact or communication;
                        </li>
                        <li>
                            Send or make any unsolicited contact with a user of
                            the Services, whether in person, by mail, e-mail,
                            fax, or unsolicited phone call;
                        </li>
                        <li>
                            Advertise any products or services via the Services
                            without our advance, written permission;
                        </li>
                        <li>
                            Provide any information that could be used to
                            facilitate unsolicited contact by a third party to
                            any person, without such person’s advance, written
                            permission;
                        </li>
                    </ul>
                    <p>
                        <strong>Other Prohibited Activities</strong>
                    </p>
                    <ul>
                        <li>
                            Engage in any activity in violation of any law,
                            rule, or regulation, including, without limitation,
                            the infringement of any copyright, trademark, trade
                            secret or other intellectual property right of
                            others or violation of the privacy or publicity
                            rights of any other person;
                        </li>
                        <li>
                            Engage in any employment-related activity or offer
                            of employment in violation of any law, rule, or
                            regulation;
                        </li>
                        <li>
                            Post any position or business opportunity that
                            requires of other individuals, sub-distributors, or
                            sub-agents such as a multilevel marketing scheme,
                            pyramid scheme, franchise, or distributorship
                            arrangement; or
                        </li>
                        <li>
                            Post any position or business opportunity that
                            requires payment from the applicant.
                        </li>
                        <li>
                            Post multiple (more than one) jobs within one paid
                            job package or subscription, unless the job package
                            clearly states advertising for more than one job
                            post.
                        </li>
                        <li>
                            Job posts should specify the hiring company's
                            location. Our terms allow one company location per
                            package.
                        </li>
                    </ul>
                    <p>
                        The foregoing prohibitions do not impose on WinWinGo,
                        and WinWinGo does not assume, any obligation to monitor,
                        edit, or remove any Content or take any action to
                        prevent Prohibited Conduct, except as otherwise provided
                        by applicable law. WinWinGo reserves the right to work
                        with members of law enforcement to facilitate
                        investigations of criminal activity as well as the
                        prosecution of suspected criminals and to share your
                        Content as provided herein or as otherwise provided in
                        our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("privacyPolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("privacyPolicy", e)
                            }
                        >
                            <strong>Privacy Policy</strong>
                        </a>
                        .
                    </p>
                    <br />
                    <p>
                        <strong>Reporters and Researchers</strong>
                    </p>
                    <p>
                        Reporters, researchers, and third party representatives
                        must receive permission from WinWinGo before posting to
                        the Website and/or using the Services, and must identify
                        him or herself as such along with his or her name and
                        affiliation with an organisation in all postings.
                    </p>
                    <br />
                    <p>
                        <strong>International Use</strong>
                    </p>
                    The Services are controlled and operated in whole or in part
                    by us from our offices within the United States of America,
                    and is not intended to subject us to the laws or
                    jurisdiction of any state, country, or territory other than
                    that of the United States of Americas. WinWinGo makes no
                    representations, warranties, or guarantees that the Services
                    are appropriate or available for use in other locations or
                    jurisdictions.
                    <br />
                    <br />
                    YOU ARE RESPONSIBLE AND ASSUME ALL RISK FOR COMPLYING WITH
                    THE LAWS OF THE UNITED STATES OF AMERICA AND THE LAWS OF THE
                    JURISDICTIONS WHERE YOU ARE LOCATED AND FROM WHICH YOU
                    ACCESS THE SERVICES.
                    <br />
                    <br />
                    You represent and warrant that you will not access or use
                    the Services in violation of law. You represent and warrant
                    that you are in full compliance with the regulations and
                    laws of the United States of America and the laws of the
                    jurisdictions where you are located and from which you
                    access the services. The provisions of this “International
                    Use” section shall survive termination or cancellation of
                    your Account, the Services, or these Terms.
                    <br />
                    <br />
                    Nothing contained in these Terms or the Services shall
                    constitute an offer or solicitation to buy or sell any
                    product to anyone in any jurisdiction in which such an offer
                    or solicitation is prohibited by law.
                    <br />
                    <br />
                    <p>
                        <strong>Malicious Software</strong>
                    </p>
                    <p>
                        YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. We cannot
                        and do not warrant that the Services are compatible with
                        your computer systems or that the Services will be
                        provided free of viruses, worms, Trojan horse programs,
                        ransomware or other malware, or disabling devices or
                        other code that manifests restrictive, contaminating, or
                        destructive properties (collectively, “Malware”). You
                        are responsible for implementing safeguards to protect
                        the integrity, availability, and confidentiality of your
                        computer systems and information, and you are
                        responsible for the entire cost of any and all
                        investigations, service, replacement, and repairs of and
                        to any and all computer systems or information that may
                        be necessary as a result of your use of the Services, to
                        the fullest extent allowed by applicable law.
                    </p>
                    <br />
                    <p>
                        <strong>Customer Support and Compatibility</strong>
                    </p>
                    <p>
                        It is your responsibility to select, acquire, and
                        maintain your own computer system and other devices and
                        software that are compatible with your use of the
                        Services.
                        <br />
                        <br />
                        We may, in our sole discretion, provide intermittent
                        customer support on a limited basis as a convenience to
                        you. However, we reserve the right to refuse to provide,
                        cease providing, or charge a reasonable fee for
                        providing customer support, in our sole discretion.
                    </p>
                    <br />
                    <h5>Intellectual Property</h5>
                    <p>
                        <strong>Generally</strong>
                    </p>
                    <p>
                        The Website may contain trademarks, service marks, logos
                        and other names that are the property of WinWinGo or
                        such other party as indicated with respect to that name
                        or icon. WinWinGo trademarks, logos and icons, may not
                        be used without WinWinGo’s prior written consent.
                        <br />
                        <br />
                        You are prohibited from uploading, posting, or otherwise
                        transmitting any materials that violate another person’s
                        intellectual property rights.
                        <br />
                        <br />
                        WinWinGo does not claim ownership of third party
                        Content. However, by posting third party Content to any
                        area of the Website, you grant WinWinGo and its
                        designees a worldwide, royalty-free, non-exclusive
                        perpetual, irrevocable and fully sub-licensable license
                        to use, distribute, reproduce, modify, adapt, publish,
                        translate, publicly perform and publicly display such
                        third party Content (in whole or in part) and to
                        incorporate such third party Content into other works in
                        any format or medium now known or later developed.
                        WinWinGo shall not be liable for any use or disclosure
                        of any third party Content.
                    </p>
                    <br />
                    <p>
                        <strong>Claims of Copyright Infringement</strong>
                    </p>
                    <p>
                        The Digital Millennium Copyright Act of 1998 (the
                        “DMCA”) may provide recourse for copyright owners who
                        believe that material appearing on the Internet
                        infringes their rights under U.K. copyright law. If you
                        believe in good faith that Content provided via the
                        Services infringe your copyright, you (or your
                        authorized agent) may send us a notice requesting that
                        the material be removed or that access to it be blocked.
                        If you believe in good faith that a notice of copyright
                        infringement has been wrongly filed against you, the
                        DMCA permits you to send WinWinGo a counter-notice.
                        <br />
                        <br />
                        ALL NOTICES AND COUNTER-NOTICES MUST CONTAIN ANY AND ALL
                        INFORMATION REQUIRED BY, AND MUST OTHERWISE BE MADE IN
                        ACCORDANCE WITH, THE PROVISIONS OF THE DMCA, AS AMENDED
                        FROM TIME TO TIME.
                        <br />
                        <br />
                        We suggest that you consult legal counsel before filing
                        a notice or counter-notice. Persons submitting false
                        claims under the DMCA may be fined.
                        <br />
                        <br />
                        LIMITATIONS; DISCLAIMERS; INDEMNIFICATION
                        <br />
                        <br />
                        The following limitations and disclaimers shall apply in
                        all cases except as otherwise agreed to in a
                        legally-binding, written agreement executed by you and
                        an authorized officer of WinWinGo. For purposes of this
                        paragraph, a “writing” does not include an email message
                        and a signature does not include an electronic
                        signature. To the extent that perpetual or implied
                        disclaimers or exclusions, or specific limitations of
                        liabilities are not allowed by applicable law, such
                        disclaimers, exclusions, or limitations shall be
                        enforceable for the longest period and/or to the fullest
                        extent permitted by applicable law.
                    </p>
                    <br />
                    <h5>Limitations; Disclaimers; Indemnification</h5>
                    <p>
                        <strong>Limitations of Liability</strong>
                    </p>
                    <p>
                        IN NO EVENT SHALL WWG, ITS SUBSIDIARIES, AFFILIATES, OR
                        LICENSORS, NOR ANY OF THEIR RESPECTIVE EMPLOYEES,
                        CONTRACTORS, AGENTS, OFFICERS, DIRECTORS, MEMBERS,
                        MANAGERS, PARTNERS, SHAREHOLDERS (COLLECTIVELY, THE “WWG
                        PARTIES”), BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
                        WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE,
                        GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
                        (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR
                        USE THE SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY OTHER
                        USER OF THE SERVICES OR OTHER THIRD PARTY VIA THE
                        SERVICE; (C) ANY CONTENT OBTAINED FROM THE SERVICE; AND
                        (D) ANY UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
                        TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY,
                        CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL
                        THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE
                        POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET
                        FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
                        PURPOSE.
                    </p>
                    <br />
                    <p>
                        <strong>Disclaimers of Warranty</strong>
                    </p>
                    <p>
                        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE
                        SERVICE IS PROVIDED ON AN “AS IS,” “WHERE IS,” AND “AS
                        AVAILABLE” BASIS. TO THE EXTENT ALLOWED BY APPLICABLE
                        LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY
                        KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                        LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
                        COURSE OF PERFORMANCE. TO THE EXTENT ALLOWED BY
                        APPLICABLE LAW, THE WWG PARTIES EXPRESSLY DISCLAIM ANY
                        GUARANTEES OR WARRANTIES RELATED TO THE AVAILABILITY,
                        ACCURACY, INTEGRITY, SECURITY, COMPLETENESS, AND/OR
                        CONFIDENTIALITY OF THE SERVICE AND ANY AND ALL
                        ASSOCIATED CONTENT.
                        <br />
                        <br />
                        WinWinGo PARTIES DO NOT WARRANT THAT: (A) THE SERVICE
                        WILL FUNCTION UNINTERRUPTED, SECURELY, OR
                        CONFIDENTIALLY, OR WILL BE AVAILABLE AT ANY PARTICULAR
                        TIME OR LOCATION; (B) ANY ERRORS OR DEFECTS IN ANY
                        SERVICE WILL BE CORRECTED OR THAT ANY UPDATES TO THE
                        SERVICE WILL BE PROVIDED; (C) THE SERVICE IS FREE OF
                        VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS
                        OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS.
                    </p>
                    <br />
                    <p>
                        <strong>Further Limitations</strong>
                    </p>
                    <p>
                        IN ADDITION TO THE LIMITATIONS AND EXCLUSIONS SET FORTH
                        IN THIS AGREEMENT, IN NO EVENT WILL OUR LIABILITY FOR
                        ANY MATTER ARISING OUT OF OR IN CONNECTION WITH THESE
                        TERMS AND/OR THE SERVICES EXCEED AN AMOUNT EQUAL TO
                        FIFTY PERCENT (50%) OF THE SUMS PAID BY YOU TO US DURING
                        THE TWELVE (12) CALENDAR MONTHS IMMEDIATELY PRECEDING
                        THE DATE OF THE ACT OR OMISSION UPON WHICH SUCH
                        LIABILITY IS BASED. You acknowledge that the allocation
                        of risk and the limitations of liabilities contained in
                        this Agreement are material considerations for our
                        agreements herein. Any future enhancements to the
                        Services that are not specifically described in this
                        Agreement are deemed to be covered by all limitations,
                        exclusions, and disclaimers provided for in these Terms.
                    </p>
                    <br />
                    <p>
                        <strong>Indemnification</strong>
                    </p>
                    <p>
                        YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS WWG
                        AND ITS AFFILIATES, LICENSEES, AND LICENSORS, AND THEIR
                        RESPECTIVE EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS,
                        DIRECTORS, MEMBERS, MANAGERS, PARTNERS, AND
                        SHAREHOLDERS, FROM AND AGAINST ANY AND ALL CLAIMS,
                        DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES, COSTS OR
                        DEBT, AND EXPENSES (INCLUDING BUT NOT LIMITED TO
                        REASONABLE LEGAL FEES), RESULTING FROM OR ARISING OUT
                        OF: (A) ANY USE OR ACCESS OF THE SERVICE, BY YOU OR ANY
                        PERSON USING YOUR ACCOUNT OR LOGIN CREDENTIALS; OR (B)
                        YOUR BREACH OF THESE TERMS, OR (C) CONTENT THAT YOU
                        PROVIDE TO THE SERVICE OR VIA THE SERVICE.
                    </p>
                    <br />
                    <h5>Additional Terms</h5>
                    <p>
                        <strong>Governing Law and Venue</strong>
                    </p>
                    <p>
                        These Terms shall be governed and construed in
                        accordance with U.S.A. laws, without regard to its
                        conflict of law provisions. You hereby irrevocably
                        consent to the exclusive jurisdiction of the United
                        States of America in connection with respect to any and
                        all suits, actions, or other proceedings arising out of
                        or in connection with the Services. If you have violated
                        or threatened to violate these Terms, we may seek
                        injunctive or other appropriate relief against you; this
                        is in addition to any claims for damages that we may
                        have. You agree to pay all of our costs of enforcement,
                        including reasonable legal fees. A printed version of
                        these Terms and of any notice given in electronic form
                        shall be admissible in judicial or administrative
                        proceedings based on or relating to these Terms to the
                        same extent and subject to the same conditions as other
                        business documents and records originally generating and
                        maintained in printed form.
                    </p>
                    <br />
                    <p>
                        <strong>
                            No Waiver; Severability; Entire Agreement
                        </strong>
                    </p>
                    <p>
                        Our failure to enforce any right or provision of these
                        Terms will not be considered a waiver of such right or
                        provision. If any provision of these Terms is held to be
                        invalid or unenforceable by a court, the remaining
                        provisions of these Terms will remain in effect. These
                        Terms constitute the entire agreement between us
                        regarding the Services, and supersede and replace any
                        prior agreements we might have had between us regarding
                        the Services.
                    </p>
                    <br />
                    <p>
                        <strong>No Partnership</strong>
                    </p>
                    <p>
                        You and WinWinGo agree that no joint venture,
                        partnership, employment, or agency relationship exists
                        between you and WWG as a result of these Terms or your
                        use of the Services.
                    </p>
                    <br />
                    <p>
                        <strong>No Third Party Rights</strong>
                    </p>
                    <p>
                        These Terms and Conditions govern your interactions with
                        us and shall not be construed as giving rights or
                        obligations to third parties.
                    </p>
                    <br />
                    <p>
                        <strong>Survival</strong>
                    </p>
                    <p>
                        All provisions of the Terms which by their nature should
                        reasonably survive termination of any business
                        relationship between WinWinGo and you shall survive such
                        termination, including, without limitation, provisions
                        related to international use, ownership provisions,
                        warranty disclaimers, indemnity, and limitations of
                        liability. If you grant us a license, it will also
                        survive any termination. Any license we may grant to you
                        terminates immediately upon termination of your right to
                        use the Website or Services, or to maintain an Account.
                        Your warranties and indemnification obligations shall
                        survive any termination for the longest period allowed
                        by applicable law.
                    </p>
                    <br />
                    <p>
                        <strong>Changes to These Terms</strong>
                    </p>
                    <p>
                        From time to time, we may change the Terms, and reserve
                        the right to do so in our sole discretion. In that case,
                        we will post the updated Terms to the Website and
                        indicate the date of revision, and all amended terms
                        shall automatically be effective thirty (30) calendar
                        days after they are initially posted. You are solely
                        responsible for knowing and ensuring that you understand
                        the provisions of the current Terms by reviewing them on
                        the Website from time to time. Without limiting the
                        foregoing, WinWinGo may from time to time elect, in its
                        sole discretion, to use reasonable efforts to notify you
                        of any changes to the Terms that WinWinGo deems to be
                        substantial or material, for your convenience and not
                        based on any obligation of WinWinGo.
                        <br />
                        <br />
                        BY CONTINUING TO ACCESS OR USE THE SERVICES AFTER ANY
                        REVISIONS BECOME EFFECTIVE, YOU AGREE TO BE BOUND BY THE
                        REVISED TERMS. IF YOU DO NOT AGREE TO THE REVISED TERMS,
                        YOU ARE NO LONGER AUTHORIZED TO USE THE SERVICES AND
                        MUST IMMEDIATELY DISCONTINUE ACCESS AND/OR USE.
                        <br />
                        <br />
                        These Terms may not be otherwise amended except in a
                        writing that is hand signed by both you and an
                        authorized officer of WinWinGo. For purposes of this
                        paragraph, a “writing” does not include an email message
                        and a signature does not include an electronic
                        signature.
                    </p>
                    <br />
                    <h5>Contact Us</h5>
                    <p>
                        {" "}
                        If you would like to contact us to understand more about
                        this Policy or wish to contact us concerning any matter
                        relating to our terms, you may do so by sending an email
                        to {SUPPORT_EMAIL}.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};
