import { Formik, Form, Field } from "formik";
import { LabUserResponse, useUserService } from "../../services/users";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { getIndustryRoles } from "../../services/generalInfo";
import { TGenericIdName } from "../../lib/types/general";
import { DentalTechSignUpFormData } from "../../lib/types/dentalTechs";
import { PillInput, usePillInput } from "../common/pillInput";
import { useRouteNavigation } from "../../lib/common/routes";
import { PositionsCheckList } from "../common/positions-check-list";
import { getRegions } from "../../services/regions";
import { emailRegex, numberRegex } from "../../lib/common/validation";

export const SignupTechForm = () => {
    const [generalError, setGeneralError] = useState<string | undefined>(
        undefined
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userCreated, setUserCreated] = useState(false);
    const [regions, setRegions] = useState<TGenericIdName[]>([]);
    // const okNotificationRef = useRef(null);
    const [positions, setPositions] = useState<TGenericIdName[]>([]);
    const { getRoute, navigateToRoute } = useRouteNavigation();
    const signUpRef = useRef<HTMLDivElement>(null);

    const hobbies = usePillInput([]);
    const interests = usePillInput([]);

    const [progress, setProgress] = useState(0);
    const [currentCard, setCurrentCard] = useState(1);
    const totalCards = 5;

    useEffect(() => {
        getRegions()
            .then((data) => {
                setRegions(data);
                return getIndustryRoles();
            })
            .then((data) => {
                setPositions(data);
            }); //TODO: get a better error management
    }, []);

    const { signUpUser, configureNewTech } = useUserService();

    const userValidationHandler = async (
        values: DentalTechSignUpFormData
    ): Promise<LabUserResponse> => {
        const signUpResponse = await signUpUser(values.email, values.password);
        if (signUpResponse.error) {
            setGeneralError(signUpResponse.error);
        }

        return signUpResponse;
    };

    const submitHandler = (
        values: DentalTechSignUpFormData,
        { setSubmitting }: { setSubmitting: (val: boolean) => void }
    ) => {
        setGeneralError(undefined);

        setTimeout(async () => {
            const signUpResponse = await userValidationHandler(values);
            if (signUpResponse.error) {
                setSubmitting(false);
                return;
            }
            await configureNewTech({
                ...values,
                hobbies: hobbies.values,
                interests: interests.values,
                uid: signUpResponse.uid,
                positions: values.positions,
                password2nd: undefined,
                zip: values.zip,
            });
            setUserCreated(true);
            localStorage.setItem("firstLogin", "true");
            setTimeout(() => {
                navigateToRoute("dentalTechAccount");
                // uncomment okNotificationRef when tech has to pay subscription
                // (okNotificationRef.current as any).scrollIntoView({ behavior: 'smooth', block: 'start' });
                // (okNotificationRef.current as any).style.opacity = "1";
            }, 100);

            setSubmitting(false);
        }, 400);
    };

    // uncomment okDisplay when tech has to pay subscription
    // const OkDisplay = () => {
    //     const { navigateToRoute, getRoute, navigateRouteClickHandler } = useRouteNavigation();

    //     setTimeout(() => {
    //         navigateToRoute('subscriptionSelection');
    //     }, 10000);

    //     return (

    //         <div ref={okNotificationRef} className="text-center" style={{ "transition": "all 250ms linear 250ms", "opacity": "0" }}>
    //             <i className="fa-regular fa-circle-check text-success my-5" style={{ 'fontSize': '7rem' }}></i>
    //             <h3>🎉 Registration Successful!</h3>
    //             <h3>Let's choose your <a href={getRoute('subscriptionSelection')}
    //                 onClick={(e) => navigateRouteClickHandler('subscriptionSelection', e)}>subscription plan.</a></h3>
    //         </div>
    //     )
    // }

    const validationSchemas = [
        // Slide 1: Name and TC
        Yup.object().shape({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            zip: Yup.string()
                .matches(numberRegex, "ZIP must be a number")
                .required("Required"),
            accept: Yup.boolean().oneOf(
                [true],
                "Please accept the terms and conditions"
            ),
        }),
        // Slide 2: Job Positions
        Yup.object().shape({
            yearsOfExperience: Yup.number()
                .nullable()
                .typeError("Must be a number from 0-100")
                .integer("Must be a whole number")
                .min(0, "Must be a number from 0-100")
                .max(100, "Must be a number from 0-100"),
            positions: Yup.array()
                .of(Yup.string())
                .min(1, "At least one position is required")
                .required("At least one position is required"),
            desiredSalaryPerYear: Yup.string()
                .nullable()
                .matches(numberRegex, "Desired Salary must be a number"),
        }),
        // Slide 3: Desired Region
        Yup.object().shape({
            desiredRegion: Yup.string().required("Required"),
        }),
        // Slide 4: Interests & Hobbies
        Yup.object().shape({}),
        // Slide 5: Account Information
        Yup.object().shape({
            email: Yup.string()
                .required("Required")
                .matches(emailRegex, "Invalid email format"),
            password: Yup.string().required("Required"),
            password2nd: Yup.string()
                .oneOf([Yup.ref("password"), undefined], "Passwords must match")
                .required("Required"),
        }),
    ];

    const progressAction = async (
        direction: "next" | "prev",
        validateForm?: any
    ) => {
        const errors = await validateForm();
        if (direction === "next" && currentCard < totalCards) {
            if (Object.keys(errors).length === 0) {
                const newCard = currentCard + 1;
                setCurrentCard(newCard);
                setProgress((newCard / totalCards) * 100);
            }
        } else if (direction === "prev" && currentCard > 1) {
            const newCard = currentCard - 1;
            setCurrentCard(newCard);
            setProgress((newCard / totalCards) * 100);
        }

        signUpRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <div ref={signUpRef}>
            {/* uncomment when tech has to pay subscription
            {(userCreated) ? (<OkDisplay />) : ( */}
            <Formik
                initialValues={{
                    uid: "",
                    email: "",
                    password: "",
                    password2nd: "",
                    lastName: "",
                    firstName: "",
                    yearsOfExperience: null,
                    desiredSalaryPerYear: null,
                    desiredRegion: "",
                    position_0: false,
                    positions: [],
                    accept: false,
                    zip: "",
                }}
                validationSchema={validationSchemas[currentCard - 1]}
                onSubmit={submitHandler}
            >
                {({
                    isSubmitting,
                    values,
                    handleChange,
                    validateForm,
                    errors,
                }) => (
                    <Form>
                        <div className="progress my-3 mx-auto signup-width px-0">
                            <div
                                className="progress-bar rounded"
                                style={{ width: `${progress}%` }}
                                role="progressbar"
                                aria-label="Tech Sign Up Progress"
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                                {progress}%
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div
                                id="techSignUpCarousel"
                                className="carousel carousel-dark slide signup-width"
                                data-touch="false"
                                data-interval="false"
                            >
                                <div className="carousel-inner">
                                    {validationSchemas.map(
                                        (validationSchema, index) => (
                                            <div
                                                key={index}
                                                className={`carousel-item ${
                                                    currentCard === index + 1
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <div className="card py-3">
                                                    {index === 0 && (
                                                        <>
                                                            <h5 className="text-center p-3">
                                                                👋 Welcome to
                                                                WinWinGo!
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Let's get
                                                                started, we want
                                                                to get to know
                                                                you better.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-5 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="First Name"
                                                                            >
                                                                                First
                                                                                Name
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-7 d-block d-md-none">
                                                                        {errors.firstName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.firstName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-5 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="First Name"
                                                                            name="firstName"
                                                                            type="text"
                                                                            className="form-control mb-2"
                                                                            maxLength={
                                                                                25
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4 d-none d-md-block">
                                                                        {errors.firstName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.firstName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-5 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                htmlFor="Last Name"
                                                                                className="form-label"
                                                                            >
                                                                                Last
                                                                                Name
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-7 d-block d-md-none">
                                                                        {errors.lastName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.lastName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-5 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Last Name"
                                                                            name="lastName"
                                                                            type="text"
                                                                            className="form-control mb-2"
                                                                            maxLength={
                                                                                25
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4 d-none d-md-block">
                                                                        {errors.lastName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.lastName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-5 col-md-3">
                                                                        <h6 className="mb-0">
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="Zip"
                                                                            >
                                                                                ZIP
                                                                                Code
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-7 d-block d-md-none mb-2 mb-md-0">
                                                                        {errors.zip ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.zip
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-5 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Zip"
                                                                            name="zip"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                10
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4 d-none d-md-block">
                                                                        {errors.zip ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.zip
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row pt-4">
                                                                    <div className="col-12 d-flex align-items-center">
                                                                        <div className="form-check">
                                                                            <input
                                                                                name="accept"
                                                                                id="accept_0"
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                    values.accept
                                                                                }
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                aria-describedby="acceptHelpBlock"
                                                                            />
                                                                            <label className="h6 form-label">
                                                                                I
                                                                                accept
                                                                                the
                                                                                <a
                                                                                    className="text-primary"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    href={getRoute(
                                                                                        "privacyPolicy"
                                                                                    )}
                                                                                >
                                                                                    <strong>
                                                                                        {" "}
                                                                                        Privacy
                                                                                        Policy{" "}
                                                                                    </strong>
                                                                                </a>
                                                                                and
                                                                                <a
                                                                                    className="text-primary"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    href={getRoute(
                                                                                        "termsOfUse"
                                                                                    )}
                                                                                >
                                                                                    <strong>
                                                                                        {" "}
                                                                                        Terms
                                                                                        of
                                                                                        Use
                                                                                    </strong>
                                                                                </a>

                                                                                .
                                                                                *
                                                                            </label>
                                                                        </div>
                                                                        <span
                                                                            id="acceptHelpBlock"
                                                                            className="form-text text-muted"
                                                                        ></span>
                                                                    </div>
                                                                    <div className="col mt-2">
                                                                        {errors.accept ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.accept
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 1 && (
                                                        <>
                                                            <h5 className="text-center py-3">
                                                                💼 Your Work
                                                                Background
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Share your
                                                                experience and
                                                                what job you're
                                                                aiming for.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-md-4">
                                                                        <h6 className="pb-2">
                                                                            <label
                                                                                htmlFor="Years of Experience"
                                                                                className="form-label"
                                                                            >
                                                                                Years
                                                                                of
                                                                                Experience
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-3 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Years of Experience"
                                                                            name="yearsOfExperience"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                3
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-5 mb-3">
                                                                        {errors.yearsOfExperience ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.yearsOfExperience
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-5">
                                                                    <div className="col-12 ">
                                                                        <label className="h6 form-label my-2">
                                                                            Position
                                                                            Applying
                                                                            For
                                                                            *
                                                                        </label>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        {errors.positions ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.positions
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <PositionsCheckList
                                                                        positions={
                                                                            positions
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-3">
                                                                        <h6 className="py-2">
                                                                            <label
                                                                                htmlFor="Desired Salary"
                                                                                className="form-label"
                                                                            >
                                                                                Desired
                                                                                Salary
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-5 mb-2 mb-md-0 d-flex align-items-start">
                                                                        <p className="fw-bold pt-2">
                                                                            $
                                                                        </p>
                                                                        <Field
                                                                            component="textarea"
                                                                            id="Desired Salary (per year)"
                                                                            name="desiredSalaryPerYear"
                                                                            rows={
                                                                                1
                                                                            }
                                                                            className="form-control mx-2 "
                                                                            maxLength={
                                                                                25
                                                                            }
                                                                        />
                                                                        <p className="fw-bold pt-2 text-nowrap">
                                                                            per
                                                                            year
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-4 mb-3">
                                                                        {errors.desiredSalaryPerYear ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.desiredSalaryPerYear
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 2 && (
                                                        <>
                                                            <h5 className="text-center p-3">
                                                                🌎 Where Do You
                                                                Want to Work?
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Pick the region
                                                                where you'd like
                                                                to work.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-5">
                                                                    <div className="col-12 mb-3">
                                                                        {errors.desiredRegion ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.desiredRegion
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    {regions.map(
                                                                        (
                                                                            region,
                                                                            index
                                                                        ) => (
                                                                            <div className="col-md-4">
                                                                                <div className="form-check">
                                                                                    <Field
                                                                                        name="desiredRegion"
                                                                                        id={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        type="radio"
                                                                                        key={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        className="form-check-input"
                                                                                        value={
                                                                                            region.id
                                                                                        }
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        className="form-control-label"
                                                                                    >
                                                                                        {
                                                                                            region.name
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 3 && (
                                                        <>
                                                            <h5 className="text-center p-3">
                                                                ✨ What Do You
                                                                Like to Do?
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Tell us about
                                                                your interests
                                                                and hobbies.{" "}
                                                                <br /> We'll use
                                                                this info to
                                                                match you with
                                                                labs and suggest
                                                                ones that would
                                                                be a great fit
                                                                for you.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-4">
                                                                    <div className="col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                htmlFor="Interests"
                                                                                className="form-label"
                                                                            >
                                                                                Interests
                                                                            </label>
                                                                            <p>
                                                                                <small className="form-text">
                                                                                    E.g.,
                                                                                    Community
                                                                                    Events,
                                                                                    Sports,
                                                                                    Ecology
                                                                                </small>
                                                                            </p>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-8 mb-2 mb-md-0">
                                                                        <PillInput
                                                                            hook={
                                                                                interests
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                htmlFor="Hobbies"
                                                                                className="form-label"
                                                                            >
                                                                                Hobbies
                                                                            </label>
                                                                            <p>
                                                                                <small className="form-text">
                                                                                    E.g.,
                                                                                    Hiking,
                                                                                    Photography,
                                                                                    Cooking
                                                                                </small>
                                                                            </p>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-8 mb-2 mb-md-0">
                                                                        <PillInput
                                                                            hook={
                                                                                hobbies
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 4 && (
                                                        <>
                                                            <h5 className="text-center p-3">
                                                                Almost Done! 🎉
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Let's choose
                                                                your email and a
                                                                secure password.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="Email"
                                                                            >
                                                                                Email
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-6 d-block d-md-none">
                                                                        {errors.email ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.email
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Email"
                                                                            name="email"
                                                                            type="email"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                254
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.email ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.email
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="password"
                                                                            >
                                                                                Password
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-4 d-block d-md-none">
                                                                        {errors.password ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.password
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="password"
                                                                            name="password"
                                                                            type="password"
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.password ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.password
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="password"
                                                                            >
                                                                                Confirm
                                                                                Password*
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-6 d-block d-md-none">
                                                                        {errors.password2nd ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.password2nd
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="password2nd"
                                                                            name="password2nd"
                                                                            type="password"
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.password2nd ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.password2nd
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-1">
                                                                    {generalError ? (
                                                                        <div className="badge rounded-pill text-bg-danger text-wrap">
                                                                            {
                                                                                generalError
                                                                            }
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row gap-4 justify-content-center mt-5">
                            {/* {currentCard === 1 && (
                                <button className="btn btn-primary col-lg-5"
                                    type="button"
                                    onClick={() => userValidationHandler(values, validateForm)}>
                                    <span className="" aria-hidden="true">Sign Up</span>
                                </button>
                            )} */}
                            {currentCard > 1 && (
                                <button
                                    className="btn btn-outline-dark col-md-4 col-lg-3"
                                    type="button"
                                    onClick={() =>
                                        progressAction("prev", validateForm)
                                    }
                                >
                                    <span className="" aria-hidden="true">
                                        Back
                                    </span>
                                    <span className="visually-hidden">
                                        Previous
                                    </span>
                                </button>
                            )}
                            {currentCard < totalCards && (
                                <button
                                    className="btn btn-primary col-md-7 col-lg-5"
                                    type="button"
                                    onClick={() =>
                                        progressAction("next", validateForm)
                                    }
                                >
                                    <span className="" aria-hidden="true">
                                        Next
                                    </span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            )}
                            {currentCard === totalCards && (
                                <button
                                    disabled={isSubmitting}
                                    name="submit"
                                    type="submit"
                                    className="btn btn-primary col-md-7 col-lg-5"
                                >
                                    Get Started
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            {/* )
            } */}
        </div>
    );
};
