import { useRef, useState } from "react";
import { useUserService } from "../../services/users";
import { TProcessStates } from "../../lib/types/general";

type TReportButtonProps = {
    userId: string;
};
export const ReportButton = ({ userId }: TReportButtonProps) => {
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [reportMessage, setReportMessage] = useState("");
    const refTextArea = useRef<HTMLTextAreaElement>(null);
    const [currState, setCurrState] = useState<TProcessStates>("idle");
    const [alertType, setAlertType] = useState<string>("");
    const onChangeHandler = (val?: string) => {
        setButtonDisabled(!!!val);
    };

    const { reportUser } = useUserService();

    const reportUserClickHandler = async () => {
        if (!refTextArea) return;
        if (!refTextArea.current?.value) return;
        setCurrState("working");
        setButtonDisabled(true);
        try {
            const message = await reportUser(userId, refTextArea.current.value);

            if (message === "ok") {
                setAlertType("alert-success");
                setReportMessage("Thanks!, Your report will be notified.");
            } else {
                setAlertType("alert-warning");
                setReportMessage(message);
            }
        } catch {
            setAlertType("alert-danger");
            setReportMessage(
                "Something wrong happened!, please try again later."
            );
        } finally {
            setCurrState("idle");
            setButtonDisabled(false);
        }
    };

    return (
        <div className="my-3">
            <button
                className="btn btn-outline-danger w-100"
                style={{ width: "18rem" }}
                data-toggle="collapse"
                data-target="#reportThisUser"
                aria-expanded="true"
                aria-controls="blockLabWarning"
            >
                <i className="fa-solid fa-person-circle-exclamation me-1"></i>{" "}
                <small>Report this user </small>
            </button>
            <div className="collapse mt-2 p-0" id="reportThisUser">
                <div className="card card-body text-center px-5">
                    <p>
                        <small>
                            Please provide a brief reason for reporting this
                            user:
                        </small>
                    </p>
                    <textarea
                        ref={refTextArea}
                        onChange={(e) => onChangeHandler(e.target.value)}
                        className="form-control"
                        style={{ height: "150px" }}
                    ></textarea>
                    <div
                        style={{ opacity: !!reportMessage ? 1 : 0 }}
                        className={`m-0 py-1 small alert mt-2 alert-transition ${alertType}`}
                        role="alert"
                    >
                        {reportMessage || "_"}
                    </div>
                    <div className="d-grid gap-3">
                        <button
                            className="btn btn-dark"
                            disabled={buttonDisabled}
                            onClick={reportUserClickHandler}
                        >
                            {currState === "working" ? (
                                <>
                                    reporting{" "}
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                            ) : (
                                "Report"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
