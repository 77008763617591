import { useEffect, useState } from "react";
import { useUserService } from "../../services/users";
import { TProcessStates } from "../../lib/types/general";

type TBlacklistButtonProps = {
    userId: string;
    isInBlacklist: string | null;
};

type TBlackListState = "found" | "notFound" | "added" | "removed";

export const BlacklistButton = ({
    userId,
    isInBlacklist,
}: TBlacklistButtonProps) => {
    const [blacklistState, setBlacklistState] = useState<TBlackListState>();
    const [currState, setCurrState] = useState<TProcessStates>("idle");
    const { addLabToBlacklist, deleteLabBlacklist } = useUserService();

    const checkLabInBlacklist = () => {
        if (isInBlacklist !== null) {
            setBlacklistState("found");
        } else {
            setBlacklistState("notFound");
        }
    };

    const addLabToBlacklistHandler = async (lab_id: string) => {
        try {
            setCurrState("working");
            await addLabToBlacklist(lab_id);
            setBlacklistState("added");
            setCurrState("done");
        } catch (error) {
            setBlacklistState("found");
        }
    };

    const deleteBlacklistHandler = async (lab_id: string) => {
        try {
            setCurrState("working");
            await deleteLabBlacklist(lab_id);
            setBlacklistState("removed");
            setCurrState("done");
        } catch (error) {
            console.error("Error deleting lab from blacklist:", error);
        }
    };

    useEffect(() => {
        checkLabInBlacklist();
        // eslint-disable-next-line
    }, [isInBlacklist]);

    return (
        <div className="my-3">
            <button
                className="btn btn-outline-danger w-100"
                data-toggle="collapse"
                data-target="#blockLabWarning"
                aria-expanded="true"
                aria-controls="blockLabWarning"
            >
                {" "}
                <i className="fa-solid fa-ban me-2"></i>
                {blacklistState === "added" && (
                    <>
                        <small className="me-3"> Added to Block List</small>{" "}
                        <i className="fa-solid fa-chevron-down"></i>
                    </>
                )}
                {blacklistState === "removed" && (
                    <>
                        <small className="me-3"> Removed from Block List</small>{" "}
                        <i className="fa-solid fa-chevron-down"></i>
                    </>
                )}
                {blacklistState === "found" && (
                    <small>Remove this lab from your Block List</small>
                )}
                {blacklistState === "notFound" && (
                    <small>Add to my Block List</small>
                )}
            </button>
            <div className="collapse mt-2 p-md-0" id="blockLabWarning">
                <div className="card card-body text-center">
                    {blacklistState === "notFound" && (
                        <>
                            If you add this lab to your Block List, you will not
                            appear in its search results.
                            <br />
                            Are you sure you want to hide from this lab?
                            <div className="d-grid gap-3 mt-2">
                                <button
                                    className="btn btn-warning"
                                    onClick={() =>
                                        addLabToBlacklistHandler(userId)
                                    }
                                >
                                    {currState === "working" ? (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    ) : (
                                        <>Yes</>
                                    )}
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    data-toggle="collapse"
                                    data-target="#blockLabWarning"
                                    aria-expanded="false"
                                >
                                    No
                                </button>
                            </div>
                        </>
                    )}
                    {blacklistState === "added" && (
                        <small className=" mt-1">
                            {" "}
                            This lab has been added to your Block List.
                        </small>
                    )}
                    {blacklistState === "removed" && (
                        <small className=" mt-1">
                            {" "}
                            This lab has been removed from your Block List.
                        </small>
                    )}
                    {blacklistState === "found" && (
                        <>
                            This lab is currently in your Block List. <br />{" "}
                            Removing it will make you visible in its search
                            results.
                            <div className="d-grid gap-3 mt-2">
                                <button
                                    className="btn btn-warning"
                                    type="button"
                                    onClick={() =>
                                        deleteBlacklistHandler(userId)
                                    }
                                >
                                    {currState === "working" ? (
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                    ) : (
                                        <>Remove from Block List</>
                                    )}
                                </button>
                                <button
                                    className="btn btn-secondary "
                                    data-toggle="collapse"
                                    data-target="#blockLabWarning"
                                    aria-expanded="false"
                                >
                                    Keep in Block List
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
