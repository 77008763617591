import { useServiceContext } from "../../contexts/service";
import { TApiLab } from "../../lib/types/labs";
import { TApiTechnician } from "../../lib/types/dentalTechs";

type TUserInfo = TApiLab | TApiTechnician;
type TMessageUser = {
    userInfo: TUserInfo;
    className?: string;
};

export const MessageBtn = ({ userInfo, className }: TMessageUser) => {
    const {
        chats: { setShowChat, setUserIdToChat },
    } = useServiceContext();
    return (
        <button
            className={"btn " + className}
            onClick={() => {
                setUserIdToChat({ id: userInfo.id, name: userInfo.name });
                setShowChat(true);
            }}
        >
            <i className="fa-regular fa-message me-2"></i>
            Chat
        </button>
    );
};
