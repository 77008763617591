import { SUPPORT_EMAIL } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { Header } from "../../common/header";
import { Footer } from "../main-page/footer";
export const CookiePolicy = () => {
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();

    return (
        <>
            <Header title="Cookie Policy" />
            <div className="container-fluid">
                <div className="container text-justify px-5">
                    <p>
                        <small>Effective March 20, 2024</small>
                    </p>
                    <br />
                    <p>
                        This cookie policy ("Policy") describes what cookies are
                        and how and they're being used by the winwingo.com
                        website ("Website" or "Service") and any of its related
                        products and services (collectively, "Services"). This
                        Policy is a legally binding agreement between you
                        ("User", "you" or "your") and WinWinGo ("WinWinGo",
                        "WWG", "we", "us" or "our"), along with all associated
                        websites and products. You should read this Policy so
                        you can understand the types of cookies we use, the
                        information we collect using cookies and how that
                        information is used. It also describes the choices
                        available to you regarding accepting or declining the
                        use of cookies. For further information on how we use,
                        store and keep your personal data secure, see our{" "}
                        <a
                            style={{ color: "orange" }}
                            href={getRoute("privacyPolicy")}
                            onClick={(e) =>
                                navigateRouteClickHandler("privacyPolicy", e)
                            }
                        >
                            <strong>Privacy Policy</strong>
                        </a>
                        .
                    </p>

                    <h5>What are cookies?</h5>
                    <p>
                        Cookies are small pieces of data stored in text files
                        that are saved on your computer or other devices when
                        websites are loaded in a browser. They are widely used
                        to remember you and your preferences, either for a
                        single visit (through a "session cookie") or for
                        multiple repeat visits (using a "persistent cookie").
                        <br />
                        <br />
                        Session cookies are temporary cookies that are used
                        during the course of your visit to the Website, and they
                        expire when you close the web browser.
                        <br />
                        <br />
                        Persistent cookies are used to remember your preferences
                        within our Website and remain on your desktop or mobile
                        device even after you close your browser or restart your
                        computer. They ensure a consistent and efficient
                        experience for you while visiting the Website and
                        Services.
                        <br />
                        <br />
                        Cookies may be set by the Website ("first-party
                        cookies"), or by third parties, such as those who serve
                        content or provide advertising or analytics services on
                        the Website ("third party cookies"). These third parties
                        can recognize you when you visit our website and also
                        when you visit certain other websites.
                    </p>
                    <h5>What type of cookies do we use?</h5>
                    <p>
                        <strong>Necessary Cookies</strong>
                    </p>
                    <p>
                        Necessary cookies allow us to offer you the best
                        possible experience when accessing and navigating
                        through our Website and using its features. For example,
                        these cookies let us recognize that you have created an
                        account and have logged into that account to access the
                        content.
                    </p>

                    <p>
                        <strong>Functionality Cookies</strong>
                    </p>
                    <p>
                        Functionality cookies let us operate the Website and
                        Services in accordance with the choices you make. For
                        example, we will recognize your username and remember
                        how you customized the Website and Services during
                        future visits.
                    </p>

                    <p>
                        <strong>Analytical Cookies</strong>
                    </p>
                    <p>
                        These cookies enable us and third party services to
                        collect aggregated data for statistical purposes on how
                        our visitors use the Website. These cookies do not
                        contain personal information such as names and email
                        addresses and are used to help us improve your user
                        experience of the Website.
                    </p>

                    <p>
                        <strong>Advertising Cookies</strong>
                    </p>
                    <p>
                        Advertising cookies allow us and third parties serve
                        relevant ads to you more effectively and help us collect
                        aggregated audit data, research, and performance
                        reporting for advertisers. They also enable us to
                        understand and improve the delivery of ads to you and
                        know when certain ads have been shown to you.
                    </p>
                    <p>
                        Your web browser may request advertisements directly
                        from ad network servers, these networks can view, edit,
                        or set their own cookies, just as if you had requested a
                        web page from their website.
                    </p>
                    <p>
                        Although we do not use cookies to create a profile of
                        your browsing behavior on third party websites, we do
                        use aggregate data from third parties to show you
                        relevant, interest-based advertising.
                    </p>

                    <p>
                        <strong>Social Media Cookies</strong>
                    </p>
                    <p>
                        Third party cookies from social media sites (such as
                        Facebook, Twitter, etc) let us track social network
                        users when they visit or use the Website and Services,
                        or share content, by using a tagging mechanism provided
                        by those social networks.
                    </p>
                    <p>
                        These cookies are also used for event tracking and
                        re-marketing purposes. Any data collected with these
                        tags will be used in accordance with our and social
                        networks’ privacy policies. We will not collect or share
                        any personally identifiable information from the user.
                    </p>

                    <h5>What are your cookie options?</h5>
                    <p>
                        If you don't like the idea of cookies or certain types
                        of cookies, you can change your browser's settings to
                        delete cookies that have already been set and to not
                        accept new cookies. To learn more about how to do this
                        or to learn more about cookies, visit
                        internetcookies.org.
                    </p>
                    <p>
                        Please note, however, that if you delete cookies or do
                        not accept them, you might not be able to use all of the
                        features the Website and Services offer.
                    </p>

                    <h5>Changes And Amendments</h5>
                    <p>
                        We reserve the right to modify this Policy or its terms
                        relating to the Website and Services at any time,
                        effective upon posting of an updated version of this
                        Policy on the Website. When we do, we will revise the
                        updated date at the bottom of this page. Continued use
                        of the Website and Services after any such changes shall
                        constitute your consent to such changes.
                    </p>

                    <h5>Acceptance Of This Policy</h5>
                    <p>
                        You acknowledge that you have read this Policy and agree
                        to all its terms and conditions. By accessing and using
                        the Website and Services you agree to be bound by this
                        Policy. If you do not agree to abide by the terms of
                        this Policy, you are not authorized to access or use the
                        Website and Services.
                    </p>

                    <h5>Contact Us</h5>
                    <p>
                        If you would like to contact us to understand more about
                        this Policy or wish to contact us concerning any matter
                        relating to our Cookie Policy, you may do so by sending
                        an email to {SUPPORT_EMAIL}.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};
