import { useEffect, useRef, useState } from "react";
import { TGenericIdName } from "../../lib/types/general";

export type TOptionToShow = {
    data: TGenericIdName;
    selected: boolean;
};

export type TApplyfilterCallback = (options: TOptionToShow[]) => void;

type TDropdownMultiSelectProps = {
    title: string;
    applyFilterCallback: TApplyfilterCallback;
    setOptionsToShow: React.Dispatch<React.SetStateAction<TOptionToShow[]>>;
    optionsToShow: TOptionToShow[];
    btnColor: "info" | "primary";
};

export const DropdownMultiSelect = (props: TDropdownMultiSelectProps) => {
    const {
        title,
        applyFilterCallback,
        optionsToShow,
        setOptionsToShow,
        btnColor,
    } = props;

    const applyClickHandler = (
        e: React.MouseEvent<HTMLButtonElement>,
        option: TOptionToShow
    ) => {
        e.stopPropagation();
        option.selected = !option.selected;
        applyFilterCallback(optionsToShow.filter((o) => o.selected));
        setOptionsToShow([...optionsToShow]);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (
                dropdownButtonRef.current &&
                event.target instanceof Node &&
                !dropdownButtonRef.current.contains(event.target)
            ) {
                closeDropdown();
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };
    const resetFilter = () => {
        const clearOptions = () => {
            return optionsToShow.map((option) => ({
                ...option,
                selected: false,
            }));
        };
        setOptionsToShow(clearOptions());
        applyFilterCallback([]);
        closeDropdown();
    };

    return (
        <div className="dropdown">
            <button
                className={
                    "btn border-2 fw-bold py-1 px-3 w-100 mb-2 text-wrap dropdown-toggle " +
                    (optionsToShow.filter((o) => o.selected).length
                        ? `btn-${btnColor} text-white`
                        : `btn-outline-${btnColor}`)
                }
                data-toggle="dropdown"
                aria-expanded={isDropdownOpen}
                onClick={toggleDropdown}
                ref={dropdownButtonRef}
            >
                {optionsToShow.filter((o) => o.selected).length === 1
                    ? optionsToShow.filter((o) => o.selected)[0].data.name
                    : title}
                {optionsToShow.filter((o) => o.selected).length > 1 && (
                    <span className="badge rounded-pill text-bg-dark mx-2 px-3">
                        {optionsToShow.filter((o) => o.selected).length}
                    </span>
                )}
            </button>

            <div className="dropdown-menu my-2 py-3 shadow border-info">
                {optionsToShow.map((option, idx) => (
                    <button
                        data-testid="optionItem"
                        className={
                            "dropdown-item " +
                            (option.selected ? ` active-${btnColor}` : " ")
                        }
                        key={idx + "_" + option.data.id}
                        onClick={(e) => {
                            applyClickHandler(e, option);
                        }}
                    >
                        {option.data.name}
                    </button>
                ))}
                <hr />
                <div className="container text-end">
                    <button
                        data-testid="clearFilter"
                        className={`btn btn-info text-white py-1 px-3`}
                        onClick={resetFilter}
                    >
                        clear
                    </button>
                </div>
            </div>
        </div>
    );
};
