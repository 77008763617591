import { TMessageStatus, TProcessStates } from "../../lib/types/general";
import { TUserTypes } from "../../lib/types/users";
import { useMessages } from "../../services/notifications";
import { Spinner } from "../sections/backoffice/common/spinner";

export const InviteButton = ({
    userToInviteId,
    userToInviteInvitationState,
    userType,
}: {
    userToInviteId: string;
    userToInviteInvitationState?: TMessageStatus;
    userType: TUserTypes;
}) => {
    const { sendInvitation, sendInviteState } = useMessages(userToInviteId);
    const isDone: TProcessStates | undefined = userToInviteInvitationState
        ? "done"
        : undefined;

    return (
        <button
            className={
                (isDone || sendInviteState) === "done"
                    ? "btn btn-outline-dark w-100 rounded-3"
                    : "btn btn-primary w-100 rounded-3 fw-semibold"
            }
            data-testid="invite-button"
            disabled={(isDone || sendInviteState) !== "idle"}
            onClick={(e) => {
                e.preventDefault();
                sendInvitation();
            }}
        >
            {(isDone || sendInviteState) === "idle" && userType === "lab" && (
                <>
                    <i className="fa-solid fa-user-plus pe-2"></i> CONTACT
                </>
            )}
            {(isDone || sendInviteState) === "idle" && userType === "tech" && (
                <>
                    <i className="fa-solid fa-user-plus pe-2"></i> INVITE
                </>
            )}

            {(isDone || sendInviteState) === "error" && (
                <>
                    <i
                        data-testid="error"
                        className="fa-solid fa-circle-exclamation"
                    ></i>{" "}
                    Something went wrong. Please try again.
                </>
            )}

            {(isDone || sendInviteState) === "working" && (
                <>
                    <i data-testid="loading-spinner" className="text-white">
                        Sending ... {""} <Spinner />{" "}
                    </i>
                </>
            )}

            {(isDone || sendInviteState) === "done" && userType === "lab" && (
                <small>
                    Contact request sent. <br /> The lab has been notified.
                </small>
            )}
            {(isDone || sendInviteState) === "done" && userType === "tech" && (
                <small>
                    Invitation sent. <br /> The tech has been notified.
                </small>
            )}
        </button>
    );
};
